<!-- eslint-disable vue/valid-v-bind -->
<template>
<div >
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>

    <div >
        <!-- 左侧菜单 -->
        <van-sidebar v-model="active" >
        <van-sidebar-item v-for="(item,index) in menuList" :key="index" :title="item.name" @click="clickScroll(item.name)"/>
        </van-sidebar>
       
       
        <!-- 右侧内容 -->
        <div class="right_box">
            <van-index-bar :index-list="[]">
                <div v-for="(item,index) in menuList" :key="index" :id="item.name">
                    <van-index-anchor  index="index" >{{item.name}}</van-index-anchor>

                    <van-grid :border="true" :column-num="1">
                    <van-grid-item v-for="(itemc,index) in item.children" :key="index">
                        
                        <div class="infoTitleBox">
                            <h4>{{itemc.name}}</h4>
                        </div>
                        <div class="infoMesBox">
                            <p v-for="(itemMes,index) in itemc.mes" :key="index">{{itemMes}}</p>
                        </div>
                        <div class="infoBtnBox">
                            <van-button v-for="(itemBtn,index) in itemc.btn" type="danger" size="mini" :key="index" @click="condSelect(itemc.name,itemBtn.name)">{{itemBtn.name}}</van-button>
                        </div>
                    </van-grid-item>
                    
                    </van-grid>

                </div>
            </van-index-bar>
        
        </div>
        <!-- 条件弹窗 -->
        <van-popup v-model="selectShow"  position="bottom" :closeable="true" :style="{ maxHeight: '90%'}" >
            <div class="pophead">
                <span >{{selectTitle1}}   </span>
                <span >{{selectTitle2}}</span>
            </div>
            <!-- 弹窗的内容 -->
            <div class="popbody">
                <div v-show="this.selectTitle1==='定位置'">
                    <div  v-show="this.selectTitle2==='自由选码'">
                        <h5 style="display:inline-block;">请选择号码</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <div v-show="!disabled1">
                            <p>千位</p>
                            <ol>
                                <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in group1" :key="index" @click="change(group1,index)">{{index}}</li>
                            </ol>
                        </div>
                        <div v-show="!disabled2">
                            <p>百位</p>
                            <ol>
                                <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in group2" :key="index" @click="change(group2,index)">{{index}}</li>
                            </ol>
                        </div>
                        <div v-show="!disabled3">
                            <p>十位</p>
                            <ol >
                                <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in group3" :key="index" @click="change(group3,index)">{{index}}</li>
                            </ol>
                        </div>
                        <div v-show="!disabled4">
                            <p>个位</p>
                            <ol >
                                <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in group4" :key="index" @click="change(group4,index)">{{index}}</li>
                            </ol>
                        </div>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                    </div>
                    <div  v-show="this.selectTitle2==='批量选码'">
                        <h5 style="display:inline-block;">请选择类型</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <p class="radioboxclass">千位</p>
                        <div style="display:inline-block">
                            <van-radio-group v-model="radioval1_1" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled1" style="margin-bottom: 0.3rem;">
                                <van-radio name="单" @click="showradioval">单</van-radio>
                                <van-radio name="双" @click="showradioval">双</van-radio>
                            </van-radio-group>
                            <van-radio-group v-model="radioval1_2" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled1" style="margin-bottom: 0.3rem;">
                                <van-radio name="大" @click="showradioval">大</van-radio>
                                <van-radio name="小" @click="showradioval">小</van-radio>
                            </van-radio-group>
                            <van-radio-group v-model="radioval1_3" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled1" style="margin-bottom: 0.3rem;">
                                <van-radio name="0路" @click="showradioval">0路</van-radio>
                                <van-radio name="1路" @click="showradioval">1路</van-radio>
                                <van-radio name="2路" @click="showradioval">2路</van-radio>
                            </van-radio-group>
                        </div>
                        <hr/>
                        <p class="radioboxclass">百位</p>
                        <div style="display:inline-block">
                            <van-radio-group v-model="radioval2_1" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled2" style="margin-bottom: 0.3rem;">
                                <van-radio name="单" @click="showradioval">单</van-radio>
                                <van-radio name="双" @click="showradioval">双</van-radio>
                            </van-radio-group>
                            <van-radio-group v-model="radioval2_2" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled2" style="margin-bottom: 0.3rem;">
                                <van-radio name="大" @click="showradioval">大</van-radio>
                                <van-radio name="小" @click="showradioval">小</van-radio>
                            </van-radio-group>
                            <van-radio-group v-model="radioval2_3" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled2" style="margin-bottom: 0.3rem;">
                                <van-radio name="0路" @click="showradioval">0路</van-radio>
                                <van-radio name="1路" @click="showradioval">1路</van-radio>
                                <van-radio name="2路" @click="showradioval">2路</van-radio>
                            </van-radio-group>
                        </div>
                        <hr/>
                        <p class="radioboxclass">十位</p>
                        <div style="display:inline-block">
                            <van-radio-group v-model="radioval3_1" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled3" style="margin-bottom: 0.3rem;">
                                <van-radio name="单" @click="showradioval">单</van-radio>
                                <van-radio name="双" @click="showradioval">双</van-radio>
                            </van-radio-group>
                            <van-radio-group v-model="radioval3_2" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled3" style="margin-bottom: 0.3rem;">
                                <van-radio name="大" @click="showradioval">大</van-radio>
                                <van-radio name="小" @click="showradioval">小</van-radio>
                            </van-radio-group>
                            <van-radio-group v-model="radioval3_3" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled3" style="margin-bottom: 0.3rem;">
                                <van-radio name="0路" @click="showradioval">0路</van-radio>
                                <van-radio name="1路" @click="showradioval">1路</van-radio>
                                <van-radio name="2路" @click="showradioval">2路</van-radio>
                            </van-radio-group>
                        </div>
                        <hr/>
                        <p class="radioboxclass">个位</p>
                        <div style="display:inline-block">
                            <van-radio-group v-model="radioval4_1" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled4" style="margin-bottom: 0.3rem;">
                                <van-radio name="单" @click="showradioval">单</van-radio>
                                <van-radio name="双" @click="showradioval">双</van-radio>
                            </van-radio-group>
                            <van-radio-group v-model="radioval4_2" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled4" style="margin-bottom: 0.3rem;">
                                <van-radio name="大" @click="showradioval">大</van-radio>
                                <van-radio name="小" @click="showradioval">小</van-radio>
                            </van-radio-group>
                            <van-radio-group v-model="radioval4_3" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled4" style="margin-bottom: 0.3rem;">
                                <van-radio name="0路" @click="showradioval">0路</van-radio>
                                <van-radio name="1路" @click="showradioval">1路</van-radio>
                                <van-radio name="2路" @click="showradioval">2路</van-radio>
                            </van-radio-group>
                        </div>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='乘号位置'">
                        <h5 style="display:inline-block;">请选择乘号位置</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-model="checkedval" direction="horizontal" :max="1" v-if="type===3">
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="qian" @click="showcheckedval2">千位 </van-checkbox>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="bai" @click="showcheckedval2">百位 </van-checkbox>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="shi" @click="showcheckedval2">十位 </van-checkbox>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ge" @click="showcheckedval2">个位 </van-checkbox>
                            <p style="opacity:0.5;margin-top:0.6rem;margin-bottom:0rem;">*最多选一个位置</p>
                        </van-checkbox-group>
                        <van-checkbox-group v-model="checkedval" direction="horizontal" :max="2" v-if="type===5">
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="qian" @click="showcheckedval2">千位 </van-checkbox>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="bai" @click="showcheckedval2">百位 </van-checkbox>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="shi" @click="showcheckedval2">十位 </van-checkbox>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ge" @click="showcheckedval2">个位 </van-checkbox>
                            <p style="opacity:0.5;margin-top:0.6rem;margin-bottom:0rem;">*最多选两个位置</p>
                        </van-checkbox-group>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                </div>
                <div v-show="this.selectTitle1==='头尾有数'">
                    <h5 style="display:inline-block;">请选择头、尾两个位置中，其中一个位置所包含号码</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <ol>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                    </ol>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                </div>
                <div v-show="this.selectTitle1==='中肚有数'">
                    <h5 style="display:inline-block;">请选择百、十两个位置中，其中一个位置所包含号码</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <ol>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                    </ol>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <div v-show="this.selectTitle1==='有数'">
                    <div  v-show="this.selectTitle2==='4个位置'">
                        <h5 style="display:inline-block;">请选择号码</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='3个位置'">
                        <h5 style="display:inline-block;">请选择玩法和号码</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-model="checkedval" direction="horizontal" >
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ABC" @click="showcheckedval">ABC</van-checkbox>
                            <p style="margin-right:1.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ABD" @click="showcheckedval">ABD</van-checkbox>
                        </van-checkbox-group>   
                        <van-checkbox-group v-model="checkedval" direction="horizontal" > 
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ACD" @click="showcheckedval">ACD</van-checkbox>
                            <p style="margin-right:1.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="BCD" @click="showcheckedval">BCD</van-checkbox>
                        </van-checkbox-group>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='2个位置'">
                        <h5 style="display:inline-block;">请选择玩法和号码</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-model="checkedval" direction="horizontal" >
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="AB" @click="showcheckedval">AB</van-checkbox>
                            <p style="margin-right:0.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="AC" @click="showcheckedval">AC</van-checkbox>
                            <p style="margin-right:0.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="AD" @click="showcheckedval">AD</van-checkbox>
                        </van-checkbox-group>   
                        <van-checkbox-group v-model="checkedval" direction="horizontal" > 
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="BC" @click="showcheckedval">BC</van-checkbox>
                            <p style="margin-right:0.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="BD" @click="showcheckedval">BD</van-checkbox>
                            <p style="margin-right:0.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="CD" @click="showcheckedval">CD</van-checkbox>
                        </van-checkbox-group>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                </div>
                <div v-show="this.selectTitle1==='凹凸升降'">
                    <h5 style="display:inline-block;">请选择类型</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <!-- //ao tu up down level -->
                    <van-checkbox-group v-model="checkedval">
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="ao" @click="showcheckedval">凹下型 <span style="opacity:0.5;font-size:18px;">中间<span v-if="type===1">2</span><span v-if="type===3">1</span>个数均小于头尾</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="tu" @click="showcheckedval">凸起型 <span style="opacity:0.5;font-size:18px;">中间<span v-if="type===1">2</span><span v-if="type===3">1</span>个数均大于头尾</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="up" @click="showcheckedval">上升型 <span style="opacity:0.5;font-size:18px;">从左到右依次变大</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="down" @click="showcheckedval">下降型 <span style="opacity:0.5;font-size:18px;">从左到右依次变小</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="level" @click="showcheckedval">平行型 <span style="opacity:0.5;font-size:18px;"><span v-if="type===1">四</span><span v-if="type===3">三</span>个数完全相等</span></van-checkbox>
                    </van-checkbox-group>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <div v-show="this.selectTitle1==='两数合'">
                    <div  v-show="this.selectTitle2==='定位置'">
                        <h5 style="display:inline-block;">请选择位置、合值</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-model="checkedval" direction="horizontal" >
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="AB" @click="showcheckedval">AB</van-checkbox>
                            <p style="margin-right:0.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="AC" @click="showcheckedval">AC</van-checkbox>
                            <p style="margin-right:0.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="AD" @click="showcheckedval">AD</van-checkbox>
                        </van-checkbox-group>   
                        <van-checkbox-group v-model="checkedval" direction="horizontal" > 
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="BC" @click="showcheckedval">BC</van-checkbox>
                            <p style="margin-right:0.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="BD" @click="showcheckedval">BD</van-checkbox>
                            <p style="margin-right:0.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="CD" @click="showcheckedval">CD</van-checkbox>
                        </van-checkbox-group>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='不定位置'">
                        <h5 style="display:inline-block;">请选择合值</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p style="opacity:0.5">*任意两个数的合值中，有一个符合就满足条件</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                </div>
                <div v-show="this.selectTitle1==='三数合'">
                    <div  v-show="this.selectTitle2==='定位置'">
                        <h5 style="display:inline-block;">请选择位置、合值</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-model="checkedval" direction="horizontal" >
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ABC" @click="showcheckedval">ABC</van-checkbox>
                            <p style="margin-right:1.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ABD" @click="showcheckedval">ABD</van-checkbox>
                        </van-checkbox-group>   
                        <van-checkbox-group v-model="checkedval" direction="horizontal" > 
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ACD" @click="showcheckedval">ACD</van-checkbox>
                            <p style="margin-right:1.8rem"></p>
                            <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="BCD" @click="showcheckedval">BCD</van-checkbox>
                        </van-checkbox-group>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='不定位置'">
                        <h5 style="display:inline-block;">请选择合值</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p style="opacity:0.5">*任意三个数的合值中，有一个符合就满足条件</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                </div>
                <div v-show="this.selectTitle1==='合值（合分）'">
                    <h5 style="display:inline-block;">请选择合值</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <ol>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                    </ol>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                </div>
                <div v-show="this.selectTitle1==='单双（奇偶）'">
                    <div  v-show="this.selectTitle2==='按位快选'">
                        <h5 style="display:inline-block;">请选择号码</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-radio-group  v-model="radioval" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled1" >
                            <p style="font-size:0.5rem;margin-right:1.5rem" >千位</p>
                            <van-radio name="{name:'qian',code:1}" @click="showradioval">单</van-radio>
                            <van-radio name="{name:'qian',code:0}" @click="showradioval">双</van-radio>
                        </van-radio-group>
                        <van-radio-group  v-model="radioval2" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled2">
                            <p style="font-size:0.5rem;margin-right:1.5rem">百位</p>
                            <van-radio name="{name:'bai',code:1}" @click="showradioval">单</van-radio>
                            <van-radio name="{name:'bai',code:0}" @click="showradioval">双</van-radio>
                        </van-radio-group>
                        <van-radio-group v-model="radioval3" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled3">
                            <p style="font-size:0.5rem;margin-right:1.5rem">十位</p>
                            <van-radio name="{name:'shi',code:1}" @click="showradioval">单</van-radio>
                            <van-radio name="{name:'shi',code:0}" @click="showradioval">双</van-radio>
                        </van-radio-group>
                        <van-radio-group  v-model="radioval4" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled4">
                            <p style="font-size:0.5rem;margin-right:1.5rem">个位</p>
                            <van-radio name="{name:'ge',code:1}" @click="showradioval">单</van-radio>
                            <van-radio name="{name:'ge',code:0}" @click="showradioval">双</van-radio>
                        </van-radio-group>
                        <p style="opacity:0.5">*注：单：13579，双：02468</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                           
                        
                       
                    </div>
                    <div  v-show="this.selectTitle2==='按位任选'">
                        <h5 style="display:inline-block;">请选择组合方式，从左到右分别是：千百十个</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-if="type===1" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in (commoncombination1_4)" :key="index" :name="item" @click="showcheckedval2()">{{single1_4[index]}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===3" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in (commoncombination1_3)" :key="index" :name="item" @click="showcheckedval2()">{{single1_3[index]}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===5" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in (commoncombination1_2)" :key="index" :name="item" @click="showcheckedval2()">{{single1_2[index]}} </van-checkbox>
                        </van-checkbox-group>
                        <p style="opacity:0.5">*单13579,双02468</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='出现比例'">
                        <h5 style="display:inline-block;">请选择"单：双"出现的比例</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-if="type===1||type===2" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio1_4" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===3||type===4" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio1_3" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===5||type===6" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio1_2" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <p style="opacity:0.5" v-if="type===1||type===2">*这里的01234指的是出现的个数</p>
                        <p style="opacity:0.5" v-if="type===3||type===4">*这里的0123指的是出现的个数</p>
                        <p style="opacity:0.5" v-if="type===5||type===6">*这里的012指的是出现的个数</p>
                        <p style="opacity:0.5">*注：单13579,双02468</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='断组'">
                        <h5 style="display:inline-block;">请选择断组的类型</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"    name="true" @click="showcheckedval2()" style="width:6rem;height:1rem;">单双不能同时出现</van-checkbox>
                        </van-checkbox-group>
                        
                        <p style="opacity:0.5">*注：单13579,双02468</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                </div>
                <div v-show="this.selectTitle1==='大小'">
                    <div  v-show="this.selectTitle2==='按位快选'">
                        <h5 style="display:inline-block;">请选择号码</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-radio-group  v-model="radioval" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled1" >
                            <p style="font-size:0.5rem;margin-right:1.5rem" >千位</p>
                            <van-radio name="{name:'qian',code:1}" @click="showradioval">大</van-radio>
                            <van-radio name="{name:'qian',code:0}" @click="showradioval">小</van-radio>
                        </van-radio-group>
                        <van-radio-group  v-model="radioval2" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled2">
                            <p style="font-size:0.5rem;margin-right:1.5rem">百位</p>
                            <van-radio name="{name:'bai',code:1}" @click="showradioval">大</van-radio>
                            <van-radio name="{name:'bai',code:0}" @click="showradioval">小</van-radio>
                        </van-radio-group>
                        <van-radio-group v-model="radioval3" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled3">
                            <p style="font-size:0.5rem;margin-right:1.5rem">十位</p>
                            <van-radio name="{name:'shi',code:1}" @click="showradioval">大</van-radio>
                            <van-radio name="{name:'shi',code:0}" @click="showradioval">小</van-radio>
                        </van-radio-group>
                        <van-radio-group  v-model="radioval4" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled4">
                            <p style="font-size:0.5rem;margin-right:1.5rem">个位</p>
                            <van-radio name="{name:'ge',code:1}" @click="showradioval">大</van-radio>
                            <van-radio name="{name:'ge',code:0}" @click="showradioval">小</van-radio>
                        </van-radio-group>
                        <p style="opacity:0.5">*注：小：01234，大：56789</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                           
                        
                       
                    </div>
                    <div  v-show="this.selectTitle2==='按位任选'">
                        <h5 style="display:inline-block;">请选择组合方式，从左到右分别是：千百十个</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-if="type===1" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in (commoncombination1_4)" :key="index" :name="item" @click="showcheckedval2()">{{big1_4[index]}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===3" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in (commoncombination1_3)" :key="index" :name="item" @click="showcheckedval2()">{{big1_3[index]}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===5" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in (commoncombination1_2)" :key="index" :name="item" @click="showcheckedval2()">{{big1_2[index]}} </van-checkbox>
                        </van-checkbox-group>
                        <p style="opacity:0.5">*注：小：01234，大：56789</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='出现比例'">
                        <h5 style="display:inline-block;">请选择"大：小"出现的比例</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-if="type===1||type===2" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio1_4" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===3||type===4" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio1_3" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===5||type===6" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio1_2" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <p style="opacity:0.5" v-if="type===1||type===2">*这里的01234指的是出现的个数</p>
                        <p style="opacity:0.5" v-if="type===3||type===4">*这里的0123指的是出现的个数</p>
                        <p style="opacity:0.5" v-if="type===5||type===6">*这里的012指的是出现的个数</p>
                        <p style="opacity:0.5">*注：小：01234，大：56789</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='断组'">
                        <h5 style="display:inline-block;">请选择断组的类型</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"    name="true" @click="showcheckedval2()" style="width:6rem;height:1rem;">大小不同时出现</van-checkbox>
                        </van-checkbox-group>
                        
                        <p style="opacity:0.5">*注：小：01234，大：56789</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                </div>
                <div v-show="this.selectTitle1==='质合'">
                    <div  v-show="this.selectTitle2==='按位快选'">
                        <h5 style="display:inline-block;">请选择号码</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-radio-group  v-model="radioval" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled1" >
                            <p style="font-size:0.5rem;margin-right:1.5rem" >千位</p>
                            <van-radio name="{name:'qian',code:1}" @click="showradioval">质</van-radio>
                            <van-radio name="{name:'qian',code:0}" @click="showradioval">合</van-radio>
                        </van-radio-group>
                        <van-radio-group  v-model="radioval2" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled2">
                            <p style="font-size:0.5rem;margin-right:1.5rem">百位</p>
                            <van-radio name="{name:'bai',code:1}" @click="showradioval">质</van-radio>
                            <van-radio name="{name:'bai',code:0}" @click="showradioval">合</van-radio>
                        </van-radio-group>
                        <van-radio-group v-model="radioval3" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled3">
                            <p style="font-size:0.5rem;margin-right:1.5rem">十位</p>
                            <van-radio name="{name:'shi',code:1}" @click="showradioval">质</van-radio>
                            <van-radio name="{name:'shi',code:0}" @click="showradioval">合</van-radio>
                        </van-radio-group>
                        <van-radio-group  v-model="radioval4" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled4">
                            <p style="font-size:0.5rem;margin-right:1.5rem">个位</p>
                            <van-radio name="{name:'ge',code:1}" @click="showradioval">质</van-radio>
                            <van-radio name="{name:'ge',code:0}" @click="showradioval">合</van-radio>
                        </van-radio-group>
                        <p style="opacity:0.5">*注：质：12357，合：04688</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                           
                        
                       
                    </div>
                    <div  v-show="this.selectTitle2==='按位任选'">
                        <h5 style="display:inline-block;">请选择组合方式，从左到右分别是：千百十个</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-if="type===1" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in (commoncombination1_4)" :key="index" :name="item" @click="showcheckedval2()">{{prime1_4[index]}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===3" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in (commoncombination1_3)" :key="index" :name="item" @click="showcheckedval2()">{{prime1_3[index]}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===5" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in (commoncombination1_2)" :key="index" :name="item" @click="showcheckedval2()">{{prime1_2[index]}} </van-checkbox>
                        </van-checkbox-group>
                        <p style="opacity:0.5">*注：质：12357，合：04688</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='出现比例'">
                        <h5 style="display:inline-block;">请选择"质：合"出现的比例</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-if="type===1||type===2" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio1_4" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===3||type===4" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio1_3" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===5||type===6" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio1_2" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <p style="opacity:0.5" v-if="type===1||type===2">*这里的01234指的是出现的个数</p>
                        <p style="opacity:0.5" v-if="type===3||type===4">*这里的0123指的是出现的个数</p>
                        <p style="opacity:0.5" v-if="type===5||type===6">*这里的012指的是出现的个数</p>
                        <p style="opacity:0.5">*注：质：12357，合：04688</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='断组'">
                        <h5 style="display:inline-block;">请选择断组的类型</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group  v-model="checkedval" direction="horizontal" >
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"    name="true" @click="showcheckedval2()" style="width:6rem;height:1rem;">质合不同时出现</van-checkbox>
                        </van-checkbox-group>
                        
                        <p style="opacity:0.5">*注：质：12357，合：04688</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                </div>
                <div v-show="this.selectTitle1==='大中小'">
                    <div  v-show="this.selectTitle2==='按位快选'">
                        <h5 style="display:inline-block;">请选择号码</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-radio-group  v-model="radioval" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled1" >
                            <p style="font-size:0.5rem;margin-right:1.5rem" >千位</p>
                            <van-radio name="{name:'qian',code:2}" @click="showradioval">大</van-radio>
                            <van-radio name="{name:'qian',code:1}" @click="showradioval">中</van-radio>
                            <van-radio name="{name:'qian',code:0}" @click="showradioval">小</van-radio>
                        </van-radio-group>
                        <van-radio-group  v-model="radioval2" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled2">
                            <p style="font-size:0.5rem;margin-right:1.5rem">百位</p>
                            <van-radio name="{name:'bai',code:2}" @click="showradioval">大</van-radio>
                            <van-radio name="{name:'bai',code:1}" @click="showradioval">中</van-radio>
                            <van-radio name="{name:'bai',code:0}" @click="showradioval">小</van-radio>
                        </van-radio-group>
                        <van-radio-group v-model="radioval3" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled3">
                            <p style="font-size:0.5rem;margin-right:1.5rem">十位</p>
                            <van-radio name="{name:'shi',code:2}" @click="showradioval">大</van-radio>
                            <van-radio name="{name:'shi',code:1}" @click="showradioval">中</van-radio>
                            <van-radio name="{name:'shi',code:0}" @click="showradioval">小</van-radio>
                        </van-radio-group>
                        <van-radio-group  v-model="radioval4" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled4">
                            <p style="font-size:0.5rem;margin-right:1.5rem">个位</p>
                            <van-radio name="{name:'ge',code:2}" @click="showradioval">大</van-radio>
                            <van-radio name="{name:'ge',code:1}" @click="showradioval">中</van-radio>
                            <van-radio name="{name:'ge',code:0}" @click="showradioval">小</van-radio>
                        </van-radio-group>
                        <p style="opacity:0.5">*注：小：012，中：3456，大：789</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                           
                        
                       
                    </div>
                    <div  v-show="this.selectTitle2==='按位任选'">
                        <h5 style="display:inline-block;">请选择组合方式，从左到右分别是：千百十个</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-tabs v-model="tableval" v-if="type===1">
                            <van-tab title="千位=大">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_4.slice(0,27)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_4.slice(0,27)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="千位=中">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_4.slice(27,54)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_4.slice(27,54)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="千位=小">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_4.slice(54,81)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_4.slice(54,81)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                        </van-tabs>
                        <van-tabs v-model="tableval" v-if="type===3">
                            <van-tab title="XBCD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_3.slice(0,27)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_3.slice(0,27)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="AXCD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_3.slice(27,54)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_3.slice(27,54)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="ABXD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_3.slice(54,81)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_3.slice(54,81)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="ABCX">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_3.slice(81,108)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_3.slice(81,108)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                        </van-tabs>
                        <van-tabs v-model="tableval" v-if="type===5">
                            <van-tab title="XXCD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(0,9)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_2.slice(0,9)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="XBXD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(9,18)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_2.slice(9,18)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="XBCX">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(18,27)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_2.slice(18,27)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="AXXD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(27,36)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_2.slice(27,36)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="AXCX">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(36,45)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_2.slice(36,45)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="ABXX">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(45,54)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{big2_2.slice(45,54)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                        </van-tabs>
                    
                        <p style="opacity:0.5">*注：小：012，中：3456，大：789</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='出现比例'">
                        <h5 style="display:inline-block;">请选择"大：中：小"出现的比例</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-if="type===1||type===2" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio2_4" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===3||type===4" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio2_3" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===5||type===6" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio2_2" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <p style="opacity:0.5" v-if="type===1||type===2">*这里的01234指的是出现的个数</p>
                        <p style="opacity:0.5" v-if="type===3||type===4">*这里的0123指的是出现的个数</p>
                        <p style="opacity:0.5" v-if="type===5||type===6">*这里的012指的是出现的个数</p>
                        <p style="opacity:0.5">*注：小：012，中：3456，大：789</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='断组'">
                        <h5 style="display:inline-block;">请选择断组的类型</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"    name="true" @click="showcheckedval2()" style="width:6rem;height:1rem;">大中小不同时出现</van-checkbox>
                        </van-checkbox-group>
                        
                        <p style="opacity:0.5">*注：小：012，中：3456，大：789</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                </div>
                <div v-show="this.selectTitle1==='012路'">
                    <div  v-show="this.selectTitle2==='按位快选'">
                        <h5 style="display:inline-block;">请选择号码</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-radio-group  v-model="radioval" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled1" >
                            <p style="font-size:0.5rem;margin-right:1rem" >千位</p>
                            <van-radio name="{name:'qian',code:2}" @click="showradioval">2路</van-radio>
                            <van-radio name="{name:'qian',code:1}" @click="showradioval">1路</van-radio>
                            <van-radio name="{name:'qian',code:0}" @click="showradioval">0路</van-radio>
                        </van-radio-group>
                        <van-radio-group  v-model="radioval2" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled2">
                            <p style="font-size:0.5rem;margin-right:1rem">百位</p>
                            <van-radio name="{name:'bai',code:2}" @click="showradioval">2路</van-radio>
                            <van-radio name="{name:'bai',code:1}" @click="showradioval">1路</van-radio>
                            <van-radio name="{name:'bai',code:0}" @click="showradioval">0路</van-radio>
                        </van-radio-group>
                        <van-radio-group v-model="radioval3" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled3">
                            <p style="font-size:0.5rem;margin-right:1rem">十位</p>
                            <van-radio name="{name:'shi',code:2}" @click="showradioval">2路</van-radio>
                            <van-radio name="{name:'shi',code:1}" @click="showradioval">1路</van-radio>
                            <van-radio name="{name:'shi',code:0}" @click="showradioval">0路</van-radio>
                        </van-radio-group>
                        <van-radio-group  v-model="radioval4" direction="horizontal" :checked-color="save?'green':'red'" :disabled="disabled4">
                            <p style="font-size:0.5rem;margin-right:1rem">个位</p>
                            <van-radio name="{name:'ge',code:2}" @click="showradioval">2路</van-radio>
                            <van-radio name="{name:'ge',code:1}" @click="showradioval">1路</van-radio>
                            <van-radio name="{name:'ge',code:0}" @click="showradioval">0路</van-radio>
                        </van-radio-group>
                        <p style="opacity:0.5">*注：0路：0369，1路：147，2路：258</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                           
                        
                       
                    </div>
                    <div  v-show="this.selectTitle2==='按位任选'">
                        <h5 style="display:inline-block;">请选择组合方式，从左到右分别是：千百十个</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-tabs v-model="tableval" v-if="type===1">
                            <van-tab title="千位=2路">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_4.slice(0,27)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_4.slice(0,27)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="千位=1路">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_4.slice(27,54)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_4.slice(27,54)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="千位=0路">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_4.slice(54,81)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_4.slice(54,81)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                        </van-tabs>
                        <van-tabs v-model="tableval" v-if="type===3">
                            <van-tab title="XBCD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_3.slice(0,27)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_3.slice(0,27)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="AXCD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_3.slice(27,54)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_3.slice(27,54)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="ABXD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_3.slice(54,81)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_3.slice(54,81)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="ABCX">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_3.slice(81,108)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_3.slice(81,108)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                        </van-tabs>
                        <van-tabs v-model="tableval" v-if="type===5">
                            <van-tab title="XXCD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(0,9)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_2.slice(0,9)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="XBXD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(9,18)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_2.slice(9,18)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="XBCX">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(18,27)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_2.slice(18,27)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="AXXD">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(27,36)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_2.slice(27,36)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="AXCX">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(36,45)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_2.slice(36,45)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                            <van-tab title="ABXX">
                                <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                    <van-checkbox class="checkboxclass2" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in commoncombination2_2.slice(45,54)" :key="index" :name="item" @click="showcheckedval2()"><span style="font-size:0.3rem;">{{way2_2.slice(45,54)[index]}} </span></van-checkbox>
                                </van-checkbox-group>
                            </van-tab>
                        </van-tabs>
                    
                        <p style="opacity:0.5">*注：0路：0369，1路：147，2路：258</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='出现比例'">
                        <h5 style="display:inline-block;">请选择"2路：1路：0路"出现的比例</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group v-if="type===1||type===2" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio2_4" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===3||type===4" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio2_3" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <van-checkbox-group v-if="type===5||type===6" v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"   v-for="(item,index) in ratio2_2" :key="index" :name="item" @click="showcheckedval2()">{{item}} </van-checkbox>
                        </van-checkbox-group>
                        <p style="opacity:0.5" v-if="type===1||type===2">*这里的01234指的是出现的个数</p>
                        <p style="opacity:0.5" v-if="type===3||type===4">*这里的0123指的是出现的个数</p>
                        <p style="opacity:0.5" v-if="type===5||type===6">*这里的012指的是出现的个数</p>
                        <p style="opacity:0.5">*注：0路：0369，1路：147，2路：258</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                    <div  v-show="this.selectTitle2==='断组'">
                        <h5 style="display:inline-block;">请选择断组的类型</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <van-checkbox-group  v-model="checkedval" direction="horizontal">
                                <van-checkbox class="checkboxclass" :checked-color="save?'green':'red'"  shape="square"    name="true" @click="showcheckedval2()" style="width:6rem;height:1rem;">012路不同时出现</van-checkbox>
                        </van-checkbox-group>
                        
                        <p style="opacity:0.5">*注：0路：0369，1路：147，2路：258</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                    </div>
                </div>
                <div v-show="this.selectTitle1==='对数'">
                    <h5 style="display:inline-block;">请选择号码中出现对数的情况</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <!-- //1 、2 组 -->
                    <van-checkbox-group v-model="checkedval">
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="1" @click="showcheckedval">至少包含1组 </van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="2" v-if="type===1||type===2" @click="showcheckedval">至少包含2组 </van-checkbox>
                    </van-checkbox-group>
                    <p style="opacity:0.5">*至少包含2组 又名“双对数”、“对对数”</p>
                    <p style="opacity:0.5;margin-top: -0.3rem;">*特例说明：例如0508只算包含1组对数</p>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <div v-show="this.selectTitle1==='配号码'">
                    <h5 style="display:inline-block;">请选择号码</h5>
                    <!-- <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button> -->
                    <hr/>
                    <ol>
                        <p>第一组</p>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in group1" :key="index" @click="change(group1,index)">{{index}}</li>
                    </ol>
                    <ol>
                        <p>第二组</p>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in group2" :key="index" @click="change(group2,index)">{{index}}</li>
                    </ol>
                    <ol v-if="type===1||type===2||type===3||type===4">
                        <p>第三组</p>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in group3" :key="index" @click="change(group3,index)">{{index}}</li>
                    </ol>
                    <ol v-if="type===1||type===2">
                        <p>第四组</p>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in group4" :key="index" @click="change(group4,index)">{{index}}</li>
                    </ol>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                </div>
                <div v-show="this.selectTitle1==='排除码（死数）'">
                    <h5 style="display:inline-block;">请选择需要排除的号码</h5>
                    <hr/>
                    <ol>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                    </ol>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                </div>
                <div v-show="this.selectTitle1==='必出码（铁率）'">
                    <h5 style="display:inline-block;">请选择号码</h5>
                    <hr/>
                    <ol>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                    </ol>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <div v-show="this.selectTitle1==='重数'">
                    <h5 style="display:inline-block;">请选择重复的类型</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <van-checkbox-group v-model="checkedval">
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="1" @click="showcheckedval">双重 <span style="opacity:0.5;font-size:13px;">含2个相同的数字</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="2" v-if="type===1||type===2" @click="showcheckedval">双双重 <span style="opacity:0.5;font-size:13px;">含2个数字(可以相等)，分别重复2次</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="3" v-if="type===1||type===2||type===3||type===4" @click="showcheckedval">三重 <span style="opacity:0.5;font-size:13px;">含3个相同的数字</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="4" v-if="type===1||type===2" @click="showcheckedval">四重 <span style="opacity:0.5;font-size:13px;">含4个相同的数字</span></van-checkbox>
                    </van-checkbox-group>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <div v-show="this.selectTitle1==='连号（兄弟号）'">
                    <h5 style="display:inline-block;">请选择连号的类型</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <van-checkbox-group v-model="checkedval">
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'" v-if="type===1||type==2||type==3||type==4||type==5||type==6" shape="square" name="2" @click="showcheckedval">二连号 <span style="opacity:0.5;font-size:18px;">出现2个相邻的号码</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'" v-if="type===1||type==2||type==3||type==4" shape="square" name="3" @click="showcheckedval">三连号 <span style="opacity:0.5;font-size:18px;">出现3个相邻的号码</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'" v-if="type===1||type==2" shape="square" name="4" @click="showcheckedval">四连号 <span style="opacity:0.5;font-size:18px;">出现4个相邻的号码</span></van-checkbox>
                    </van-checkbox-group>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                </div>
                <div v-show="this.selectTitle1==='入数个数'">
                    <h5 style="display:inline-block;">请选择数量</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <van-checkbox-group v-model="checkedval">
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="1" @click="showcheckedval">1个 <span style="opacity:0.5;font-size:18px;">所有号码相同</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="2" @click="showcheckedval">2个 <span style="opacity:0.5;font-size:18px;">只含2个不同的号码</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="3" v-if="type===1||type===2||type===3||type===4" @click="showcheckedval">3个 <span style="opacity:0.5;font-size:18px;">只含3个不同的号码</span></van-checkbox>
                        <van-checkbox style="margin-bottom: 0.3rem;" :checked-color="save?'green':'red'"  shape="square" name="4" v-if="type===1||type===2" @click="showcheckedval">4个 <span style="opacity:0.5;font-size:18px;">含4个不相同的数字</span></van-checkbox>
                    </van-checkbox-group>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <div v-show="this.selectTitle1==='极值'">
                    <div  v-show="this.selectTitle2==='最大值'">
                        <h5 style="display:inline-block;">请选择号码组中，出现的最大的数字</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                    
                    </div>
                    <div  v-show="this.selectTitle2==='最小值'">
                        <h5 style="display:inline-block;">请选择号码组中，出现的最小的数字</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                    
                    </div>
                </div>
                <div v-show="this.selectTitle1==='跨度'">
                    <h5 style="display:inline-block;">请选择号码</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <ol>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                    </ol>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                
                </div>
                <div v-show="this.selectTitle1==='和值'">
                    <h5 style="display:inline-block;">请选择和值</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <!-- 四定、四现 -->
                        <ol v-if="type===1||type===2">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val37" :key="index" @click="change(val37,index)">{{index}}</li>
                        </ol>
                        <!-- 三定、三现 -->
                        <ol v-if="type===3||type===4">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val28" :key="index" @click="change(val28,index)">{{index}}</li>
                        </ol>
                        <!-- 二定、二现 -->
                        <ol v-if="type===5||type===6">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val19" :key="index" @click="change(val19,index)">{{index}}</li>
                        </ol>

                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                    
                </div>
                <div v-show="this.selectTitle1==='合值+跨度'">
                    <h5 style="display:inline-block;">请选择数值</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <ol v-if="type===1||type===2||type===3||type===4">
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in positiveval19" :key="index" @click="change(positiveval19,index)">{{index}}</li>
                    </ol>
                    <ol v-if="type===5||type===6">
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in positiveval10" :key="index" @click="change(positiveval10,index)">{{index*2}}</li>
                    </ol>
                    <p style="opacity:0.5">*合值：所有数字相加所得之和的个位（和尾）</p>
                    <p style="opacity:0.5">*跨度：最大值与最小值值差称为跨度</p>
                    <p v-if="type===5||type===6" style="opacity:0.5">*注数值1,2,5,7,9,11,13,15,17筛选无结果</p>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <!-- 减式未完成 -->
                <div v-show="this.selectTitle1==='减式'">
                    <h5 style="display:inline-block;">请选择位置、合值</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                   
                    <button :class="front[0]?save?'selectsaveletter':'selectoutletter':'unselectletter'" @click="changebuttonval2('front',0)">A</button>
                    <button :class="front[1]?save?'selectsaveletter':'selectoutletter':'unselectletter'" @click="changebuttonval2('front',1)">B</button>
                    <button :class="front[2]?save?'selectsaveletter':'selectoutletter':'unselectletter'" @click="changebuttonval2('front',2)">C</button>
                    <button :class="front[3]?save?'selectsaveletter':'selectoutletter':'unselectletter'" @click="changebuttonval2('front',3)">D</button>
                    <p style="font-size:0.5rem;margin:0.28rem 0.5rem;display:inline-block">减去</p>
                    <button :class="after[0]?save?'selectsaveletter':'selectoutletter':'unselectletter'" @click="changebuttonval2('after',0)">A</button>
                    <button :class="after[1]?save?'selectsaveletter':'selectoutletter':'unselectletter'" @click="changebuttonval2('after',1)">B</button>
                    <button :class="after[2]?save?'selectsaveletter':'selectoutletter':'unselectletter'" @click="changebuttonval2('after',2)">C</button>
                    <button :class="after[3]?save?'selectsaveletter':'selectoutletter':'unselectletter'" @click="changebuttonval2('after',3)">D</button>
                    <ol>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                    </ol>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <div v-show="this.selectTitle1==='合值-跨度'">
                    <h5 style="display:inline-block;">请选择数值</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <ol v-if="type===1||type===2">
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in nagetiveval18" :key="index" @click="change(nagetiveval18,index)">{{index-9}}</li>
                    </ol>
                    <ol v-if="type===3||type===4">
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in nagetiveval19" :key="index" @click="change(nagetiveval19,index)">{{index-9}}</li>
                    </ol>
                    <ol v-if="type===5||type===6">
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in nagetiveval9" :key="index" @click="change(nagetiveval9,index)">{{-8+index*2}}</li>
                    </ol>
                    <p style="opacity:0.5">*合值：所有数字相加所得之和的个位（和尾）</p>
                    <p style="opacity:0.5">*跨度：最大值与最小值值差称为跨度</p>
                    <p v-if="type===5||type===6" style="opacity:0.5">*注数值1,2,5,7,9,11,13,15,17筛选无结果</p>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <div v-show="this.selectTitle1==='两数乘积'">
                    <h5 style="display:inline-block;">请选择位置、合值</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <!-- 二现没有位置选择 -->
                    <van-checkbox-group v-model="checkedval" direction="horizontal" v-if="type!==6" >
                        <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ab" @click="showcheckedval">AB</van-checkbox>
                        <p style="margin-right:0.8rem"></p>
                        <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ac" @click="showcheckedval">AC</van-checkbox>
                        <p style="margin-right:0.8rem"></p>
                        <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="ad" @click="showcheckedval">AD</van-checkbox>
                    </van-checkbox-group>   
                    <van-checkbox-group v-model="checkedval" direction="horizontal" v-if="type!==6" > 
                        <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="bc" @click="showcheckedval">BC</van-checkbox>
                        <p style="margin-right:0.8rem"></p>
                        <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="bd" @click="showcheckedval">BD</van-checkbox>
                        <p style="margin-right:0.8rem"></p>
                        <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="cd" @click="showcheckedval">CD</van-checkbox>
                    </van-checkbox-group>
                    <ol>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                    </ol>
                    <p style="opacity:0.5">*含X的号码组不会被筛选出来</p>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <div v-show="this.selectTitle1==='三数乘积'">
                    <h5 style="display:inline-block;">请选择位置、合值</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <!-- 三现没有位置选择 -->
                    <van-checkbox-group v-model="checkedval" direction="horizontal" v-if="type!==4">
                        <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="abc" @click="showcheckedval">ABC</van-checkbox>
                        <p style="margin-right:1.8rem"></p>
                        <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="abd" @click="showcheckedval">ABD</van-checkbox>
                    </van-checkbox-group>   
                    <van-checkbox-group v-model="checkedval" direction="horizontal" v-if="type!==4"> 
                        <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="acd" @click="showcheckedval">ACD</van-checkbox>
                        <p style="margin-right:1.8rem"></p>
                        <van-checkbox :checked-color="save?'green':'red'"  shape="square" name="bcd" @click="showcheckedval">BCD</van-checkbox>
                    </van-checkbox-group>
                    <ol>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                    </ol>
                    <p style="opacity:0.5">*含X的号码组不会被筛选出来</p>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>

                </div>
                <div v-show="this.selectTitle1==='四数乘积'">
                    <h5 style="display:inline-block;">请选择合值</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <ol>
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                    </ol>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                
                </div>
                <div v-show="this.selectTitle1==='位积'">
                    <div  v-show="this.selectTitle2==='和值'">
                        <h5 style="display:inline-block;">请选择A*1+B*2+c*3+D*4的和值</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <ol v-if="type===1||type===2">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val91" :key="index" @click="change(val91,index)">{{index}}</li>
                        </ol>
                        <ol v-if="type===3||type===4">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val82" :key="index" @click="change(val82,index)">{{index}}</li>
                        </ol>
                        <ol v-if="type===5||type===6">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val64" :key="index" @click="change(val64,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                
                    </div>
                    <div  v-show="this.selectTitle2==='合值'">
                        <h5 style="display:inline-block;">请选择A*1+B*2+c*3+D*4的合值</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                
                    </div>
                </div>
                <div v-show="this.selectTitle1==='反位积'">
                    <div  v-show="this.selectTitle2==='和值'">
                        <h5 style="display:inline-block;">请选择A*4+B*3+c*2+D*1的和值</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <ol v-if="type===1||type===2">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val91" :key="index" @click="change(val91,index)">{{index}}</li>
                        </ol>
                        <ol v-if="type===3||type===4">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val82" :key="index" @click="change(val82,index)">{{index}}</li>
                        </ol>
                        <ol v-if="type===5||type===6">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val64" :key="index" @click="change(val64,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                
                    </div>
                    <div  v-show="this.selectTitle2==='合值'">
                        <h5 style="display:inline-block;">请选择A*4+B*3+C*2+D*1的合值</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <ol>
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in numberval" :key="index" @click="change(numberval,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                    
                    </div>
                </div>
                <div v-show="this.selectTitle1==='余数和'">
                        <h5 style="display:inline-block;">请选择A/3+B/3+C/3+D/3的余数之和</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <ol v-if="type===1||type===2">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val9" :key="index" @click="change(val9,index)">{{index}}</li>
                        </ol>
                        <ol v-if="type===3||type===4">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val7" :key="index" @click="change(val7,index)">{{index}}</li>
                        </ol>
                        <ol v-if="type===5||type===6">
                            <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val5" :key="index" @click="change(val5,index)">{{index}}</li>
                        </ol>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                    
                </div>
                <div v-show="this.selectTitle1==='开奖历史'">
                    <div  v-show="this.selectTitle2==='七星彩'">
                        <h5 style="display:inline-block;">请设置开奖历史期间</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <h5>七星彩</h5>
                        <div style="display:block">
                            <button :class="(buttonval===10)?save?'selectsave2':'selectout2':'unselect2'" @click="changebuttonval(10)">近10期</button>
                            <button :class="(buttonval===20)?save?'selectsave2':'selectout2':'unselect2'" @click="changebuttonval(20)">近20期</button>
                            <button :class="(buttonval===50)?save?'selectsave2':'selectout2':'unselect2'" @click="changebuttonval(50)">近50期</button>
                            <button :class="(buttonval===100)?save?'selectsave2':'selectout2':'unselect2'" @click="changebuttonval(100)">近100期</button>
                        </div>
                        <div style="display:block">
                            <button :class="save?'selectsave3':'selectout3'">
                                <div v-if="startnumseven===''">开始期数</div>
                                <div v-if="startnumseven!==''">{{startnumseven}}</div>
                            </button>
                            <span style="font-size:0.6rem;text-align: center;margin-left: 0.3rem;">~</span>
                            <button :class="save?'selectsave3':'selectout3'">
                                <div v-if="endnumseven===''">结束期号</div>
                                <div v-if="endnumseven!==''">{{endnumseven}}</div>
                            </button>
                        </div>
                        
                        
                        <p style="opacity:0.5">*此处的期号为本地期号，暂不支持自定义修改</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                    </div>
                    <div  v-show="this.selectTitle2==='排列五'">
                        <h5 style="display:inline-block;">请设置开奖历史期间</h5>
                        <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                        <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                        <hr/>
                        <h5>排列五</h5>
                        <div style="display:block">
                            <button :class="(buttonval2===10)?save?'selectsave2':'selectout2':'unselect2'" @click="changebuttonval(10)">近10期</button>
                            <button :class="(buttonval2===20)?save?'selectsave2':'selectout2':'unselect2'" @click="changebuttonval(20)">近20期</button>
                            <button :class="(buttonval2===50)?save?'selectsave2':'selectout2':'unselect2'" @click="changebuttonval(50)">近50期</button>
                            <button :class="(buttonval2===100)?save?'selectsave2':'selectout2':'unselect2'" @click="changebuttonval(100)">近100期</button>
                        </div>
                        <div style="display:block">
                            <button :class="save?'selectsave3':'selectout3'">
                                <div v-if="startnumfive===''">开始期数</div>
                                <div v-if="startnumfive!==''">{{startnumfive}}</div>
                            </button>
                            <span style="font-size:0.6rem;text-align: center;margin-left: 0.3rem;">~</span>
                            <button :class="save?'selectsave3':'selectout3'">
                                <div v-if="endnumfive===''">结束期号</div>
                                <div v-if="endnumfive!==''">{{endnumfive}}</div>
                            </button>
                        </div>
                        
                        
                        <p style="opacity:0.5">*此处的期号为本地期号，暂不支持自定义修改</p>
                        <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                        <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                    </div>
                </div>
                <div v-show="this.selectTitle1==='全转（全倒）'">
                    <h5 style="display:inline-block;">点击下方数字键盘，输入数字</h5>
                    
                    <hr/>
                    <ol>
                        <li  :class="'unchecked'" v-for="(item,index) in 10" :key="index" @click="change2(index)">{{index}}</li>
                    </ol>
                    
                   
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                </div>
                <div v-show="this.selectTitle1==='AC值'">
                    <h5 style="display:inline-block;">请选择合值</h5>
                    <button :class="save?'selectsave':'unselect'" @click="save=!save">保留</button>
                    <button :class="save?'unselect':'selectout'" @click="save=!save">排除</button>
                    <hr/>
                    <ol v-if="type===1||type===2">
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val6" :key="index" @click="change(val6,index)">{{index+1}}</li>
                    </ol>
                    <ol v-if="type===3||type===4">
                        <li  :class="item?save?'checkedsave':'checkedout':'unchecked'" v-for="(item,index) in val3" :key="index" @click="change(val3,index)">{{index+1}}</li>
                    </ol>
                    <p style="opacity:0.5">*当AC=1，所有号码都相同</p>
                    <p style="opacity:0.5">*AC值越大，意味着号码越复杂</p>
                    <p v-show="this.save" style="color:green;"><span v-show="selectnumber!==''" style="color:white;background-color:green;">保留</span>  {{selectnumber}}</p>
                    <p v-show="this.save===false" style="color:red;"><span v-show="selectnumber!==''" style="color:white;background-color:red;">排除</span>  {{selectnumber}}</p>
                </div>
            </div>

            <div class="popbutton">
                <van-button type="default" style="background-color: #7a7a7a" @click="cancel">清除</van-button>
                <van-button type="danger" style="background-color: red" @click="confirmed">确定</van-button>
            </div>
            
        </van-popup>


        <!-- 已选的条件集合弹窗 -->
        <div class="pop2">
            <van-popup  v-model="selectShow2" position="bottom"  :closeable="true" :style="{ maxHeight: '80%'}"  >
            <div class="pophead">
                <button :class="unionSet?'selectsave':'unselect'" @click="selectUnion(1)">并集</button>
                <button :class="unionSet?'unselect':'selectout'" @click="selectUnion(0)">交集</button>
                <van-icon name="delete-o" @click="clear">清空</van-icon>
            </div>
            <div class="popbody">
                <p>单击条件可以进行修改内容</p>
                <div v-for="(item,index) in selectList" :key="index">
                    <div v-for="(ite,index) in item.btn" :key="index">
                       
                       <div class="items" v-if="ite.isTrue===1||ite.isTrue===2">
                            <van-icon name="passed" :class="ite.isTrue===1?'choose':'unchoose'" @click="chooses(item.name,ite.name,ite.isTrue)" />
                            <div @click="condSelected(item.name,ite.name)">
                                <p>{{item.name}} {{ite.name}}</p>
                                <p>
                                    <span v-show="ite.isSave===1">保留</span>
                                    <span v-show="ite.isSave===0">排除</span>

                                    {{ite.msg}}
                                </p>
                            </div>
                            <button @click="deletechoose(item.name,ite.name)">删除</button>
                        </div>
                    </div>
                </div>
                        
                        
                 
                        
                        
                    
               
            </div>
            
        </van-popup>
        </div>


        <!-- 下端展示区 -->
        <div class="bottom_box">
            <div class="bottom_one">
                <div>
                    <p>{{maxall}}</p>
                    <p style="background-color:red;position:fixed;bottom:0px;width: 2rem;height: 0.5rem;text-align:center;font-size:0.3rem;">{{maxMsg}}</p>
                </div>
                    
            </div>
            <div class="bottom_two" @click="condSelect2">
                <div>
                    <p>已选择{{selected}}个条件</p>
                    <p>共计{{waitingselected}}个条件</p>
                </div>
               
            </div>
            <div class="bottom_three">
                <div @click="gotoshownumber">
                    <p>{{this.lastall.length}}组</p>
                    <p>筛选结果>></p>
                </div>
            </div>
                    
            
        </div>
        

    </div>
    
    

</div>
</template>

<script>
// import isWeixin from "@/utils/utils";
import vHeader from "@/components/header.vue"
import { Toast } from 'vant';
// import { ref } from 'vue';
export default {
    data(){
        return{
            //减式需要的数据
            front:[false,false,false,false],
            after:[false,false,false,false],
            newestseven:0,//最新期初
            newestfive:0,//最新期初
            lotteryhistorysave:true,
            lotteryhistoryseven4:[], //七星彩的往期数据 四定
            lotteryhistoryseven3:[], //七星彩的往期数据 三定
            lotteryhistoryseven2:[], //七星彩的往期数据 二定
            lotteryhistoryfive4:[],  //排列五的往期数据 四定
            lotteryhistoryfive3:[],  //排列五的往期数据 三定
            lotteryhistoryfive2:[],  //排列五的往期数据 二定
            startnumseven:'',
            endnumseven:'',
            startnumfive:'',
            endnumfive:'',
            buttonval:0, //开奖历史的数据
            buttonval2:0, //开奖历史的数据
            positionfront:[],//减式复选框的数据
            positionafter:[],//减式复选框的数据
            checkedval:[],//复选框的数据
            radioval:'',//单选框的数据
            radioval2:'',//单选框的数据
            radioval3:'',//单选框的数据
            radioval4:'',//单选框的数据
            radioval1_1:'',//批量选码单选框的数据
            radioval1_2:'',//批量选码单选框的数据
            radioval1_3:'',//批量选码单选框的数据
            radioval2_1:'',//批量选码单选框的数据
            radioval2_2:'',//批量选码单选框的数据
            radioval2_3:'',//批量选码单选框的数据
            radioval3_1:'',//批量选码单选框的数据
            radioval3_2:'',//批量选码单选框的数据
            radioval3_3:'',//批量选码单选框的数据
            radioval4_1:'',//批量选码单选框的数据
            radioval4_2:'',//批量选码单选框的数据
            radioval4_3:'',//批量选码单选框的数据
            disabled1:false,
            disabled2:false,
            disabled3:false,
            disabled4:false,
            tableval:'',//标题页
            selectnumber:'', //展示已选的数字
            // selectnumberarry:[],
            numberval:[false,false,false,false,false,false,false,false,false,false],
            numbervalcomparison:[false,false,false,false,false,false,false,false,false,false],
            //和值需要的数组 四定四现 三定三现 二定二现
            val37:[false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false],
            val28: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false],
            val19: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
            //合值+跨度需要的数组
            positiveval19: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
            positiveval10: [false,false,false,false,false,false,false,
                        false,false,false],
            //合值-跨度需要的数组
            nagetiveval18: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false],
            nagetiveval19: [false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false],
            nagetiveval9: [false,false,false,false,false,false,false,false,false],
            //位积的和值需要的数组
            val91: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false
                    
                    ],
            val82: [
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false
                    ],
            val64: [
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false
                    ],
            //余数和需要的数组
            val9: [false,false,false,false,false,false,false,false,false],
            val7: [false,false,false,false,false,false,false],
            val5: [false,false,false,false,false],
            //AC值需要的数组
            val6: [false,false,false,false,false,false],
            val3: [false,false,false],
            //配号码需要的数组
            group1:[false,false,false,false,false,false,false,false,false,false],
            group2:[false,false,false,false,false,false,false,false,false,false],
            group3:[false,false,false,false,false,false,false,false,false,false],
            group4:[false,false,false,false,false,false,false,false,false,false],
            //单双、大小、质合 大中小 012路需要的数组
            //单双、大小、质合的 通用组合 4 3 2 定
            commoncombination1_4:['aaaa','aaab','aaba','aabb','abaa','abab','abba','abbb',
            'bbbb','bbba','bbab','bbaa','babb','baba','baab','baaa'],//16种
            commoncombination1_3:['aaaX','aaXa','aXaa','Xaaa','abbX','abXb','aXbb','Xabb','abaX','abXa','aXba','Xaba'
            ,'bbbX','bbXb','bXbb','Xbbb','baaX','baXa','bXaa','Xbaa','babX','baXb','bXab','Xbab'],//24种
            commoncombination1_2:['aaXX','aXaX','aXXa','XaaX','XaXa','XXaa','abXX','aXbX','aXXb','XabX','XaXb','XXab'
            ,'bbXX','bXbX','bXXb','XbbX','XbXb','XXbb','baXX','bXaX','bXXa','XbaX','XbXa','XXba'],//24种
            single1_4:[
                '单单单单','单单单双','单单双单','单单双双','单双单单','单双单双','单双双单','单双双双',
                '双双双双','双双双单','双双单双','双双单单','双单双双','双单双单','双单单双','双单单单'
            ],
            single1_3:[
                '单单单X','单单X单','单X单单','X单单单','单双双X','单双X双','单X双双','X单双双','单双单X','单双X单','单X双单','X单双单',
                '双双双X','双双X双','双X双双','X双双双','双单单X','双单X单','双X单单','X双单单','双单双X','双单X双','双X单双','X双单双'
            ],
            single1_2:[
                '单单XX','单X单X','单XX单','X单单X','X单X单','XX单单','单双XX','单X双X','单XX双','X单双X','X单X双','XX单双'
                ,'双双XX','双X双X','双XX双','X双双X','X双X双','XX双双','双单XX','双X单X','双XX单','X双单X','X双X单','XX双单'
            ],
            big1_4:[
                '大大大大','大大大小','大大小大','大大小小','大小大大','大小大小','大小小大','大小小小',
                '小小小小','小小小大','小小大小','小小大大','小大小小','小大小大','小大大小','小大大大'
            ],
            big1_3:[
                '大大大X','大大X大','大X大大','X大大大','大小小X','大小X小','大X小小','X大小小','大小大X','大小X大','大X小大','X大小大',
                '小小小X','小小X小','小X小小','X小小小','小大大X','小大X大','小X大大','X小大大','小大小X','小大X小','小X大小','X小大小'
            ],
            big1_2:[
                '大大XX','大X大X','大XX大','X大大X','X大X大','XX大大','大小XX','大X小X','大XX小','X大小X','X大X小','XX大小'
                ,'小小XX','小X小X','小XX小','X小小X','X小X小','XX小小','小大XX','小X大X','小XX大','X小大X','X小X大','XX小大'
            ],
            prime1_4:[
                '质质质质','质质质合','质质合质','质质合合','质合质质','质合质合','质合合质','质合合合',
                '合合合合','合合合质','合合质合','合合质质','合质合合','合质合质','合质质合','合质质质'
            ],
            prime1_3:[
                '质质质X','质质X质','质X质质','X质质质','质合合X','质合X合','质X合合','X质合合','质合质X','质合X质','质X合质','X质合质',
                '合合合X','合合X合','合X合合','X合合合','合质质X','合质X质','合X质质','X合质质','合质合X','合质X合','合X质合','X合质合'
            ],
            prime1_2:[
                '质质XX','质X质X','质XX质','X质质X','X质X质','XX质质','质合XX','质X合X','质XX合','X质合X','X质X合','XX质合'
                ,'合合XX','合X合X','合XX合','X合合X','X合X合','XX合合','合质XX','合X质X','合XX质','X合质X','X合X质','XX合质'
            ],

            //大中小 012路 的通用组合 4 3 2 定
            commoncombination2_4:[
                'aaaa','aaab','aaac','aaba','aabb','aabc','aaca','aacb','aacc','abaa','abab','abac','abba','abbb','abbc','abca','abcb','abcc','acaa','acab','acac','acba','acbb','acbc','acca','accb','accc',
                'baaa','baab','baac','baba','babb','babc','baca','bacb','bacc','bbaa','bbab','bbac','bbba','bbbb','bbbc','bbca','bbcb','bbcc','bcaa','bcab','bcac','bcba','bcbb','bcbc','bcca','bccb','bccc',
                'caaa','caab','caac','caba','cabb','cabc','caca','cacb','cacc','cbaa','cbab','cbac','cbba','cbbb','cbbc','cbca','cbcb','cbcc','ccaa','ccab','ccac','ccba','ccbb','ccbc','ccca','cccb','cccc'
            ],
            commoncombination2_3:[
                'Xaaa','Xaab','Xaac','Xaba','Xabb','Xabc','Xaca','Xacb','Xacc','Xbaa','Xbab','Xbac','Xbba','Xbbb','Xbbc','Xbca','Xbcb','Xbcc','Xcaa','Xcab','Xcac','Xcba','Xcbb','Xcbc','Xcca','Xccb','Xccc',
                'aXaa','aXab','aXac','aXba','aXbb','aXbc','aXca','aXcb','aXcc','bXaa','bXab','bXac','bXba','bXbb','bXbc','bXca','bXcb','bXcc','cXaa','cXab','cXac','cXba','cXbb','cXbc','cXca','cXcb','cXcc',
                'aaXa','aaXb','aaXc','abXa','abXb','abXc','acXa','acXb','acXc','baXa','baXb','baXc','bbXa','bbXb','bbXc','bcXa','bcXb','bcXc','caXa','caXb','caXc','cbXa','cbXb','cbXc','ccXa','ccXb','ccXc',
                'aaaX','aabX','aacX','abaX','abbX','abcX','acaX','acbX','accX','baaX','babX','bacX','bbaX','bbbX','bbcX','bcaX','bcbX','bccX','caaX','cabX','cacX','cbaX','cbbX','cbcX','ccaX','ccbX','cccX'
            ],
            commoncombination2_2:[
                'XXaa','XXab','XXac','XXba','XXbb','XXbc','XXca','XXcb','XXcc',
                'XaXa','XaXb','XaXc','XbXa','XbXb','XbXc','XcXa','XcXb','XcXc',
                'XaaX','XabX','XacX','XbaX','XbbX','XbcX','XcaX','XcbX','XccX',
                'aXXa','aXXb','aXXc','bXXa','bXXb','bXXc','cXXa','cXXb','cXXc',
                'aXaX','aXbX','aXcX','bXaX','bXbX','bXcX','cXaX','cXbX','cXcX',
                'aaXX','abXX','acXX','baXX','bbXX','bcXX','caXX','cbXX','ccXX',
            ],
            // 大中小 abc
            big2_4:[
                '大大大大','大大大中','大大大小','大大中大','大大中中','大大中小','大大小大','大大小中','大大小小','大中大大','大中大中','大中大小','大中中大','大中中中','大中中小','大中小大','大中小中','大中小小','大小大大','大小大中','大小大小','大小中大','大小中中','大小中小','大小小大','大小小中','大小小小',
                '中大大大','中大大中','中大大小','中大中大','中大中中','中大中小','中大小大','中大小中','中大小小','中中大大','中中大中','中中大小','中中中大','中中中中','中中中小','中中小大','中中小中','中中小小','中小大大','中小大中','中小大小','中小中大','中小中中','中小中小','中小小大','中小小中','中小小小',
                '小大大大','小大大中','小大大小','小大中大','小大中中','小大中小','小大小大','小大小中','小大小小','小中大大','小中大中','小中大小','小中中大','小中中中','小中中小','小中小大','小中小中','小中小小','小小大大','小小大中','小小大小','小小中大','小小中中','小小中小','小小小大','小小小中','小小小小'
            ],
            big2_3:[
                'X大大大','X大大中','X大大小','X大中大','X大中中','X大中小','X大小大','X大小中','X大小小','X中大大','X中大中','X中大小','X中中大','X中中中','X中中小','X中小大','X中小中','X中小小','X小大大','X小大中','X小大小','X小中大','X小中中','X小中小','X小小大','X小小中','X小小小',
                '大X大大','大X大中','大X大小','大X中大','大X中中','大X中小','大X小大','大X小中','大X小小','中X大大','中X大中','中X大小','中X中大','中X中中','中X中小','中X小大','中X小中','中X小小','小X大大','小X大中','小X大小','小X中大','小X中中','小X中小','小X小大','小X小中','小X小小',
                '大大X大','大大X中','大大X小','大中X大','大中X中','大中X小','大小X大','大小X中','大小X小','中大X大','中大X中','中大X小','中中X大','中中X中','中中X小','中小X大','中小X中','中小X小','小大X大','小大X中','小大X小','小中X大','小中X中','小中X小','小小X大','小小X中','小小X小',
                '大大大X','大大中X','大大小X','大中大X','大中中X','大中小X','大小大X','大小中X','大小小X','中大大X','中大中X','中大小X','中中大X','中中中X','中中小X','中小大X','中小中X','中小小X','小大大X','小大中X','小大小X','小中大X','小中中X','小中小X','小小大X','小小中X','小小小X'
            
            ],
            big2_2:[
                'XX大大','XX大中','XX大小','XX中大','XX中中','XX中小','XX小大','XX小中','XX小小',
                'X大X大','X大X中','X大X小','X中X大','X中X中','X中X小','X小X大','X小X中','X小X小',
                'X大大X','X大中X','X大小X','X中大X','X中中X','X中小X','X小大X','X小中X','X小小X',
                '大XX大','大XX中','大XX小','中XX大','中XX中','中XX小','小XX大','小XX中','小XX小',
                '大X大X','大X中X','大X小X','中X大X','中X中X','中X小X','小X大X','小X中X','小X小X',
                '大大XX','大中XX','大小XX','中大XX','中中XX','中小XX','小大XX','小中XX','小小XX',
            
            ],
            way2_4:[
                '2222','2221','2220','2212','2211','2210','2202','2201','2200','2122','2121','2120','2112','2111','2110','2102','2101','2100','2022','2021','2020','2012','2011','2010','2002','2001','2000',
                '1222','1221','1220','1212','1211','1210','1202','1201','1200','1122','1121','1120','1112','1111','1110','1102','1101','1100','1022','1021','1020','1012','1011','1010','1002','1001','1000',
                '0222','0221','0220','0212','0211','0210','0202','0201','0200','0122','0121','0120','0112','0111','0110','0102','0101','0100','0022','0021','0020','0012','0011','0010','0002','0001','0000'
            
            ],
            way2_3:[
                'X222','X221','X220','X212','X211','X210','X202','X201','X200','X122','X121','X120','X112','X111','X110','X102','X101','X100','X022','X021','X020','X012','X011','X010','X002','X001','X000',
                '2X22','2X21','2X20','2X12','2X11','2X10','2X02','2X01','2X00','1X22','1X21','1X20','1X12','1X11','1X10','1X02','1X01','1X00','0X22','0X21','0X20','0X12','0X11','0X10','0X02','0X01','0X00',
                '22X2','22X1','22X0','21X2','21X1','21X0','20X2','20X1','20X0','12X2','12X1','12X0','11X2','11X1','11X0','10X2','10X1','10X0','02X2','02X1','02X0','01X2','01X1','01X0','00X2','00X1','00X0',
                '222X','221X','220X','212X','211X','210X','202X','201X','200X','122X','121X','120X','112X','111X','110X','102X','101X','100X','022X','021X','020X','012X','011X','010X','002X','001X','000X'
            
            ],
            way2_2:[
                'XX22','XX21','XX20','XX12','XX11','XX10','XX02','XX01','XX00',
                'X2X2','X2X1','X2X0','X1X2','X1X1','X1X0','X0X2','X0X1','X0X0',
                'X22X','X21X','X20X','X12X','X11X','X10X','X02X','X01X','X00X',
                '2XX2','2XX1','2XX0','1XX2','1XX1','1XX0','0XX2','0XX1','0XX0',
                '2X2X','2X1X','2X0X','1X2X','1X1X','1X0X','0X2X','0X1X','0X0X',
                '22XX','21XX','20XX','12XX','11XX','10XX','02XX','01XX','00XX',
            
            ],
            //单双 大小 质合 的比例数组 4 3 2 定现
            ratio1_4:['4:0','0:4','3:1','1:3','2:2'],
            ratio1_3:['3:0','0:3','2:1','1:2'],
            ratio1_2:['2:0','0:2','1:1'],
            // 大中小 012路的比例数组 4 3 2 定现
            ratio2_4:['0:0:4','0:1:3','0:2:2','0:3:1','0:4:0','1:0:3','1:1:2','1:2:1','1:3:0','2:0:2','2:1:1','2:2:0','3:0:1','3:1:0','4:0:0'],
            ratio2_3:['0:0:3','0:1:2','0:2:1','0:3:0','1:0:2','1:1:1','1:2:0','2:0:1','2:1:0','3:0:0'],
            ratio2_2:['0:0:2','0:1:1','0:2:0','1:0:1','1:1:0','2:0:0'],
            // 是否保留
            save:false,
           
            // 是否并集 true为并集 false为交集
            unionSet:true,
            active:'',
            selectTitle1:'',
            selectTitle2:'',
            selectShow:false,
            selectShow2:false,
            menuList:[],
            selectList:[],
            title:'',
            num:0,
            type:'',


            maxall:'',
            maxMsg:'',
            all:[], //可提供给各个条件
            selectjiaoorbing:false, //交集或并集
            arr:[],   //多个条件计算的多个结果
            lastall:[],    //   最后的结果集
 
        }
    },
    components: {
    vHeader
    },
    methods:{
    //减式
    changebuttonval2(item,n){
        
        if(item==='front'){
        this.positionfront=[]
            this.front[n]=!this.front[n]
            this.front.forEach((item,index)=>{
                if(item&&index===0)
                this.positionfront.push('A')
                if(item&&index===1)
                this.positionfront.push('B')
                if(item&&index===2)
                this.positionfront.push('C')
                if(item&&index===3)
                this.positionfront.push('D')

            })
        }
        if(item==='after'){
            this.positionafter=[]
            this.after[n]=!this.after[n]
            this.after.forEach((item,index)=>{
                if(item&&index===0)
                this.positionafter.push('A')
                if(item&&index===1)
                this.positionafter.push('B')
                if(item&&index===2)
                this.positionafter.push('C')
                if(item&&index===3)
                this.positionafter.push('D')

            })
        }
        // console.log(this.positionfront,this.positionafter)

        //每点一次就更新数据
        this.$forceUpdate()
        //修改msg的信息
        this.showcheckedval()
    },
    // 开奖历史 还未完成
    changebuttonval(n){
        this.selectnumber=''
       
        //每点一次就更新数据
        this.$forceUpdate()
        if(this.selectTitle2==='七星彩'){
            this.buttonval=n
            if(this.buttonval!==0){
                
                this.endnumseven=this.newestseven
                this.startnumseven=this.newestseven-n+1
                this.selectnumber+='本地号码：第'+this.startnumseven+'~'+this.endnumseven+'期'
            }
            
        }
        if(this.selectTitle2==='排列五'){
            this.buttonval2=n
            if(this.buttonval2!==0){
                
                this.endnumfive=this.newestfive
                this.startnumfive=this.newestfive-n+1
                this.selectnumber+='本地号码：第'+this.startnumfive+'~'+this.endnumfive+'期'
            }
        }
        
    },
    // 点击显示弹窗方法
    condSelect(titleName,btnName){
        this.selectTitle1 = titleName
        this.selectTitle2 = btnName
        this.selectShow = true
    //   console.log(this.selectTitle1,this.selectTitle2,this.selectShow)
        //取消 清除 还原已选的数据
        this.cancel()
        //保留或排除
        this.save=this.selectList.find((item)=>(item.name===titleName)).btn.find((item)=>(item.name===btnName)).isSave===1?true:false
    
            

    },
    // 在已选的条件弹窗中点击条件进行修改 (重要)
    condSelected(titleName,btnName){
        this.selectTitle1 = titleName
        this.selectTitle2 = btnName
        this.selectShow = true
    //   console.log(this.selectTitle1,this.selectTitle2,this.selectShow)
        //防止刷新就丢失数据
         // eslint-disable-next-line no-prototype-builtins
         if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val!==undefined){
            this.numberval=this.selectList.find((item)=>(item.name===titleName)).btn.find((item)=>(item.name===btnName)).val
            // console.log(this.numberval)
            // this.updatashownumber(this.numberval)
         }
         // eslint-disable-next-line no-prototype-builtins
         if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).checkboxval!==undefined){
            this.checkedval=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).checkboxval
         }
         //减式需要的数据 positionfront
         if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positionfront!==undefined){
                // console.log('更新选择的复选框的数据',this.selectTitle1,this.selectTitle2)
                 //更新选择的复选框的数据
                 this.positionfront=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positionfront
                 this.positionfront.forEach((item)=>{
                    if(item==='A')
                    this.front[0]=true
                    if(item==='B')
                    this.front[1]=true
                    if(item==='C')
                    this.front[2]=true
                    if(item==='D')
                    this.front[3]=true
                    })
                   
            }
            //减式需要的数据 positionafter
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positionafter!==undefined){
                // console.log('更新选择的复选框的数据',this.selectTitle1,this.selectTitle2)
                 //更新选择的复选框的数据
                 this.positionafter=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positionafter
                 this.positionafter.forEach((item)=>{
                    if(item==='A')
                    this.after[0]=true
                    if(item==='B')
                    this.after[1]=true
                    if(item==='C')
                    this.after[2]=true
                    if(item==='D')
                    this.after[3]=true
                    })
            }
          // 合值需要的数组
          if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val37!==undefined){
                this.val37=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val37
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val28!==undefined){
                this.val28=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val28
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val19!==undefined){
                this.val19=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val19
            }
            //合值+跨度需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positiveval19!==undefined){
                 this.positiveval19=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positiveval19
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positiveval10!==undefined){
                 this.positiveval10=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positiveval10
            }
            //合值-跨度需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval18!==undefined){
                 this.nagetiveval18=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval18
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval19!==undefined){
                 this.nagetiveval19=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval19
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval9!==undefined){
                 this.nagetiveval9=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval9
            }
            //位积反位积需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val91!==undefined){
                 this.val91=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val91
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val82!==undefined){
                 this.val82=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val82
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val64!==undefined){
                 this.val64=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val64
            }
            //余数和需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val9!==undefined){
                 this.val9=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val9
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val7!==undefined){
                 this.val7=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val7
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val5!==undefined){
                 this.val5=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val5
            }
            //AC值需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val6!==undefined){
                 this.val6=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val6
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val3!==undefined){
                 this.val3=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val3
            }
            //配号码需要的数组 group1 自由选码
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group1!==undefined){
                 this.group1=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group1
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group2!==undefined){
                 this.group2=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group2
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group3!==undefined){
                 this.group3=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group3
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group4!==undefined){
                 this.group4=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group4
            }
            //开奖历史需要的数据
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).buttonval!==undefined){
                 this.buttonval=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).buttonval
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).buttonval2!==undefined){
                 this.buttonval2=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).buttonval2
            }
            //单双 大小 质合 按位快选需要的数组 定位置
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).batch!==undefined){
                 this.checkedval=this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).batch
                 this.checkedval.forEach((item)=>{
                    if(item.name==='qian'){
                        if(this.selectTitle1==='大中小'||this.selectTitle1==='012路'){
                            if(item.code===2)
                            this.radioval="{name:'qian',code:2}"
                            if(item.code===1)
                            this.radioval="{name:'qian',code:1}"
                            if(item.code===0)
                            this.radioval="{name:'qian',code:0}"
                        }else if(this.selectTitle1==='定位置'){
                            if(item.dan===1)
                            this.radioval1_1='单'
                            if(item.dan===0)
                            this.radioval1_1='双'
                            if(item.da===1)
                            this.radioval1_2='大'
                            if(item.da===0)
                            this.radioval1_2='小'
                            if(item.lu===0)
                            this.radioval1_3='0路'
                            if(item.lu===1)
                            this.radioval1_3='1路'
                            if(item.lu===2)
                            this.radioval1_3='2路'
                        }else{ //单双 大小 质合
                            if(item.code===1)
                            this.radioval="{name:'qian',code:1}"
                            if(item.code===0)
                            this.radioval="{name:'qian',code:0}"
                        }
                        

                    }
                    if(item.name==='bai'){
                        if(this.selectTitle1==='大中小'||this.selectTitle1==='012路'){
                            if(item.code===2)
                            this.radioval2="{name:'bai',code:2}"
                            if(item.code===1)
                            this.radioval2="{name:'bai',code:1}"
                            if(item.code===0)
                            this.radioval2="{name:'bai',code:0}"
                        }else if(this.selectTitle1==='定位置'){
                            if(item.dan===1)
                            this.radioval2_1='单'
                            if(item.dan===0)
                            this.radioval2_1='双'
                            if(item.da===1)
                            this.radioval2_2='大'
                            if(item.da===0)
                            this.radioval2_2='小'
                            if(item.lu===0)
                            this.radioval2_3='0路'
                            if(item.lu===1)
                            this.radioval2_3='1路'
                            if(item.lu===2)
                            this.radioval2_3='2路'
                        }else{
                            if(item.code===1)
                            this.radioval2="{name:'bai',code:1}"
                            if(item.code===0)
                            this.radioval2="{name:'bai',code:0}"
                        }
                    }
                    if(item.name==='shi'){
                        if(this.selectTitle1==='大中小'||this.selectTitle1==='012路'){
                            if(item.code===2)
                            this.radioval3="{name:'shi',code:2}"
                            if(item.code===1)
                            this.radioval3="{name:'shi',code:1}"
                            if(item.code===0)
                            this.radioval3="{name:'shi',code:0}"
                        }else if(this.selectTitle1==='定位置'){
                            if(item.dan===1)
                            this.radioval3_1='单'
                            if(item.dan===0)
                            this.radioval3_1='双'
                            if(item.da===1)
                            this.radioval3_2='大'
                            if(item.da===0)
                            this.radioval3_2='小'
                            if(item.lu===0)
                            this.radioval3_3='0路'
                            if(item.lu===1)
                            this.radioval3_3='1路'
                            if(item.lu===2)
                            this.radioval3_3='2路'
                        }else{
                            if(item.code===1)
                            this.radioval3="{name:'shi',code:1}"
                            if(item.code===0)
                            this.radioval3="{name:'shi',code:0}"
                        }
                    }
                    if(item.name==='ge'){
                        if(this.selectTitle1==='大中小'||this.selectTitle1==='012路'){
                            if(item.code===2)
                            this.radioval4="{name:'ge',code:2}"
                            if(item.code===1)
                            this.radioval4="{name:'ge',code:1}"
                            if(item.code===0)
                            this.radioval4="{name:'ge',code:0}"
                        }else if(this.selectTitle1==='定位置'){
                            if(item.dan===1)
                            this.radioval4_1='单'
                            if(item.dan===0)
                            this.radioval4_1='双'
                            if(item.da===1)
                            this.radioval4_2='大'
                            if(item.da===0)
                            this.radioval4_2='小'
                            if(item.lu===0)
                            this.radioval4_3='0路'
                            if(item.lu===1)
                            this.radioval4_3='1路'
                            if(item.lu===2)
                            this.radioval4_3='2路'
                        }else{
                            if(item.code===1)
                            this.radioval4="{name:'ge',code:1}"
                            if(item.code===0)
                            this.radioval4="{name:'ge',code:0}"
                        }
                    }
                    
                 })
            }
            


        //取消 清除 还原已选的数据
        // this.cancel()
        //保留或排除
        this.save=this.selectList.find((item)=>(item.name===titleName)).btn.find((item)=>(item.name===btnName)).isSave===1?true:false
        this.selectnumber=this.selectList.find((item)=>(item.name===titleName)).btn.find((item)=>(item.name===btnName)).msg
    
    
            

    },
    //已选条件的弹窗
    condSelect2(){
        this.selectShow2 = true
        this.unionSet=this.selectList[0].isOverlap===1?true:false
        // console.log(this.unionSet)
        // console.log(this.selectList[0].isOverlap)

    },
    
    //左侧菜单和右侧内容联动，还差右侧拖动左边菜单跟着动的功能
    clickScroll(name){
        // console.log(name)
        document.getElementById(name).scrollIntoView();
        
    },
    
      
    //选择数字
    change(val,index){
        // console.log('test')
        // console.log(index)
        // console.log(val)
        // console.log(this.selectTitle1)
        // console.log(this.selectTitle2)
        //修改选择的数字（已选或未选）
        val[index]=!val[index]
        //每点一次就更新数据
        this.$forceUpdate()
        //数字和字符串都要更新
        if(
            this.selectTitle1==='减式'&&this.selectTitle2==='合值'||
            this.selectTitle1==='有数'&&this.selectTitle2==='3个位置'||
            this.selectTitle1==='有数'&&this.selectTitle2==='2个位置'||
            this.selectTitle1==='三数乘积'&&this.selectTitle2==='合值'||
            this.selectTitle1==='两数乘积'&&this.selectTitle2==='合值'||
            this.selectTitle1==='三数合'&&this.selectTitle2==='定位置'||
            this.selectTitle1==='两数合'&&this.selectTitle2==='定位置'
            ){
            this.showcheckedval()
        }else if(this.selectTitle1==='配号码'||this.selectTitle1==='定位置'){
            // console.log(this.selectTitle1)
            this.groupval()
        }else{
            //更新已选的数据 只有数字
            this.updatashownumber(val)
        }
        
    },
    change2(index){
        if(this.checkedval.length>=10){
            this.$toast('最多只支持10位数字');
        }
        else{
            this.checkedval.push(index)
            this.selectnumber+=index   
        }
    },
     //更新已选的数据 返回字符串 只针对选的数字
     updatashownumber(val){
        this.selectnumber=''
        // let numbers
        val.forEach((item,index)=>{
            if(this.selectTitle1==='合值+跨度'&&(this.type===5||this.type===6)){
                if(item){
                this.selectnumber+=index*2+','
                }
            }else if(this.selectTitle1==='合值-跨度'&&(this.type===1||this.type===2||this.type===3||this.type===4)){
                if(item){
                this.selectnumber+=index-9+','
                }
            }else if(this.selectTitle1==='合值-跨度'&&(this.type===5||this.type===6)){
                if(item){
                this.selectnumber+=-8+index*2+','
                }
            }else if(this.selectTitle1==='AC值'){
                if(item)
                this.selectnumber+=index+1+','
            }else{
                if(item){
                this.selectnumber+=index+','
                // numbers+=index+','
                }
            }
            
            
        })
        //去掉最后一个，
        this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
        // return numbers.substr(0,numbers.length-1)
     },
    //取消 清除 只要弹窗关闭就复原所有已选的条件 还原所有本地数据 (重要)
    cancel(){
        this.front=[false,false,false,false]
        this.after=[false,false,false,false]
        this.endnumseven=''
        this.startnumseven=''
        this.endnumfive=''
        this.startnumfive=''
        this.buttonval=0
        this.buttonval2=0
        this.positionfront=[],//减式复选框的数据
        this.positionafter=[],
        this.checkedval=[]
        this.numberval=[false,false,false,false,false,false,false,false,false,false]
        //和值需要的数组
        this.val37=[false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false,false,false,false,false,false,
                          false,false]
        this.val28=[false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false]
        this.val19=[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]
        this.positiveval19=[false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false]
        this.positiveval10=[false,false,false,false,false,false,false,
                        false,false,false]
        this.nagetiveval18=[false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false]
        this.nagetiveval19=[false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,
                        false,false,false,false,false]
        this.nagetiveval9=[false,false,false,false,false,false,false,false,false]
        //位积的和值需要的数组
        this.val91=[
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false
                    
                    ]
        this.val82=[
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false,false,false,false,false,false,false,false,false,
                    false,false
                    ]
        this.val64=[
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false,false,false,false,false,false,false,
                        false,false,false,false
                    ]
        //余数和需要的数组
        this.val9=[false,false,false,false,false,false,false,false,false]
        this.val7=[false,false,false,false,false,false,false]
        this.val5=[false,false,false,false,false]
        //AC值需要的数组
        this.val6=[false,false,false,false,false,false]
        this.val3=[false,false,false]
        //配号码需要的数组
        this.group1=[false,false,false,false,false,false,false,false,false,false]
        this.group2=[false,false,false,false,false,false,false,false,false,false]
        this.group3=[false,false,false,false,false,false,false,false,false,false]
        this.group4=[false,false,false,false,false,false,false,false,false,false]
        //单选按钮需要的属性
        this.radioval=''
        this.radioval2=''
        this.radioval3=''
        this.radioval4=''
        this.radioval1_1='',//批量选码单选框的数据
        this.radioval1_2='',//批量选码单选框的数据
        this.radioval1_3='',//批量选码单选框的数据
        this.radioval2_1='',//批量选码单选框的数据
        this.radioval2_2='',//批量选码单选框的数据
        this.radioval2_3='',//批量选码单选框的数据
        this.radioval3_1='',//批量选码单选框的数据
        this.radioval3_2='',//批量选码单选框的数据
        this.radioval3_3='',//批量选码单选框的数据
        this.radioval4_1='',//批量选码单选框的数据
        this.radioval4_2='',//批量选码单选框的数据
        this.radioval4_3='',//批量选码单选框的数据
        this.disabled1=false
        this.disabled2=false
        this.disabled3=false
        this.disabled4=false
        //标签页需要的属性
        this.tableval=''
        
        //更新已选的数据
        this.updatashownumber(this.numberval)
    },
    //获取数组 只针对数字
    getArray(arr){
        let list=[]
       arr.forEach((item,index)=>{
            if(item)
            list.push(index)
       })
       return list
    },
    //获取数组 只针AC值
    getArray1(arr){
        let list=[]
       arr.forEach((item,index)=>{
            if(item)
            list.push(index+1)
        
       })
       return list
    },
    //获取数组 只针对合值+跨度的二定二现的情况 0 2 4 6 8 10 12 14 16 18
    getArray2(arr){
        let list=[]
       arr.forEach((item,index)=>{
        if(item)
        list.push(index*2)
       })
       return list
    },
    //获取数组 只针对合值+跨度 四定 四现 三定 三现
    getArray3(arr){
        let list=[]
       arr.forEach((item,index)=>{
        if(item)
        list.push(index-9)
       })
       return list
    },
    //获取数组 只针对合值+跨度的二定二现的情况
    getArray4(arr){
        let list=[]
       arr.forEach((item,index)=>{
        if(item)
        list.push(-8+index*2)
       })
       return list
    },
   
    //确认后更新vuex里对应的数据 需要更新选择的数据 （重要） 昨天到这里单双的确认还没弄好
    confirmed(){
        // console.log(this.selectTitle1)
        // console.log(this.selectTitle2)
        // console.log(this.numberval)
        // console.log(this.checkedval)
        // console.log(this.selectnumber)
        // console.log(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val!==undefined)
        // console.log(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).checkboxval!==undefined)

        //确认前的检查：减式、有数(三个位置、两个位置)、两数合（定位置）、三数合（定位置）是否都填写完整了
            if(this.selectTitle1==='减式'&&this.selectTitle2==='合值'){
                    if(this.positionfront.length===0||this.positionafter.length===0||this.numberval.toString()===this.numbervalcomparison.toString()){
                        Toast({
                            message: '请选择完整的条件后，再点击确认',
                            position: 'middle',
                        });
                        return false
                    }
            }
            if(this.selectTitle1==='有数'&&(this.selectTitle2==='3个位置'||this.selectTitle2==='2个位置')){
                    if(this.checkedval.length===0||this.numberval.toString()===this.numbervalcomparison.toString()){
                        Toast({
                            message: '请选择完整的条件后，再点击确认',
                            position: 'middle',
                        });
                        return false
                    }
            }
            if(this.selectTitle1==='两数合'&&this.selectTitle2==='定位置'){
                    if(this.checkedval.length===0||this.numberval.toString()===this.numbervalcomparison.toString()){
                        Toast({
                            message: '请选择完整的条件后，再点击确认',
                            position: 'middle',
                        });
                        return false
                    }
            }
            if(this.selectTitle1==='三数合'&&this.selectTitle2==='定位置'){
                    if(this.checkedval.length===0||this.numberval.toString()===this.numbervalcomparison.toString()){
                        Toast({
                            message: '请选择完整的条件后，再点击确认',
                            position: 'middle',
                        });
                        return false
                    }
            }
             // 更新该条件被选用
            this.selectList.find((item)=>(item.name===this.selectTitle1)).isTrue=1
            // 更新该按钮被选用
            this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).isTrue=1
            
            // 更新是否保留
            this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).isSave=this.save?1:0
            //更新已选信息
            this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).msg=this.selectnumber

            // 这里需要根据不同情况进行修改数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val!==undefined){
                
                // console.log('//更新选择的数字',this.selectTitle1,this.selectTitle2)
                 //更新选择的数字
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val=JSON.parse(JSON.stringify(this.numberval))
                   
            }
            // 合值需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val37!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val37=JSON.parse(JSON.stringify(this.val37))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val28!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val28=JSON.parse(JSON.stringify(this.val28))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val19!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val19=JSON.parse(JSON.stringify(this.val19))
            }
            //合值+跨度需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positiveval19!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positiveval19=JSON.parse(JSON.stringify(this.positiveval19))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positiveval10!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positiveval10=JSON.parse(JSON.stringify(this.positiveval10))
            }
            //合值-跨度需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval18!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval18=JSON.parse(JSON.stringify(this.nagetiveval18))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval19!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval19=JSON.parse(JSON.stringify(this.nagetiveval19))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval9!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).nagetiveval9=JSON.parse(JSON.stringify(this.nagetiveval9))
            }
             //位积反位积需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val91!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val91=JSON.parse(JSON.stringify(this.val91))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val82!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val82=JSON.parse(JSON.stringify(this.val82))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val64!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val64=JSON.parse(JSON.stringify(this.val64))
            }
            //余数和需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val9!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val9=JSON.parse(JSON.stringify(this.val9))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val7!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val7=JSON.parse(JSON.stringify(this.val7))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val5!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val5=JSON.parse(JSON.stringify(this.val5))
            }
            //AC值需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val6!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val6=JSON.parse(JSON.stringify(this.val6))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val3!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).val3=JSON.parse(JSON.stringify(this.val3))
            }
            //配号码需要的数组 自由选码
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group1!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group1=JSON.parse(JSON.stringify(this.group1))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group2!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group2=JSON.parse(JSON.stringify(this.group2))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group3!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group3=JSON.parse(JSON.stringify(this.group3))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group4!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).group4=JSON.parse(JSON.stringify(this.group4))
            }
            //单双、大小、质合需要的数组（按位快选）
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).batch!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).batch=JSON.parse(JSON.stringify(this.checkedval))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).flag!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).flag=this.checkedval[0]
            }
            //定位置 乘号位置 批量选码需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).position!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).position=this.checkedval
            }

            //复选框需要的数组
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).checkboxval!==undefined){
                // console.log('更新选择的复选框的数据',this.selectTitle1,this.selectTitle2)
                 //更新选择的复选框的数据
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).checkboxval=JSON.parse(JSON.stringify(this.checkedval))
                   
            }
            //减式需要的数据 positionfront
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positionfront!==undefined){
                // console.log('更新选择的复选框的数据',this.selectTitle1,this.selectTitle2)
                 //更新选择的复选框的数据
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positionfront=JSON.parse(JSON.stringify(this.positionfront))
                   
            }
            //减式需要的数据 positionafter
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positionafter!==undefined){
                // console.log('更新选择的复选框的数据',this.selectTitle1,this.selectTitle2)
                 //更新选择的复选框的数据
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).positionafter=JSON.parse(JSON.stringify(this.positionafter))
                   
            }
            //开奖历史需要的数据
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).buttonval!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).buttonval=JSON.parse(JSON.stringify(this.buttonval))
            }
            if(this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).buttonval2!==undefined){
                 this.selectList.find((item)=>(item.name===this.selectTitle1)).btn.find((item)=>(item.name===this.selectTitle2)).buttonval2=JSON.parse(JSON.stringify(this.buttonval2))
            }
                            
            
               

             //每点一次就更新数据
            this.$forceUpdate()
            //关闭弹窗
            this.selectShow=false

            //更新全局大底
            this.getLastAll()

        // console.log(this.selectList)


        
    },

    //交集或并集
    selectUnion(n){
        
        if(n===1){ //并集
            this.unionSet=true
        }
        if(n===0){ //交集
            this.unionSet=false
        }
        //更新并集或交集
        this.selectList[0].isOverlap=n
         //更新全局大底
        
        this.getLastAll()
        
        
    },

    //计算全局的大底 这里需要完善 (重要) 今晚到这
    getLastAll(){
          //更新全局的的所有数据
            this.arr=[]
            this.selectList.forEach((item)=>{
                
                //最简单操作
                if(item.name==='排除码（死数）'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list= this.getArray(this.selectList.find((item)=>(item.name==='排除码（死数）')).btn.find((item)=>(item.name==='设置')).val)
                        this.arr.push(this.deadnumber(list))
                       } 
                    })
               
                    
                }
                if(item.name==='合值（合分）'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='合值（合分）')).btn.find((item)=>(item.name==='设置')).val)
                        let save=this.selectList.find((item)=>(item.name==='合值（合分）')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.hefens(list,save))
                       } 
                    })
                    
                }
                if(item.name==='头尾有数'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='头尾有数')).btn.find((item)=>(item.name==='设置')).val)
                        let save=this.selectList.find((item)=>(item.name==='头尾有数')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.headandtail(list,save))
                       } 
                    })
                    
                }
                if(item.name==='中肚有数'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='中肚有数')).btn.find((item)=>(item.name==='设置')).val)
                        let save=this.selectList.find((item)=>(item.name==='中肚有数')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.middleposition(list,save))
                       } 
                    })
                    
                }
                if(item.name==='有数'){
                    item.btn.forEach((n)=>{
                       if(n.name==='4个位置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='有数')).btn.find((item)=>(item.name==='4个位置')).val)
                        let save=this.selectList.find((item)=>(item.name==='有数')).btn.find((item)=>(item.name==='4个位置')).isSave===1?true:false
                        let placelist=[]
                        this.arr.push(this.existnumber(4,list,save,placelist))
                       } 
                       
                       if(n.name==='3个位置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='有数')).btn.find((item)=>(item.name==='3个位置')).val)
                        let placelist=this.selectList.find((item)=>(item.name==='有数')).btn.find((item)=>(item.name==='3个位置')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='有数')).btn.find((item)=>(item.name==='3个位置')).isSave===1?true:false
                        this.arr.push(this.existnumber(3,list,save,placelist))
                       } 
                       if(n.name==='2个位置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='有数')).btn.find((item)=>(item.name==='2个位置')).val)
                        let placelist=this.selectList.find((item)=>(item.name==='有数')).btn.find((item)=>(item.name==='2个位置')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='有数')).btn.find((item)=>(item.name==='2个位置')).isSave===1?true:false
                        this.arr.push(this.existnumber(2,list,save,placelist))
                       } 
                    })
                    
                }
                if(item.name==='两数合'){
                    item.btn.forEach((n)=>{
                       if(n.name==='不定位置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='两数合')).btn.find((item)=>(item.name==='不定位置')).val)
                        let save=this.selectList.find((item)=>(item.name==='两数合')).btn.find((item)=>(item.name==='不定位置')).isSave===1?true:false
                        this.arr.push(this.doubleresultant(0,list,save,[]))
                       } 
                       //定位置还没完成
                       if(n.name==='定位置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='两数合')).btn.find((item)=>(item.name==='定位置')).val)
                        let placelist=this.selectList.find((item)=>(item.name==='两数合')).btn.find((item)=>(item.name==='定位置')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='两数合')).btn.find((item)=>(item.name==='定位置')).isSave===1?true:false
                        this.arr.push(this.doubleresultant(1,list,save,placelist))
                       } 
                    })
                    
                }
                if(item.name==='三数合'){
                    item.btn.forEach((n)=>{
                       if(n.name==='不定位置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='三数合')).btn.find((item)=>(item.name==='不定位置')).val)
                        let save=this.selectList.find((item)=>(item.name==='三数合')).btn.find((item)=>(item.name==='不定位置')).isSave===1?true:false
                        this.arr.push(this.threeresultant(0,list,save,[]))
                       } 
                       //定位置还没完成
                       if(n.name==='定位置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='三数合')).btn.find((item)=>(item.name==='定位置')).val)
                        let placelist=this.selectList.find((item)=>(item.name==='三数合')).btn.find((item)=>(item.name==='定位置')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='三数合')).btn.find((item)=>(item.name==='定位置')).isSave===1?true:false
                        this.arr.push(this.threeresultant(1,list,save,placelist))
                       } 
                    })
                    
                }
                if(item.name==='必出码（铁率）'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='必出码（铁率）')).btn.find((item)=>(item.name==='设置')).val)
                        this.arr.push(this.appearnumber(list))
                       } 
                    })
                    
                }
                if(item.name==='极值'){
                    item.btn.forEach((n)=>{
                       if(n.name==='最大值'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='极值')).btn.find((item)=>(item.name==='最大值')).val)
                        let save=this.selectList.find((item)=>(item.name==='极值')).btn.find((item)=>(item.name==='最大值')).isSave===1?true:false
                        this.arr.push(this.extremum(1,list,save))
                       } 
                       if(n.name==='最小值'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='极值')).btn.find((item)=>(item.name==='最小值')).val)
                        let save=this.selectList.find((item)=>(item.name==='极值')).btn.find((item)=>(item.name==='最小值')).isSave===1?true:false
                        this.arr.push(this.extremum(0,list,save))
                       } 
                    })
                    
                }
                if(item.name==='跨度'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='跨度')).btn.find((item)=>(item.name==='设置')).val)
                        let save=this.selectList.find((item)=>(item.name==='跨度')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.spacing(list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='四数乘积'){
                    item.btn.forEach((n)=>{
                       if(n.name==='合值'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='四数乘积')).btn.find((item)=>(item.name==='合值')).val)
                        let save=this.selectList.find((item)=>(item.name==='四数乘积')).btn.find((item)=>(item.name==='合值')).isSave===1?true:false
                        this.arr.push(this.fourmultiply(list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='三数乘积'){
                    item.btn.forEach((n)=>{
                       if(n.name==='合值'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='三数乘积')).btn.find((item)=>(item.name==='合值')).val)
                        let placelist=this.selectList.find((item)=>(item.name==='三数乘积')).btn.find((item)=>(item.name==='合值')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='三数乘积')).btn.find((item)=>(item.name==='合值')).isSave===1?true:false
                        this.arr.push(this.threemultiply(list,save,placelist))
                       } 
                       
                    })
                    
                }
                if(item.name==='两数乘积'){
                    item.btn.forEach((n)=>{
                       if(n.name==='合值'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='两数乘积')).btn.find((item)=>(item.name==='合值')).val)
                        let placelist=this.selectList.find((item)=>(item.name==='两数乘积')).btn.find((item)=>(item.name==='合值')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='两数乘积')).btn.find((item)=>(item.name==='合值')).isSave===1?true:false
                        this.arr.push(this.doublemultiply(list,save,placelist))
                       } 
                       
                    })
                    
                }
                if(item.name==='减式'){
                    item.btn.forEach((n)=>{
                       if(n.name==='合值'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='减式')).btn.find((item)=>(item.name==='合值')).val)
                        let positionfront=this.selectList.find((item)=>(item.name==='减式')).btn.find((item)=>(item.name==='合值')).positionfront
                        let positionafter=this.selectList.find((item)=>(item.name==='减式')).btn.find((item)=>(item.name==='合值')).positionafter
                        let save=this.selectList.find((item)=>(item.name==='减式')).btn.find((item)=>(item.name==='合值')).isSave===1?true:false
                        this.arr.push(this.reducetype(list,positionfront,positionafter,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='位积'){
                    item.btn.forEach((n)=>{
                       if(n.name==='合值'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='位积')).btn.find((item)=>(item.name==='合值')).val)
                        let save=this.selectList.find((item)=>(item.name==='位积')).btn.find((item)=>(item.name==='合值')).isSave===1?true:false
                        this.arr.push(this.potentialproduct(0,list,save))
                       } 
                       //和值还没完成
                       if(n.name==='和值'&&n.isTrue===1){
                        let list=[]
                        if(this.type===1||this.type===2){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='位积')).btn.find((item)=>(item.name==='和值')).val91)
                        }
                        if(this.type===3||this.type===4){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='位积')).btn.find((item)=>(item.name==='和值')).val82)
                        }
                        if(this.type===5||this.type===6){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='位积')).btn.find((item)=>(item.name==='和值')).val64)
                        }
                        let save=this.selectList.find((item)=>(item.name==='位积')).btn.find((item)=>(item.name==='和值')).isSave===1?true:false
                        this.arr.push(this.potentialproduct(1,list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='反位积'){
                    item.btn.forEach((n)=>{
                       if(n.name==='合值'&&n.isTrue===1){
                        let list=this.getArray(this.selectList.find((item)=>(item.name==='反位积')).btn.find((item)=>(item.name==='合值')).val)
                        let save=this.selectList.find((item)=>(item.name==='反位积')).btn.find((item)=>(item.name==='合值')).isSave===1?true:false
                        this.arr.push(this.inversepotentialproduct(0,list,save))
                       } 
                       //和值还没完成
                       if(n.name==='和值'&&n.isTrue===1){
                        let list=[]
                        if(this.type===1||this.type===2){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='反位积')).btn.find((item)=>(item.name==='和值')).val91)
                        }
                        if(this.type===3||this.type===4){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='反位积')).btn.find((item)=>(item.name==='和值')).val82)
                        }
                        if(this.type===5||this.type===6){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='反位积')).btn.find((item)=>(item.name==='和值')).val64)
                        }
                        let save=this.selectList.find((item)=>(item.name==='反位积')).btn.find((item)=>(item.name==='和值')).isSave===1?true:false
                        this.arr.push(this.inversepotentialproduct(1,list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='余数和'){
                    item.btn.forEach((n)=>{
                       
                       if(n.name==='除以3'&&n.isTrue===1){
                        let list=[]
                        if(this.type===1||this.type===2){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='余数和')).btn.find((item)=>(item.name==='除以3')).val9)
                        }
                        if(this.type===3||this.type===4){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='余数和')).btn.find((item)=>(item.name==='除以3')).val7)
                        }
                        if(this.type===5||this.type===6){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='余数和')).btn.find((item)=>(item.name==='除以3')).val5)
                        }
                        let save=this.selectList.find((item)=>(item.name==='余数和')).btn.find((item)=>(item.name==='除以3')).isSave===1?true:false
                        this.arr.push(this.remaindersum(list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='配号码'){
                    item.btn.forEach((n)=>{
                       
                       if(n.name==='设置'&&n.isTrue===1){
                        let list1=[]
                        let list2=[]
                        let list3=[]
                        let list4=[]
                        if(this.type===1||this.type===2){//四定 四现
                            list1=this.getArray(this.selectList.find((item)=>(item.name==='配号码')).btn.find((item)=>(item.name==='设置')).group1)
                            list2=this.getArray(this.selectList.find((item)=>(item.name==='配号码')).btn.find((item)=>(item.name==='设置')).group2)
                            list3=this.getArray(this.selectList.find((item)=>(item.name==='配号码')).btn.find((item)=>(item.name==='设置')).group3)
                            list4=this.getArray(this.selectList.find((item)=>(item.name==='配号码')).btn.find((item)=>(item.name==='设置')).group4)
                        }
                        if(this.type===3||this.type===4){
                            list1=this.getArray(this.selectList.find((item)=>(item.name==='配号码')).btn.find((item)=>(item.name==='设置')).group1)
                            list2=this.getArray(this.selectList.find((item)=>(item.name==='配号码')).btn.find((item)=>(item.name==='设置')).group2)
                            list3=this.getArray(this.selectList.find((item)=>(item.name==='配号码')).btn.find((item)=>(item.name==='设置')).group3)
                        }
                        if(this.type===5||this.type===6){
                            list1=this.getArray(this.selectList.find((item)=>(item.name==='配号码')).btn.find((item)=>(item.name==='设置')).group1)
                            list2=this.getArray(this.selectList.find((item)=>(item.name==='配号码')).btn.find((item)=>(item.name==='设置')).group2)
                        }
                        this.arr.push(this.withnumber(list1,list2,list3,list4))
                       } 
                       
                    })
                    
                }
                if(item.name==='定位置'){   //type 0 1 2 自由选码 批量选码 乘号位置
                    item.btn.forEach((n)=>{
                       
                       if(n.name==='自由选码'&&n.isTrue===1){
                        let list=[]
                        let list1=[]
                        let list2=[]
                        let list3=[]
                        let list4=[]
                        list1=this.getArray(this.selectList.find((item)=>(item.name==='定位置')).btn.find((item)=>(item.name==='自由选码')).group1)
                        list2=this.getArray(this.selectList.find((item)=>(item.name==='定位置')).btn.find((item)=>(item.name==='自由选码')).group2)
                        list3=this.getArray(this.selectList.find((item)=>(item.name==='定位置')).btn.find((item)=>(item.name==='自由选码')).group3)
                        list4=this.getArray(this.selectList.find((item)=>(item.name==='定位置')).btn.find((item)=>(item.name==='自由选码')).group4)
                        list.push(list1,list2,list3,list4)
                        // console.log('这里是二维数组',list)
                        let save=this.selectList.find((item)=>(item.name==='定位置')).btn.find((item)=>(item.name==='自由选码')).isSave===1?true:false
                        
                        this.arr.push(this.staticplace(0,list,save))
                       } 
                       if(n.name==='批量选码'&&n.isTrue===1){
                        let list=this.selectList.find((item)=>(item.name==='定位置')).btn.find((item)=>(item.name==='批量选码')).batch
                        let save=this.selectList.find((item)=>(item.name==='定位置')).btn.find((item)=>(item.name==='批量选码')).isSave===1?true:false
                        //0 1 2 自由选码 批量选码 乘号位置
                        this.arr.push(this.staticplace(1,list,save))
                       } 
                       if(n.name==='乘号位置'&&n.isTrue===1){
                        
                        let list=this.selectList.find((item)=>(item.name==='定位置')).btn.find((item)=>(item.name==='乘号位置')).position
                        let save=this.selectList.find((item)=>(item.name==='定位置')).btn.find((item)=>(item.name==='乘号位置')).isSave===1?true:false
                        //0 1 2 自由选码 批量选码 乘号位置
                        this.arr.push(this.staticplace(2,list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='AC值'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list
                        if(this.type===1||this.type===2){
                             list=this.getArray1(this.selectList.find((item)=>(item.name==='AC值')).btn.find((item)=>(item.name==='设置')).val6)
                        }
                        if(this.type===3||this.type===4){
                             list=this.getArray1(this.selectList.find((item)=>(item.name==='AC值')).btn.find((item)=>(item.name==='设置')).val3)
                        }
                        let save=this.selectList.find((item)=>(item.name==='AC值')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.avalue(list,save))
                       } 
                    })
                    
                }
                if(item.name==='全转（全倒）'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.selectList.find((item)=>(item.name==='全转（全倒）')).btn.find((item)=>(item.name==='设置')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='全转（全倒）')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.spare(list,save))
                       } 
                    })
                    
                }

                if(item.name==='连号（兄弟号）'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.selectList.find((item)=>(item.name==='连号（兄弟号）')).btn.find((item)=>(item.name==='设置')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='连号（兄弟号）')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.consecutivenumbers(list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='凹凸升降'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.selectList.find((item)=>(item.name==='凹凸升降')).btn.find((item)=>(item.name==='设置')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='凹凸升降')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.bumplift(list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='对数'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.selectList.find((item)=>(item.name==='对数')).btn.find((item)=>(item.name==='设置')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='对数')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.logarithm(list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='重数'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.selectList.find((item)=>(item.name==='重数')).btn.find((item)=>(item.name==='设置')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='重数')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.multiplenumbers(list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='入数个数'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=this.selectList.find((item)=>(item.name==='入数个数')).btn.find((item)=>(item.name==='设置')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='入数个数')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.incomingnumber(list,save))
                       } 
                       
                    })
                    
                }

                if(item.name==='和值'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=[]
                        if(this.type===1||this.type===2){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='和值')).btn.find((item)=>(item.name==='设置')).val37)
                        }
                        if(this.type===3||this.type===4){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='和值')).btn.find((item)=>(item.name==='设置')).val28)
                        }
                        if(this.type===5||this.type===6){
                             list=this.getArray(this.selectList.find((item)=>(item.name==='和值')).btn.find((item)=>(item.name==='设置')).val19)
                        }
                        let save=this.selectList.find((item)=>(item.name==='和值')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.sumvalue(list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='合值+跨度'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=[]
                        if(this.type===1||this.type===2||this.type===3||this.type===4){
                            list=this.getArray(this.selectList.find((item)=>(item.name==='合值+跨度')).btn.find((item)=>(item.name==='设置')).positiveval19)
                        }
                        if(this.type===5||this.type===6){
                             list=this.getArray2(this.selectList.find((item)=>(item.name==='合值+跨度')).btn.find((item)=>(item.name==='设置')).positiveval10)
                        }
                        let save=this.selectList.find((item)=>(item.name==='合值+跨度')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.sumvaluepluspan(list,save))
                       } 
                       
                    })
                    
                }
                if(item.name==='合值-跨度'){
                    item.btn.forEach((n)=>{
                       if(n.name==='设置'&&n.isTrue===1){
                        let list=[]
                        if(this.type===1||this.type===2){
                            list=this.getArray3(this.selectList.find((item)=>(item.name==='合值-跨度')).btn.find((item)=>(item.name==='设置')).nagetiveval18)
                            // console.log(list)
                        }
                        if(this.type===3||this.type===4){
                            list=this.getArray3(this.selectList.find((item)=>(item.name==='合值-跨度')).btn.find((item)=>(item.name==='设置')).nagetiveval19)
                        }
                        if(this.type===5||this.type===6){
                             list=this.getArray4(this.selectList.find((item)=>(item.name==='合值-跨度')).btn.find((item)=>(item.name==='设置')).nagetiveval9)
                        }
                        let save=this.selectList.find((item)=>(item.name==='合值-跨度')).btn.find((item)=>(item.name==='设置')).isSave===1?true:false
                        this.arr.push(this.sumvalueminuspan(list,save))
                       } 
                       
                    })
                    
                }

                if(item.name==='单双（奇偶）'){
                    item.btn.forEach((n)=>{
                       if(n.name==='按位快选'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='单双（奇偶）')).btn.find((item)=>(item.name==='按位快选')).batch
                        let save=this.selectList.find((item)=>(item.name==='单双（奇偶）')).btn.find((item)=>(item.name==='按位快选')).isSave===1?true:false
                        this.arr.push(this.singledouble(1,list,save))
                       } 
                       if(n.name==='按位任选'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='单双（奇偶）')).btn.find((item)=>(item.name==='按位任选')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='单双（奇偶）')).btn.find((item)=>(item.name==='按位任选')).isSave===1?true:false
                        this.arr.push(this.singledouble(2,list,save))
                       } 
                       if(n.name==='出现比例'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='单双（奇偶）')).btn.find((item)=>(item.name==='出现比例')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='单双（奇偶）')).btn.find((item)=>(item.name==='出现比例')).isSave===1?true:false
                        this.arr.push(this.singledouble(3,list,save))
                       } 
                       if(n.name==='断组'&&n.isTrue===1){
                        
                            let flag=this.selectList.find((item)=>(item.name==='单双（奇偶）')).btn.find((item)=>(item.name==='断组')).flag
                            if(flag==="true"){
                                let save=this.selectList.find((item)=>(item.name==='单双（奇偶）')).btn.find((item)=>(item.name==='断组')).isSave===1?true:false
                                this.arr.push(this.singledouble(4,flag,save))
                            }
                           
                        
                        
                       } 
                       
                    })
                    
                }
                if(item.name==='大小'){
                    item.btn.forEach((n)=>{
                       if(n.name==='按位快选'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='大小')).btn.find((item)=>(item.name==='按位快选')).batch
                        let save=this.selectList.find((item)=>(item.name==='大小')).btn.find((item)=>(item.name==='按位快选')).isSave===1?true:false
                        this.arr.push(this.bigsmall(1,list,save))
                       } 
                       if(n.name==='按位任选'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='大小')).btn.find((item)=>(item.name==='按位任选')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='大小')).btn.find((item)=>(item.name==='按位任选')).isSave===1?true:false
                        this.arr.push(this.bigsmall(2,list,save))
                       } 
                       if(n.name==='出现比例'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='大小')).btn.find((item)=>(item.name==='出现比例')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='大小')).btn.find((item)=>(item.name==='出现比例')).isSave===1?true:false
                        this.arr.push(this.bigsmall(3,list,save))
                       } 
                       if(n.name==='断组'&&n.isTrue===1){
                        
                            let flag=this.selectList.find((item)=>(item.name==='大小')).btn.find((item)=>(item.name==='断组')).flag
                            if(flag==="true"){
                                let save=this.selectList.find((item)=>(item.name==='大小')).btn.find((item)=>(item.name==='断组')).isSave===1?true:false
                                this.arr.push(this.bigsmall(4,flag,save))
                            }
                           
                        
                        
                       } 
                       
                    })
                    
                }
                if(item.name==='质合'){
                    item.btn.forEach((n)=>{
                       if(n.name==='按位快选'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='质合')).btn.find((item)=>(item.name==='按位快选')).batch
                        let save=this.selectList.find((item)=>(item.name==='质合')).btn.find((item)=>(item.name==='按位快选')).isSave===1?true:false
                        this.arr.push(this.qualitysum(1,list,save))
                       } 
                       if(n.name==='按位任选'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='质合')).btn.find((item)=>(item.name==='按位任选')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='质合')).btn.find((item)=>(item.name==='按位任选')).isSave===1?true:false
                        this.arr.push(this.qualitysum(2,list,save))
                       } 
                       if(n.name==='出现比例'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='质合')).btn.find((item)=>(item.name==='出现比例')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='质合')).btn.find((item)=>(item.name==='出现比例')).isSave===1?true:false
                        this.arr.push(this.qualitysum(3,list,save))
                       } 
                       if(n.name==='断组'&&n.isTrue===1){
                        
                            let flag=this.selectList.find((item)=>(item.name==='质合')).btn.find((item)=>(item.name==='断组')).flag
                            if(flag==="true"){
                                let save=this.selectList.find((item)=>(item.name==='质合')).btn.find((item)=>(item.name==='断组')).isSave===1?true:false
                                this.arr.push(this.qualitysum(4,flag,save))
                            }
                           
                        
                        
                       } 
                       
                    })
                    
                }
                if(item.name==='大中小'){
                    item.btn.forEach((n)=>{
                       if(n.name==='按位快选'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='大中小')).btn.find((item)=>(item.name==='按位快选')).batch
                        let save=this.selectList.find((item)=>(item.name==='大中小')).btn.find((item)=>(item.name==='按位快选')).isSave===1?true:false
                        this.arr.push(this.bigmediumsmall(1,list,save))
                       } 
                       if(n.name==='按位任选'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='大中小')).btn.find((item)=>(item.name==='按位任选')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='大中小')).btn.find((item)=>(item.name==='按位任选')).isSave===1?true:false
                        this.arr.push(this.bigmediumsmall(2,list,save))
                       } 
                       if(n.name==='出现比例'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='大中小')).btn.find((item)=>(item.name==='出现比例')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='大中小')).btn.find((item)=>(item.name==='出现比例')).isSave===1?true:false
                        this.arr.push(this.bigmediumsmall(3,list,save))
                       } 
                       if(n.name==='断组'&&n.isTrue===1){
                        
                            let flag=this.selectList.find((item)=>(item.name==='大中小')).btn.find((item)=>(item.name==='断组')).flag
                            if(flag==="true"){
                                let save=this.selectList.find((item)=>(item.name==='大中小')).btn.find((item)=>(item.name==='断组')).isSave===1?true:false
                                this.arr.push(this.bigmediumsmall(4,flag,save))
                            }
                           
                        
                        
                       } 
                       
                    })
                    
                }
                if(item.name==='012路'){
                    item.btn.forEach((n)=>{
                       if(n.name==='按位快选'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='012路')).btn.find((item)=>(item.name==='按位快选')).batch
                        let save=this.selectList.find((item)=>(item.name==='012路')).btn.find((item)=>(item.name==='按位快选')).isSave===1?true:false
                        this.arr.push(this.road012(1,list,save))
                       } 
                       if(n.name==='按位任选'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='012路')).btn.find((item)=>(item.name==='按位任选')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='012路')).btn.find((item)=>(item.name==='按位任选')).isSave===1?true:false
                        this.arr.push(this.road012(2,list,save))
                       } 
                       if(n.name==='出现比例'&&n.isTrue===1){
                        let list=[]
                        list=this.selectList.find((item)=>(item.name==='012路')).btn.find((item)=>(item.name==='出现比例')).checkboxval
                        let save=this.selectList.find((item)=>(item.name==='012路')).btn.find((item)=>(item.name==='出现比例')).isSave===1?true:false
                        this.arr.push(this.road012(3,list,save))
                       } 
                       if(n.name==='断组'&&n.isTrue===1){
                        
                            let flag=this.selectList.find((item)=>(item.name==='012路')).btn.find((item)=>(item.name==='断组')).flag
                            if(flag==="true"){
                                let save=this.selectList.find((item)=>(item.name==='012路')).btn.find((item)=>(item.name==='断组')).isSave===1?true:false
                                this.arr.push(this.road012(4,flag,save))
                            }
                           
                        
                        
                       } 
                       
                    })
                    
                }
                if(item.name==='开奖历史'){
                    item.btn.forEach((n)=>{
                       if(n.name==='七星彩'&&n.isTrue===1){
                        
                        let buttonval=this.selectList.find((item)=>(item.name==='开奖历史')).btn.find((item)=>(item.name==='七星彩')).buttonval
                        let save=this.selectList.find((item)=>(item.name==='开奖历史')).btn.find((item)=>(item.name==='七星彩')).isSave===1?true:false
                        this.arr.push(this.lotteryhistory(7,buttonval,save))
                       } 
                       if(n.name==='排列五'&&n.isTrue===1){
                        
                        let buttonval2=this.selectList.find((item)=>(item.name==='开奖历史')).btn.find((item)=>(item.name==='排列五')).buttonval2
                        let save=this.selectList.find((item)=>(item.name==='开奖历史')).btn.find((item)=>(item.name==='排列五')).isSave===1?true:false
                        this.arr.push(this.lotteryhistory(5,buttonval2,save))
                       } 
                       
                       
                    })
                    
                }

                
                
            })
            if(this.arr.length!==0){
                if(!this.unionSet){  //交集
                    this.lastall=this.arr[0]
                    for(let i=1;i<this.arr.length;i++){
                        this.lastall=this.lastall.filter((item)=>{
                            if(this.arr[i].indexOf(item)>-1)
                                return item  
                        })
                        
                    }
                    // console.log(this.lastall)
                }else{  //并集
                    this.lastall=this.arr[0]
                    for(let i=1;i<this.arr.length;i++){
                        
                        this.lastall=this.lastall.concat(this.arr[i].filter(item=>this.lastall.indexOf(item)==-1))
            
                    }

                    this.lastall=this.lastall.sort()
                    // console.log(this.lastall)
                }
            }
            
    },
    //勾选条件或待选 昨天到这里 还需改
    chooses(name1,name2,select){
        // let num=this.getChooseNum()//获取已勾选的条数
        // console.log(num)
        this.selectTitle1 = name1
        this.selectTitle2 = name2
        
            if(select===1){ //已选的改为待选
            this.selectList.find((item)=>(item.name===name1)).btn.find((item)=>(item.name===name2)).isTrue=2

            }
            if(select===2){ //待选的改为已选
            this.selectList.find((item)=>(item.name===name1)).btn.find((item)=>(item.name===name2)).isTrue=1

            }
            //修改大底
            this.getLastAll()
            // console.log(this.selectList)
            // console.log(this.lastAll)
        
           if(this.getChooseNum()===0){
            //还原全局大底 true表示不关闭已选条件弹窗 false表示关闭已选条件弹窗
            this.clearlastall(true)
           }
        
        
    },
    //删除勾选条件 昨天到这里
    deletechoose(name1,name2){
        this.selectTitle1 = name1
        this.selectTitle2 = name2
        if(this.waitingselected!==0){
            //该为 为选中
            this.selectList.find((item)=>(item.name===name1)).btn.find((item)=>(item.name===name2)).isTrue=0
            //修改大底
            this.getLastAll()
            // console.log(this.waitingselected)
            //统计是否还有条件
            if(this.waitingselected===0){
                //关闭弹窗
                // //还原全局大底 true表示不关闭已选条件弹窗 false表示关闭已选条件弹窗
                this.clearlastall(false)
                // console.log('已经删完了条件')
                // console.log(this.selectShow2)
                // console.log(this.selectList)
                // console.log(this.lastall)
         }
         }
        

         

    },
    //计算勾选的条件
    getChooseNum(){
        let num=0
       
        //从第二个开始统计，因为第一个是交集并集
        for(let i=1;i<this.selectList.length;i++){
            this.selectList[i].btn.forEach((item)=>{
                if(item.isTrue===1){
                    num++
                }
            })
        }
        return num
    },
    //跳转号码展示区（查看大底）
    gotoshownumber(){
        this.$router.push({
            name:'prizeNumbers',
        
        })
        
    },

    //复选框选择的数据
    showcheckedval(){
        this.selectnumber=''
        // console.log(this.selectTitle1,this.selectTitle2)
        // console.log(this.numberval)
        if(this.selectTitle1==='连号（兄弟号）'){
            this.checkedval.forEach((item)=>{
            if(item==='2')
            this.selectnumber+='二连号,'
            if(item==='3')
            this.selectnumber+='三连号,'
            if(item==='4')
            this.selectnumber+='四连号,'
            })
        }
        if(this.selectTitle1==='凹凸升降'){
            this.checkedval.forEach((item)=>{
            if(item==='ao')
            this.selectnumber+='凹下型,'
            if(item==='tu')
            this.selectnumber+='凸起型,'
            if(item==='up')
            this.selectnumber+='上升型,'
            if(item==='down')
            this.selectnumber+='下降型,'
            if(item==='level')
            this.selectnumber+='平行型,'
            })
        }
        if(this.selectTitle1==='对数'){
            this.checkedval.forEach((item)=>{
            if(item==='1')
            this.selectnumber+='至少包含1组,'
            if(item==='2')
            this.selectnumber+='至少包含2组,'
           
            })
        }
        if(this.selectTitle1==='重数'){
            this.checkedval.forEach((item)=>{
            if(item==='1')
            this.selectnumber+='双重,'
            if(item==='2')
            this.selectnumber+='双双重,'
            if(item==='3')
            this.selectnumber+='三重,'
            if(item==='4')
            this.selectnumber+='四重,'
           
            })
        }
        if(this.selectTitle1==='入数个数'){
            this.checkedval.forEach((item)=>{
            if(item==='1')
            this.selectnumber+='1个,'
            if(item==='2')
            this.selectnumber+='2个,'
            if(item==='3')
            this.selectnumber+='3个,'
            if(item==='4')
            this.selectnumber+='四个,'
           
            })
        }
        if(this.selectTitle1==='有数'){
            if(this.selectTitle2==='3个位置'){
                this.checkedval.forEach((item)=>{
                if(item==='ABC')
                this.selectnumber+='ABC/'
                if(item==='ABD')
                this.selectnumber+='ABD/'
                if(item==='ACD')
                this.selectnumber+='ACD/'
                if(item==='BCD')
                this.selectnumber+='BCD/'
            
                })
                this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
                this.selectnumber+=':'
                this.numberval.forEach((item,index)=>{
                if(item){
                    this.selectnumber+=index+','
                }
                
                })
            }
            if(this.selectTitle2==='2个位置'){
                this.checkedval.forEach((item)=>{
                if(item==='AB')
                this.selectnumber+='AB/'
                if(item==='AC')
                this.selectnumber+='AC/'
                if(item==='AD')
                this.selectnumber+='AD/'
                if(item==='BC')
                this.selectnumber+='BC/'
                if(item==='BD')
                this.selectnumber+='BD/'
                if(item==='CD')
                this.selectnumber+='CD/'
            
                })
                this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
                this.selectnumber+=':'
                this.numberval.forEach((item,index)=>{
                if(item){
                    this.selectnumber+=index+','
                }
                
                })
            }
            
        }
        if(this.selectTitle1==='三数合'){
            if(this.selectTitle2==='定位置'){
                this.checkedval.forEach((item)=>{
                if(item==='ABC')
                this.selectnumber+='ABC/'
                if(item==='ABD')
                this.selectnumber+='ABD/'
                if(item==='ACD')
                this.selectnumber+='ACD/'
                if(item==='BCD')
                this.selectnumber+='BCD/'
            
                })
                this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
                this.selectnumber+=':'
                this.numberval.forEach((item,index)=>{
                if(item){
                    this.selectnumber+=index+','
                }
                
                })
            }
          
            
        }
        if(this.selectTitle1==='两数合'){
            if(this.selectTitle2==='定位置'){
                this.checkedval.forEach((item)=>{
                if(item==='AB')
                this.selectnumber+='AB/'
                if(item==='AC')
                this.selectnumber+='AC/'
                if(item==='AD')
                this.selectnumber+='AD/'
                if(item==='BC')
                this.selectnumber+='BC/'
                if(item==='BD')
                this.selectnumber+='BD/'
                if(item==='CD')
                this.selectnumber+='CD/'
            
                })
                this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
                this.selectnumber+=':'
                this.numberval.forEach((item,index)=>{
                if(item){
                    this.selectnumber+=index+','
                }
                
                })
            }
            
        }
        if(this.selectTitle1==='三数乘积'){
            if(this.selectTitle2==='合值'){
                this.checkedval.forEach((item)=>{
                if(item==='abc')
                this.selectnumber+='ABC/'
                if(item==='abd')
                this.selectnumber+='ABD/'
                if(item==='acd')
                this.selectnumber+='ACD/'
                if(item==='bcd')
                this.selectnumber+='BCD/'
            
                })
                this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
                this.selectnumber+=':'
                this.numberval.forEach((item,index)=>{
                if(item){
                    this.selectnumber+=index+','
                }
                
                })
            }
          
            
        }
        if(this.selectTitle1==='两数乘积'){
            if(this.selectTitle2==='合值'){
                this.checkedval.forEach((item)=>{
                if(item==='ab')
                this.selectnumber+='AB/'
                if(item==='ac')
                this.selectnumber+='AC/'
                if(item==='ad')
                this.selectnumber+='AD/'
                if(item==='bc')
                this.selectnumber+='BC/'
                if(item==='bd')
                this.selectnumber+='BD/'
                if(item==='cd')
                this.selectnumber+='CD/'
            
                })
                this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
                this.selectnumber+=':'
                this.numberval.forEach((item,index)=>{
                if(item){
                    this.selectnumber+=index+','
                }
                
                })
            }
            
        }
        if(this.selectTitle1==='减式'){
            if(this.selectTitle2==='合值'){
                
                this.selectnumber+='|'
                this.positionfront.forEach((item)=>{
                    if(item==='A')
                    this.selectnumber+='A+'
                    if(item==='B')
                    this.selectnumber+='B+'
                    if(item==='C')
                    this.selectnumber+='C+'
                    if(item==='D')
                    this.selectnumber+='D+'
                })
                this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
                this.positionafter.forEach((item)=>{
                    if(this.selectnumber==='')
                    this.selectnumber+='|'
                    if(item==='A')
                    this.selectnumber+='-A'
                    if(item==='B')
                    this.selectnumber+='-B'
                    if(item==='C')
                    this.selectnumber+='-C'
                    if(item==='D')
                    this.selectnumber+='-D'
                })
                
                this.selectnumber+='|合'
                this.numberval.forEach((item,index)=>{
                    if(item){
                        this.selectnumber+=index+','
                    }
                
                })
            }
            
        }
       
        
         //去掉最后一个，
         this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
         if(this.selectnumber==='|')
            this.selectnumber=''
           //每点一次就更新数据
           this.$forceUpdate()
    },
    //复杂的复选框
    showcheckedval2(){
        // console.log(this.checkedval)
        this.selectnumber=''
        if(this.selectTitle1==='单双（奇偶）'){
            if(this.selectTitle2==='按位任选'){
                this.checkedval.forEach((item)=>{
                    if(this.type===1){//四定
                        this.commoncombination1_4.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.single1_4[index]+','
                      })
                    }
                    if(this.type===3){//三定
                        this.commoncombination1_3.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.single1_3[index]+','
                      })
                    }
                    if(this.type===5){//二定
                        this.commoncombination1_2.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.single1_2[index]+','
                      })
                    }
                })
                
            }
            if(this.selectTitle2==='出现比例'){
                this.checkedval.forEach((item)=>{
                    this.selectnumber+=item+','
                })
                
            }
            if(this.selectTitle2==='断组'){
                this.checkedval.forEach((item)=>{
                    if(item==='true')
                    this.selectnumber+='单双不同时出现,'
                })
                
            }
            
        }
        if(this.selectTitle1==='大小'){
            if(this.selectTitle2==='按位任选'){
                this.checkedval.forEach((item)=>{
                    if(this.type===1){//四定
                        this.commoncombination1_4.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.big1_4[index]+','
                      })
                    }
                    if(this.type===3){//三定
                        this.commoncombination1_3.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.big1_3[index]+','
                      })
                    }
                    if(this.type===5){//二定
                        this.commoncombination1_2.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.big1_2[index]+','
                      })
                    }
                })
                
            }
            if(this.selectTitle2==='出现比例'){
                this.checkedval.forEach((item)=>{
                    this.selectnumber+=item+','
                })
                
            }
            if(this.selectTitle2==='断组'){
                this.checkedval.forEach((item)=>{
                    if(item==='true')
                    this.selectnumber+='大小不同时出现,'
                })
                
            }
            
        }
        if(this.selectTitle1==='质合'){
            if(this.selectTitle2==='按位任选'){
                this.checkedval.forEach((item)=>{
                    if(this.type===1){//四定
                        this.commoncombination1_4.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.prime1_4[index]+','
                      })
                    }
                    if(this.type===3){//三定
                        this.commoncombination1_3.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.prime1_3[index]+','
                      })
                    }
                    if(this.type===5){//二定
                        this.commoncombination1_2.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.prime1_2[index]+','
                      })
                    }
                })
                
            }
            if(this.selectTitle2==='出现比例'){
                this.checkedval.forEach((item)=>{
                    this.selectnumber+=item+','
                })
                
            }
            if(this.selectTitle2==='断组'){
                this.checkedval.forEach((item)=>{
                    if(item==='true')
                    this.selectnumber+='质合不同时出现,'
                })
                
            }
            
        }
        if(this.selectTitle1==='大中小'){
            if(this.selectTitle2==='按位任选'){
                this.checkedval.forEach((item)=>{
                    if(this.type===1){//四定
                        this.commoncombination2_4.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.big2_4[index]+','
                      })
                    }
                    if(this.type===3){//三定
                        this.commoncombination2_3.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.big2_3[index]+','
                      })
                    }
                    if(this.type===5){//二定
                        this.commoncombination2_2.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.big2_2[index]+','
                      })
                    }
                })
                
            }
            if(this.selectTitle2==='出现比例'){
                this.checkedval.forEach((item)=>{
                    this.selectnumber+=item+','
                })
                
            }
            if(this.selectTitle2==='断组'){
                this.checkedval.forEach((item)=>{
                    if(item==='true')
                    this.selectnumber+='大中小不同时出现,'
                })
                
            }
            
        }
        if(this.selectTitle1==='012路'){
            if(this.selectTitle2==='按位任选'){
                this.checkedval.forEach((item)=>{
                    if(this.type===1){//四定
                        this.commoncombination2_4.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.way2_4[index]+','
                      })
                    }
                    if(this.type===3){//三定
                        this.commoncombination2_3.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.way2_3[index]+','
                      })
                    }
                    if(this.type===5){//二定
                        this.commoncombination2_2.forEach((n,index)=>{
                        if(item===n)
                        this.selectnumber+=this.way2_2[index]+','
                      })
                    }
                })
                
            }
            if(this.selectTitle2==='出现比例'){
                this.checkedval.forEach((item)=>{
                    this.selectnumber+=item+','
                })
                
            }
            if(this.selectTitle2==='断组'){
                this.checkedval.forEach((item)=>{
                    if(item==='true')
                    this.selectnumber+='012路不同时出现,'
                })
                
            }
            
        }
        
        if(this.selectTitle1==='定位置'){
            if(this.selectTitle2==='乘号位置'){
                this.checkedval.forEach((item)=>{
                    if(item==='qian')
                    this.selectnumber+='千位,'
                    if(item==='bai')
                    this.selectnumber+='百位,'
                    if(item==='shi')
                    this.selectnumber+='十位,'
                    if(item==='ge')
                    this.selectnumber+='个位,'
                })
                
            }
            
            
        }
        
        
        
         //去掉最后一个，
         this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
           //每点一次就更新数据
           this.$forceUpdate()
    },
    //单选按钮选择的数据
    showradioval(){
        this.selectnumber=''
        this.checkedval=[]
        let total=0
        // console.log(this.checkedval)
        // console.log(this.radioval4)
        // console.log(this.radioval3)
        // console.log(this.radioval2)
        // console.log(this.radioval) JSON.parse(JSON.stringify(this.numberval))
        if(this.selectTitle1==='单双（奇偶）'&&this.selectTitle2==='按位快选'){
            if(this.radioval!==''){
                if(this.radioval==="{name:'qian',code:1}"){
                this.checkedval.push({name:'qian',code:1})
                this.selectnumber+='千位：单，'   
                }
                if(this.radioval==="{name:'qian',code:0}"){
                this.checkedval.push({name:'qian',code:0})
                this.selectnumber+='千位：双，'
                }
            }
            if(this.radioval2!==''){
                if(this.radioval2==="{name:'bai',code:1}"){
                this.checkedval.push({name:'bai',code:1})
                this.selectnumber+='百位：单，'   
                }
                if(this.radioval2==="{name:'bai',code:0}"){
                this.checkedval.push({name:'bai',code:0})
                this.selectnumber+='百位：双，'
                }
            }
            if(this.radioval3!==''){
                if(this.radioval3==="{name:'shi',code:1}"){
                this.checkedval.push({name:'shi',code:1})
                this.selectnumber+='十位：单，'   
                }
                if(this.radioval3==="{name:'shi',code:0}"){
                this.checkedval.push({name:'shi',code:0})
                this.selectnumber+='十位：双，'
                }
            }
            if(this.radioval4!==''){
                if(this.radioval4==="{name:'ge',code:1}"){
                this.checkedval.push({name:'ge',code:1})
                this.selectnumber+='个位：单，'   
                }
                if(this.radioval4==="{name:'ge',code:0}"){
                this.checkedval.push({name:'ge',code:0})
                this.selectnumber+='个位：双，'
                }
            }
        }
        if(this.selectTitle1==='大小'&&this.selectTitle2==='按位快选'){
            if(this.radioval!==''){
                if(this.radioval==="{name:'qian',code:1}"){
                this.checkedval.push({name:'qian',code:1})
                this.selectnumber+='千位：大，'   
                }
                if(this.radioval==="{name:'qian',code:0}"){
                this.checkedval.push({name:'qian',code:0})
                this.selectnumber+='千位：小，'
                }
            }
            if(this.radioval2!==''){
                if(this.radioval2==="{name:'bai',code:1}"){
                this.checkedval.push({name:'bai',code:1})
                this.selectnumber+='百位：大，'   
                }
                if(this.radioval2==="{name:'bai',code:0}"){
                this.checkedval.push({name:'bai',code:0})
                this.selectnumber+='百位：小，'
                }
            }
            if(this.radioval3!==''){
                if(this.radioval3==="{name:'shi',code:1}"){
                this.checkedval.push({name:'shi',code:1})
                this.selectnumber+='十位：大，'   
                }
                if(this.radioval3==="{name:'shi',code:0}"){
                this.checkedval.push({name:'shi',code:0})
                this.selectnumber+='十位：小，'
                }
            }
            if(this.radioval4!==''){
                if(this.radioval4==="{name:'ge',code:1}"){
                this.checkedval.push({name:'ge',code:1})
                this.selectnumber+='个位：大，'   
                }
                if(this.radioval4==="{name:'ge',code:0}"){
                this.checkedval.push({name:'ge',code:0})
                this.selectnumber+='个位：小，'
                }
            }
        }
        if(this.selectTitle1==='质合'&&this.selectTitle2==='按位快选'){
            if(this.radioval!==''){
                if(this.radioval==="{name:'qian',code:1}"){
                this.checkedval.push({name:'qian',code:1})
                this.selectnumber+='千位：质，'   
                }
                if(this.radioval==="{name:'qian',code:0}"){
                this.checkedval.push({name:'qian',code:0})
                this.selectnumber+='千位：合，'
                }
            }
            if(this.radioval2!==''){
                if(this.radioval2==="{name:'bai',code:1}"){
                this.checkedval.push({name:'bai',code:1})
                this.selectnumber+='百位：质，'   
                }
                if(this.radioval2==="{name:'bai',code:0}"){
                this.checkedval.push({name:'bai',code:0})
                this.selectnumber+='百位：合，'
                }
            }
            if(this.radioval3!==''){
                if(this.radioval3==="{name:'shi',code:1}"){
                this.checkedval.push({name:'shi',code:1})
                this.selectnumber+='十位：质，'   
                }
                if(this.radioval3==="{name:'shi',code:0}"){
                this.checkedval.push({name:'shi',code:0})
                this.selectnumber+='十位：合，'
                }
            }
            if(this.radioval4!==''){
                if(this.radioval4==="{name:'ge',code:1}"){
                this.checkedval.push({name:'ge',code:1})
                this.selectnumber+='个位：质，'   
                }
                if(this.radioval4==="{name:'ge',code:0}"){
                this.checkedval.push({name:'ge',code:0})
                this.selectnumber+='个位：合，'
                }
            }
        }
        if(this.selectTitle1==='大中小'&&this.selectTitle2==='按位快选'){
            if(this.radioval!==''){
                if(this.radioval==="{name:'qian',code:2}"){
                this.checkedval.push({name:'qian',code:2})
                this.selectnumber+='千位：大，'   
                }
                if(this.radioval==="{name:'qian',code:1}"){
                this.checkedval.push({name:'qian',code:1})
                this.selectnumber+='千位：中，'   
                }
                if(this.radioval==="{name:'qian',code:0}"){
                this.checkedval.push({name:'qian',code:0})
                this.selectnumber+='千位：小，'
                }
            }
            if(this.radioval2!==''){
                if(this.radioval2==="{name:'bai',code:2}"){
                this.checkedval.push({name:'bai',code:2})
                this.selectnumber+='百位：大，'   
                }
                if(this.radioval2==="{name:'bai',code:1}"){
                this.checkedval.push({name:'bai',code:1})
                this.selectnumber+='百位：中，'   
                }
                if(this.radioval2==="{name:'bai',code:0}"){
                this.checkedval.push({name:'bai',code:0})
                this.selectnumber+='百位：小，'
                }
            }
            if(this.radioval3!==''){
                if(this.radioval3==="{name:'shi',code:2}"){
                this.checkedval.push({name:'shi',code:2})
                this.selectnumber+='十位：大，'   
                }
                if(this.radioval3==="{name:'shi',code:1}"){
                this.checkedval.push({name:'shi',code:1})
                this.selectnumber+='十位：中，'   
                }
                if(this.radioval3==="{name:'shi',code:0}"){
                this.checkedval.push({name:'shi',code:0})
                this.selectnumber+='十位：小，'
                }
            }
            if(this.radioval4!==''){
                if(this.radioval4==="{name:'ge',code:2}"){
                this.checkedval.push({name:'ge',code:2})
                this.selectnumber+='个位：大，'   
                }
                if(this.radioval4==="{name:'ge',code:1}"){
                this.checkedval.push({name:'ge',code:1})
                this.selectnumber+='个位：中，'   
                }
                if(this.radioval4==="{name:'ge',code:0}"){
                this.checkedval.push({name:'ge',code:0})
                this.selectnumber+='个位：小，'
                }
            }
        }
        if(this.selectTitle1==='012路'&&this.selectTitle2==='按位快选'){
            if(this.radioval!==''){
                if(this.radioval==="{name:'qian',code:2}"){
                this.checkedval.push({name:'qian',code:2})
                this.selectnumber+='千位：2路，'   
                }
                if(this.radioval==="{name:'qian',code:1}"){
                this.checkedval.push({name:'qian',code:1})
                this.selectnumber+='千位：1路，'   
                }
                if(this.radioval==="{name:'qian',code:0}"){
                this.checkedval.push({name:'qian',code:0})
                this.selectnumber+='千位：0路，'
                }
            }
            if(this.radioval2!==''){
                if(this.radioval2==="{name:'bai',code:2}"){
                this.checkedval.push({name:'bai',code:2})
                this.selectnumber+='百位：2路，'   
                }
                if(this.radioval2==="{name:'bai',code:1}"){
                this.checkedval.push({name:'bai',code:1})
                this.selectnumber+='百位：1路，'   
                }
                if(this.radioval2==="{name:'bai',code:0}"){
                this.checkedval.push({name:'bai',code:0})
                this.selectnumber+='百位：0路，'
                }
            }
            if(this.radioval3!==''){
                if(this.radioval3==="{name:'shi',code:2}"){
                this.checkedval.push({name:'shi',code:2})
                this.selectnumber+='十位：2路，'   
                }
                if(this.radioval3==="{name:'shi',code:1}"){
                this.checkedval.push({name:'shi',code:1})
                this.selectnumber+='十位：1路，'   
                }
                if(this.radioval3==="{name:'shi',code:0}"){
                this.checkedval.push({name:'shi',code:0})
                this.selectnumber+='十位：0路，'
                }
            }
            if(this.radioval4!==''){
                if(this.radioval4==="{name:'ge',code:2}"){
                this.checkedval.push({name:'ge',code:2})
                this.selectnumber+='个位：2路，'   
                }
                if(this.radioval4==="{name:'ge',code:1}"){
                this.checkedval.push({name:'ge',code:1})
                this.selectnumber+='个位：1路，'   
                }
                if(this.radioval4==="{name:'ge',code:0}"){
                this.checkedval.push({name:'ge',code:0})
                this.selectnumber+='个位：0路，'
                }
            }
        }
        if(this.selectTitle1==='定位置'&&this.selectTitle2==='批量选码'){
            if(this.radioval1_1!==''||this.radioval1_2!==''||this.radioval1_3!==''){
                total++
                let json={name:'qian'}
                this.selectnumber+='千位：'
                if(this.radioval1_1==="单"){
                    this.selectnumber+='单/'
                    Object.assign(json,{dan:1})
                }
                if(this.radioval1_1==="双"){
                    this.selectnumber+='双/'
                    Object.assign(json,{dan:0})
                }
                if(this.radioval1_2==="大"){
                    this.selectnumber+='大/'
                    Object.assign(json,{da:1})
                    
                }
                if(this.radioval1_2==="小"){
                    this.selectnumber+='小/'
                    Object.assign(json,{da:0})

                }
                if(this.radioval1_3==="0路"){
                this.selectnumber+='0路/'
                Object.assign(json,{lu:0})

                }
                else if(this.radioval1_3==="1路"){
                this.selectnumber+='1路/'
                Object.assign(json,{lu:1})

                }
                else if(this.radioval1_3==="2路"){
                this.selectnumber+='2路/'
                Object.assign(json,{lu:2})

                }
                
                this.checkedval.push(json)
                
            }
            if(this.radioval2_1!==''||this.radioval2_2!==''||this.radioval2_3!==''){
                total++
                let json={name:'bai'}
                this.selectnumber+='百位：'
                if(this.radioval2_1==="单"){
                    this.selectnumber+='单/'
                    Object.assign(json,{dan:1})
                }
                if(this.radioval2_1==="双"){
                    this.selectnumber+='双/'
                    Object.assign(json,{dan:0})
                }
                if(this.radioval2_2==="大"){
                    this.selectnumber+='大/'
                    Object.assign(json,{da:1})
                    
                }
                if(this.radioval2_2==="小"){
                    this.selectnumber+='小/'
                    Object.assign(json,{da:0})

                }
                if(this.radioval2_3==="0路"){
                this.selectnumber+='0路/'
                Object.assign(json,{lu:0})

                }
                else if(this.radioval2_3==="1路"){
                this.selectnumber+='1路/'
                Object.assign(json,{lu:1})

                }
                else if(this.radioval2_3==="2路"){
                this.selectnumber+='2路/'
                Object.assign(json,{lu:2})

                }
                
                this.checkedval.push(json)
                
            }
            if(this.radioval3_1!==''||this.radioval3_2!==''||this.radioval3_3!==''){
                total++
                let json={name:'shi'}
                this.selectnumber+='十位：'
                if(this.radioval3_1==="单"){
                    this.selectnumber+='单/'
                    Object.assign(json,{dan:1})
                }
                if(this.radioval3_1==="双"){
                    this.selectnumber+='双/'
                    Object.assign(json,{dan:0})
                }
                if(this.radioval3_2==="大"){
                    this.selectnumber+='大/'
                    Object.assign(json,{da:1})
                    
                }
                if(this.radioval3_2==="小"){
                    this.selectnumber+='小/'
                    Object.assign(json,{da:0})

                }
                if(this.radioval3_3==="0路"){
                this.selectnumber+='0路/'
                Object.assign(json,{lu:0})

                }
                else if(this.radioval3_3==="1路"){
                this.selectnumber+='1路/'
                Object.assign(json,{lu:1})

                }
                else if(this.radioval3_3==="2路"){
                this.selectnumber+='2路/'
                Object.assign(json,{lu:2})

                }
                
                this.checkedval.push(json)
                
            }
            if(this.radioval4_1!==''||this.radioval4_2!==''||this.radioval4_3!==''){
                total++
                let json={name:'ge'}
                this.selectnumber+='个位：'
                if(this.radioval4_1==="单"){
                    this.selectnumber+='单/'
                    Object.assign(json,{dan:1})
                }
                if(this.radioval4_1==="双"){
                    this.selectnumber+='双/'
                    Object.assign(json,{dan:0})
                }
                if(this.radioval4_2==="大"){
                    this.selectnumber+='大/'
                    Object.assign(json,{da:1})
                    
                }
                if(this.radioval4_2==="小"){
                    this.selectnumber+='小/'
                    Object.assign(json,{da:0})

                }
                if(this.radioval4_3==="0路"){
                this.selectnumber+='0路/'
                Object.assign(json,{lu:0})

                }
                else if(this.radioval4_3==="1路"){
                this.selectnumber+='1路/'
                Object.assign(json,{lu:1})

                }
                else if(this.radioval4_3==="2路"){
                this.selectnumber+='2路/'
                Object.assign(json,{lu:2})

                }
                
                this.checkedval.push(json)
                
            }
            // console.log(this.checkedval)  
        }   
        
       

        //判断是否可选
        if(this.selectTitle1==='定位置'&&this.selectTitle2==='批量选码'){
            if(this.type===4||this.type===3){
                if(total>=3){
                    if(this.radioval1_1===''&&this.radioval1_2===''&&this.radioval1_3==='')
                    this.disabled1=true
                    if(this.radioval2_1===''&&this.radioval2_2===''&&this.radioval2_3==='')
                    this.disabled2=true
                    if(this.radioval3_1===''&&this.radioval3_2===''&&this.radioval3_3==='')
                    this.disabled3=true
                    if(this.radioval4_1===''&&this.radioval4_2===''&&this.radioval4_3==='')
                    this.disabled4=true
                }
            }
            if(this.type===5||this.type===6){
                if(total>=2){
                    if(this.radioval1_1===''&&this.radioval1_2===''&&this.radioval1_3==='')
                    this.disabled1=true
                    if(this.radioval2_1===''&&this.radioval2_2===''&&this.radioval2_3==='')
                    this.disabled2=true
                    if(this.radioval3_1===''&&this.radioval3_2===''&&this.radioval3_3==='')
                    this.disabled3=true
                    if(this.radioval4_1===''&&this.radioval4_2===''&&this.radioval4_3==='')
                    this.disabled4=true
                }
            }

        }else{
            if(this.type===4||this.type===3){
                if(this.checkedval.length>=3){
                    if(this.radioval==='')
                    this.disabled1=true
                    if(this.radioval2==='')
                    this.disabled2=true
                    if(this.radioval3==='')
                    this.disabled3=true
                    if(this.radioval4==='')
                    this.disabled4=true
                }
            }
            if(this.type===5||this.type===6){
                if(this.checkedval.length>=2){
                    if(this.radioval==='')
                    this.disabled1=true
                    if(this.radioval2==='')
                    this.disabled2=true
                    if(this.radioval3==='')
                    this.disabled3=true
                    if(this.radioval4==='')
                    this.disabled4=true
                }
            }
        }
       
        // console.log(this.disabled1,this.disabled2,this.disabled3,this.disabled4,this.checkedval.length)
        // console.log(this.checkedval)
        
         //去掉最后一个，
         this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
           //每点一次就更新数据
           this.$forceUpdate()
    },

    //选择了多组数
    groupval(){
        this.selectnumber=''
        // console.log(this.selectTitle1,this.selectTitle2)
        // console.log(this.group1)
        // console.log(this.group2)
        // console.log(this.group3)
        // console.log(this.group4)
       
        if(this.selectTitle1==='配号码'){
            this.group1.forEach((item,index)=>{
                if(item)
                this.selectnumber+=index
            })
            this.selectnumber+=',配'
            this.group2.forEach((item,index)=>{
                if(item)
                this.selectnumber+=index
            })
            if(this.type===1||this.type===2||this.type===3||this.type===4){ //三、四 定、现
                this.selectnumber+=',配'
                this.group3.forEach((item,index)=>{
                    if(item)
                    this.selectnumber+=index
                })
            }
            if(this.type===1||this.type===2){ // 四定、现
                this.selectnumber+=',配'
                this.group4.forEach((item,index)=>{
                    if(item)
                    this.selectnumber+=index
                })
            }
        } 
        if(this.selectTitle1==='定位置'&&this.selectTitle2==='自由选码'){
            let sum1=0
            let sum2=0
            let sum3=0
            let sum4=0
            let total=0
            this.disabled1=false
            this.disabled2=false
            this.disabled3=false
            this.disabled4=false
            this.group1.forEach((item)=>{
                    if(item)
                    sum1++
            })
            this.group2.forEach((item)=>{
                    if(item)
                    sum2++
            })
            this.group3.forEach((item)=>{
                    if(item)
                    sum3++
            })
            this.group4.forEach((item)=>{
                    if(item)
                    sum4++
            })

            if(sum1!==0){ 
                total++
                this.selectnumber+='千位：'
                this.group1.forEach((item,index)=>{
                    if(item)
                    this.selectnumber+=index
                })
                this.selectnumber+='，'
            }
            if(sum2!==0){ 
                total++
                this.selectnumber+='百位：'
                this.group2.forEach((item,index)=>{
                    if(item)
                    this.selectnumber+=index
                })
                this.selectnumber+='，'
            }
            if(sum3!==0){ 
                total++
                this.selectnumber+='十位：'
                this.group3.forEach((item,index)=>{
                    if(item)
                    this.selectnumber+=index
                })
                this.selectnumber+='，'
            }
            if(sum4!==0){ 
                total++
                this.selectnumber+='个位：'
                this.group4.forEach((item,index)=>{
                    if(item)
                    this.selectnumber+=index
                })
                this.selectnumber+='，'
            }
            //去掉最后一个，
         this.selectnumber=this.selectnumber.substr(0,this.selectnumber.length-1)
            
         //根据四 三 二 来定展示多少组
         if(this.type===3){//三定
            if(total>=3&&sum1===0){
                this.disabled1=true
            }
            if(total>=3&&sum2===0){
                this.disabled2=true
            }
            if(total>=3&&sum3===0){
                this.disabled3=true
            }
            if(total>=3&&sum4===0){
                this.disabled4=true
            }
         }
         if(this.type===5){//二定
            if(total>=2&&sum1===0){
                this.disabled1=true
            }
            if(total>=2&&sum2===0){
                this.disabled2=true
            }
            if(total>=2&&sum3===0){
                this.disabled3=true
            }
            if(total>=2&&sum4===0){
                this.disabled4=true
            }
         }
        } 
        
    },


    //开奖历史
    lotteryhistory(type,val,save){
        this.recover() //复原大底
        this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
            let list4
            let list3
            let list2
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(type===7){//七星彩
                
                if(temp.length===4){//四定
                    list4=this.lotteryhistoryseven4.slice(0,val)
                    list4.forEach((n)=>{
                        if(save){
                            if(n===item){
                            flag=true
                            }
                        }else{
                            if(n===item){
                            flag2=false
                            }
                        }
                })
          
                }
                if(temp.length===3){//三定
                    list3=this.lotteryhistoryseven3.slice(0,val*4)
                    list3.forEach((n)=>{
                        if(save){
                            if(n===item){
                            flag=true
                            }
                        }else{
                            if(n===item){
                            flag2=false
                            }
                        }
                    })
                    
                    
                    
                }
                if(temp.length===2){//二定
                    list2=this.lotteryhistoryseven2.slice(0,val*6)
                    list2.forEach((n)=>{
                        if(save){
                            if(n===item){
                            flag=true
                            }
                        }else{
                            if(n===item){
                            flag2=false
                            }
                        }
                    })
                }

            }
            if(type===5){//排列五
                if(temp.length===4){//四定
                    list4=this.lotteryhistoryfive4.slice(0,val)
                    list4.forEach((n)=>{
                        if(save){
                            if(n===item){
                            flag=true
                            }
                        }else{
                            if(n===item){
                            flag2=false
                            }
                        }
                })
          
                }
                if(temp.length===3){//三定
                    list3=this.lotteryhistoryfive3.slice(0,val*4)
                    list3.forEach((n)=>{
                        if(save){
                            if(n===item){
                            flag=true
                            }
                        }else{
                            if(n===item){
                            flag2=false
                            }
                        }
                    })
                    
                    
                    
                }
                if(temp.length===2){//二定
                    list2=this.lotteryhistoryfive2.slice(0,val*6)
                    list2.forEach((n)=>{
                        if(save){
                            if(n===item){
                            flag=true
                            }
                        }else{
                            if(n===item){
                            flag2=false
                            }
                        }
                    })
                }
            }
            
            if(flag||(!save&&flag2)){
                return item
            }
               
        })
        // console.log(this.all)
        return this.all  
    },
    
    // 全倒 昨天到这里
    spare(list,save){
       
        let sum=''
        let array4=[]
        let array3=[]
        let array2=[]
        let qian
        let bai
        let shi
        let ge
        let index
        let list2
        let list3
        let list4
        
        for(let i=0;i<list.length;i++){
            qian=list[i]+''
            index=list.indexOf(list[i])
            list2=list.filter((item,indexs) =>(indexs!==index))
            for(let i=0;i<list2.length;i++){
                bai=list2[i]+''
                sum=qian+bai
                array2.push(sum)
                index=list2.indexOf(list2[i])
                list3=list2.filter((item,indexs) =>(indexs!==index))
                for(let i=0;i<list3.length;i++){
                    shi=list3[i]+''
                    sum=qian+bai+shi
                    array3.push(sum)

                    index=list3.indexOf(list3[i])
                    list4=list3.filter((item,indexs) =>(indexs!==index))
                    for(let i=0;i<list4.length;i++){
                        ge=list4[i]+''
                        sum=qian+bai+shi+ge
                        array4.push(sum)

                        
                    }
                }
            }
        }
        //去重
        array4=[...new Set(array4)]
        array3=[...new Set(array3)]
        array2=[...new Set(array2)]
       
        // console.log('四定的排列组',array4)
        // console.log('三定的排列组',array3)
        // console.log('二定的排列组',array2)
        this.recover() //复原大底
        this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定
                array4.forEach((n)=>{
                    if(save){
                        if(n===temp){
                        flag=true
                        }
                    }else{
                        if(n===temp){
                        flag2=false
                        }
                    }
               })
          
            }
            if(temp.length===3){//三定
                array3.forEach((n)=>{
                    if(save){
                        if(n===temp){
                        flag=true
                        }
                    }else{
                        if(n===temp){
                        flag2=false
                        }
                    }
               })
                
                
                
            }
            if(temp.length===2){//二定
                array2.forEach((n)=>{
                    if(save){
                        if(n===temp){
                        flag=true
                        }
                    }else{
                        if(n===temp){
                        flag2=false
                        }
                    }
               })
            }

            if(flag||(!save&&flag2)){
                return item
            }
               
        })
        // console.log(this.all)
        return this.all  
    },
    //AC值
    avalue(list,save){
        this.recover() //复原大底
        this.all=this.all.filter((item)=>{
            let total=0
            let arry=[]
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定现
                let ge= parseInt(temp.substr(3,1))
                let shi=parseInt(temp.substr(2,1))
                let bai=parseInt(temp.substr(1,1))
                let qian=parseInt(temp.substr(0,1))
                let ab=Math.abs(qian-bai)
                let ac=Math.abs(qian-shi)
                let ad=Math.abs(qian-ge)
                let bc=Math.abs(bai-shi)
                let bd=Math.abs(bai-ge)
                let cd=Math.abs(shi-ge)
                
                arry.push(ab,ac,ad,bc,bd,cd)
                arry=[...new Set(arry)]
                total=arry.length
            }
            if(temp.length===3){//三定现
                let ge=parseInt(temp.substr(2,1))
                let shi=parseInt(temp.substr(1,1))
                let bai=parseInt(temp.substr(0,1))
                let ab=Math.abs(bai-shi)
                let ac=Math.abs(bai-ge)
                let bc=Math.abs(shi-ge)
                
                
                arry.push(ab,ac,bc)
                //去重
                arry=[...new Set(arry)]
                total=arry.length
            }
            

                
                
                list.forEach((i)=>{
                    if(save){
                        if(total===i){
                        flag=true
                        }
                    }else{
                        if(total===i){
                        flag2=false
                        }
                    }
                   
                })
                if(flag||(!save&&flag2)){
                    return item
                }
               
        })
        // console.log(this.all)
        return this.all  
    },
    dan(value,n){
        
        if(value==='X')
        return false
        if(n==='')
        return true
        if(n===1){
            if(value%2!=0)
            return true
            else
            return false
        }
        if(n===0){
            if(value%2===0)
            return true
            else
            return false
        }
    },
    da(value,n){
        if(value==='X')
        return false
        if(n==='')
        return true
        if(n===1){//大
            if(value===9||value===8||value===7||value===6||value===5)
            return true
            else
            return false
        }
        if(n===0){//小
            if(value===0||value===1||value===2||value===3||value===4)
            return true
            else
            return false
        }
    },
    dazhongxiao(value,n){
        if(value==='X')
        return false
        if(n==='')
        return true
        if(n===2){//大
            if(value===9||value===8||value===7)
            return true
            else
            return false
        }
        if(n===1){//中
            if(value===3||value===4||value===5||value===6)
            return true
            else
            return false
        }
        if(n===0){//小
            if(value===0||value===1||value===2)
            return true
            else
            return false
        }
    },
    lus(value,n){
        if(value==='X')
        return false
        if(n==='')
        return true
        if(n===0){
            if(value%3===0)
            return true
            else
            return false
        }
        if(n===1){
            if(value%3===1)
            return true
            else
            return false
        }
        if(n===2){
            if(value%3===2)
            return true
            else
            return false
        }
    },
    zhi(value,n){
        if(value==='X')
        return false
        if(n==='')
        return true
        if(n===1){//质数
            if(value===1||value===2||value===3||value===5||value===7)
            return true
            else
            return false
        }
        if(n===0){//合数
            if(value===0||value===4||value===6||value===8||value===9)
            return true
            else
            return false
        }
    },
    
    //定位置 条件交集  已经符合3种玩法 四 三 二现没有定位功能 0 1 2 自由选码 批量选码 乘号位置
    staticplace(type,list,save){
        this.recover()
        if(type===1){//批量选码 条件并集
            
                    list.forEach((n)=>{
                        if(n.name==='qian'){
                            
                            this.all=this.all.filter((item)=>{
                            let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                            let flag=true
                            
                            if(save){
                                if(this.dan(qian,n.dan)||this.da(qian,n.da)||this.lus(qian,n.lu))
                                return item
                                
                            }else{
                                
                                    
                                if(this.dan(qian,n.dan)&&n.dan!==''||this.da(qian,n.da)&&n.da!==''||this.lus(qian,n.lu)&&n.lu!==''){//不返回
                                    flag=false
                                }
                            }
                            if(flag&&!save)
                            return item
                            })
                            
                            
                        }
                        if(n.name==='bai'){
                            this.all=this.all.filter((item)=>{
                            
                                
                            let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                            let flag=true
                            
                            if(save){
                                if(this.dan(bai,n.dan)||this.da(bai,n.da)||this.lus(bai,n.lu))
                                return item
                            }else{
                                if(this.dan(bai,n.dan)&&n.dan!==''||this.da(bai,n.da)&&n.da!==''||this.lus(bai,n.lu)&&n.lu!==''){
                                    flag=false
                                }
                                
                            }
                            if(flag&&!save)
                            return item
                            })
                            
                            
                        }
                        if(n.name==='shi'){
                            this.all=this.all.filter((item)=>{
                            
                            
                            let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                            let flag=true
                            
                            if(save){
                                if(this.dan(shi,n.dan)||this.da(shi,n.da)||this.lus(shi,n.lu))
                                return item
                            }else{
                                if(this.dan(shi,n.dan)&&n.dan!==''||this.da(shi,n.da)&&n.da!==''||this.lus(shi,n.lu)&&n.lu!==''){
                                  flag=false
                                }
                              
                            }
                            if(flag&&!save)
                            return item
                            })
                            
                            
                        }
                        if(n.name==='ge'){
                            this.all=this.all.filter((item)=>{
                            let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                            let flag=true
                            
                            if(save){
                                if(this.dan(ge,n.dan)||this.da(ge,n.da)||this.lus(ge,n.lu))
                                return item
                            }else{
                                if(this.dan(ge,n.dan)&&n.dan!==''||this.da(ge,n.da)&&n.da!==''||this.lus(ge,n.lu)&&n.lu!==''){
                                    flag=false
                                }
                               
                            }
                            if(flag&&!save)
                            return item
                            })
                            
                            
                        }
                        
                    })

        }
        if(type===0){//自由选码 条件交集
            if(list[0].length!==0){//千位选的号码
                this.all=this.all.filter((item)=>{
                    let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                    let flag=false
                    let flag2=true
                    list[0].forEach((i)=>{
                        if(save){
                            if(qian===i){
                                flag=true
                            }
                           
                        }else{
                            if(qian===i){
                                flag2=false
                            }
                            
                        }
                        
                    })
                    if(flag||(!save&&flag2))
                    return item
                })
            }
            if(list[1].length!==0){//百位选的号码
                this.all=this.all.filter((item)=>{
                    
                    let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                    let flag=false
                    let flag2=true
                    list[1].forEach((i)=>{
                        if(save){
                            if(bai===i){
                                flag=true
                            }
                           
                        }else{
                            if(bai===i){
                                flag2=false
                            }
                            
                        }
                        
                    })
                    if(flag||(!save&&flag2))
                    return item
                })

            }
            if(list[2].length!==0){//十位选的号码
                this.all=this.all.filter((item)=>{
                    
                    let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                    let flag=false
                    let flag2=true
                    list[2].forEach((i)=>{
                        if(save){
                            if(shi===i){
                                flag=true
                            }
                           
                        }else{
                            if(shi===i){
                                flag2=false
                            }
                            
                        }
                        
                    })
                    if(flag||(!save&&flag2))
                    return item
                })

            }
            if(list[3].length!==0){//个位选的号码
                this.all=this.all.filter((item)=>{
                    let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                    let flag=false
                    let flag2=true
                    list[3].forEach((i)=>{
                        if(save){
                            if(ge===i){
                                flag=true
                            }
                           
                        }else{
                            if(ge===i){
                                flag2=false
                            }
                            
                        }
                        
                    })
                    if(flag||(!save&&flag2))
                    return item
                })

            }
        }
        if(type===2){//乘号位置 条件并集
            
            list.forEach((n)=>{
                if(n==='qian'){
                    this.all=this.all.filter((item)=>{
                        let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                        let flag=false
                        let flag2=true
                    if(save){
                        if(qian==='X')
                        flag=true
                    }else{
                        if(qian==='X')
                        flag2=false
                    }
                    if(flag||(!save&&flag2))
                        return item
                    })
                }
                if(n==='bai'){
                    this.all=this.all.filter((item)=>{
                    let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                        let flag=false
                        let flag2=true
                    if(save){
                        if(bai==='X')
                        flag=true
                    }else{
                        if(bai==='X')
                        flag2=false
                    }
                    if(flag||(!save&&flag2))
                        return item
                    })
                }
                if(n==='shi'){
                    
                    this.all=this.all.filter((item)=>{
                        let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                        let flag=false
                        let flag2=true
                    if(save){
                        if(shi==='X')
                        flag=true
                    }else{
                        if(shi==='X')
                        flag2=false
                    }
                    if(flag||(!save&&flag2))
                        return item
                    })
                    
                }
                if(n==='ge'){
                    this.all=this.all.filter((item)=>{
                        let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                        let flag=false
                        let flag2=true
                    if(save){
                        if(ge==='X')
                        flag=true
                    }else{
                        if(ge==='X')
                        flag2=false
                    }
                    if(flag||(!save&&flag2))
                        return item
                    })
                }
                

            })
            
               
        }
        
        
        // console.log(this.all)
        return this.all
    },
    // 排除码（死数） 已经符合6种玩法
    deadnumber(list){
        this.recover() //复原大底
        this.all=this.all.filter((item)=>{
            let flag=true
            list.forEach((num)=>{
                if(item.includes(num)){
                    flag=false
                }
            })
            if(flag){
                return item
            }
        })
        // console.log(this.all)
        return this.all
    },
    // 合值（合分） 已经符合6种玩法
    hefens(list,save){
        this.recover() //复原大底
        this.all=this.all.filter((item)=>{
                let ge= item.substr(3,1)==='X'||item.substr(3,1)===''?0:parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'||item.substr(2,1)===''?0:parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'||item.substr(1,1)===''?0:parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'||item.substr(0,1)===''?0:parseInt(item.substr(0,1))
                let he=(ge+shi+bai+qian)%10
                // console.log(item,"个十百千位："+ge+shi+bai+qian,"合分为："+he)
                let flag=false
                let flag2=true
                list.forEach((i)=>{
                    if(save){
                        if(he===i){
                        flag=true
                        }
                    }else{
                        if(he===i){
                        flag2=false
                        }
                    }
                   
                })
                if(flag||(!save&&flag2)){
                    return item
                }
               
        })
        // console.log(this.all)
        return this.all
    },
    // 连号（兄弟号）已经符合6种玩法
    consecutivenumbers(list,save){
        this.recover() //复原大底
        this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
           
    
            list.forEach((i)=>{
                if(i==='2'){  //二连号只需满足一个条件即可
                    if(save){
                        if(item.includes('0')&&item.includes('1')||
                            item.includes('1')&&item.includes('2')||
                            item.includes('2')&&item.includes('3')||
                            item.includes('3')&&item.includes('4')||
                            item.includes('4')&&item.includes('5')||
                            item.includes('5')&&item.includes('6')||
                            item.includes('6')&&item.includes('7')||
                            item.includes('7')&&item.includes('8')||
                            item.includes('8')&&item.includes('9')||
                            item.includes('9')&&item.includes('0')
                        )
                        flag=true
                    }else{
                        if(item.includes('0')&&item.includes('1')||
                            item.includes('1')&&item.includes('2')||
                            item.includes('2')&&item.includes('3')||
                            item.includes('3')&&item.includes('4')||
                            item.includes('4')&&item.includes('5')||
                            item.includes('5')&&item.includes('6')||
                            item.includes('6')&&item.includes('7')||
                            item.includes('7')&&item.includes('8')||
                            item.includes('8')&&item.includes('9')||
                            item.includes('9')&&item.includes('0')
                        )
                        flag2=false
                    }
                    
                }
                else if(i==='3'){ //三连号:012/123/234/345/456/567/678/789/890/901只要四位数出现其中三位，不管顺序，不管位置
                if(save){
                    if(item.includes('0')&&item.includes('1')&&item.includes('2')||
                        item.includes('1')&&item.includes('2')&&item.includes('3')||
                        item.includes('2')&&item.includes('3')&&item.includes('4')||
                        item.includes('3')&&item.includes('4')&&item.includes('5')||
                        item.includes('4')&&item.includes('5')&&item.includes('6')||
                        item.includes('5')&&item.includes('6')&&item.includes('7')||
                        item.includes('6')&&item.includes('7')&&item.includes('8')||
                        item.includes('7')&&item.includes('8')&&item.includes('9')||
                        item.includes('8')&&item.includes('9')&&item.includes('0')||
                        item.includes('9')&&item.includes('0')&&item.includes('1')
                    )
                    flag=true  
                }else{
                    if(item.includes('0')&&item.includes('1')&&item.includes('2')||
                        item.includes('1')&&item.includes('2')&&item.includes('3')||
                        item.includes('2')&&item.includes('3')&&item.includes('4')||
                        item.includes('3')&&item.includes('4')&&item.includes('5')||
                        item.includes('4')&&item.includes('5')&&item.includes('6')||
                        item.includes('5')&&item.includes('6')&&item.includes('7')||
                        item.includes('6')&&item.includes('7')&&item.includes('8')||
                        item.includes('7')&&item.includes('8')&&item.includes('9')||
                        item.includes('8')&&item.includes('9')&&item.includes('0')||
                        item.includes('9')&&item.includes('0')&&item.includes('1')
                    )
                    flag2=false  
                } 
                   
                
                   
                }
                else{   //四连号至少需要满足三个条件且个十百千位都不相同
                    if(save){
                        if(item.includes('0')&&item.includes('1')&&item.includes('2')&&item.includes('3')||
                            item.includes('1')&&item.includes('2')&&item.includes('3')&&item.includes('4')||
                            item.includes('2')&&item.includes('3')&&item.includes('4')&&item.includes('5')||
                            item.includes('3')&&item.includes('4')&&item.includes('5')&&item.includes('6')||
                            item.includes('4')&&item.includes('5')&&item.includes('6')&&item.includes('7')||
                            item.includes('5')&&item.includes('6')&&item.includes('7')&&item.includes('8')||
                            item.includes('6')&&item.includes('7')&&item.includes('8')&&item.includes('9')||
                            item.includes('7')&&item.includes('8')&&item.includes('9')&&item.includes('0')||
                            item.includes('8')&&item.includes('9')&&item.includes('0')&&item.includes('1')||
                            item.includes('9')&&item.includes('0')&&item.includes('1')&&item.includes('2')
                        )
                        flag=true
                    }else{
                        if(item.includes('0')&&item.includes('1')&&item.includes('2')&&item.includes('3')||
                            item.includes('1')&&item.includes('2')&&item.includes('3')&&item.includes('4')||
                            item.includes('2')&&item.includes('3')&&item.includes('4')&&item.includes('5')||
                            item.includes('3')&&item.includes('4')&&item.includes('5')&&item.includes('6')||
                            item.includes('4')&&item.includes('5')&&item.includes('6')&&item.includes('7')||
                            item.includes('5')&&item.includes('6')&&item.includes('7')&&item.includes('8')||
                            item.includes('6')&&item.includes('7')&&item.includes('8')&&item.includes('9')||
                            item.includes('7')&&item.includes('8')&&item.includes('9')&&item.includes('0')||
                            item.includes('8')&&item.includes('9')&&item.includes('0')&&item.includes('1')||
                            item.includes('9')&&item.includes('0')&&item.includes('1')&&item.includes('2')
                        )
                        flag2=false
                    }
                       
                    
                }
            })

            if(flag||(!save&&flag2)){
            return item
            }
        })
        // console.log(this.all)
        return this.all
       
    },
    //减式：自定义减法 只有四定才有 减式 符合四定玩法
    reducetype(list,positionfront,positionafter,save){
        this.recover() //复原大底
        this.all=this.all.filter((item)=>{
            let ge=item%10
            let shi=Math.trunc(item/10%10)
            let bai=Math.trunc(item/100%10)
            let qian=Math.trunc(item/1000)
            let before=0
            let after=0
            positionfront.forEach((n)=>{
                if(n==='A')
                before+=qian
                if(n==='B')
                before+=bai
                if(n==='C')
                before+=shi
                if(n==='D')
                before+=ge
            })
            positionafter.forEach((n)=>{
                if(n==='A')
                after+=qian
                if(n==='B')
                after+=bai
                if(n==='C')
                after+=shi
                if(n==='D')
                after+=ge
            })
            let hefen=Math.abs(before-after)%10
            let flag=false
            let flag2=true
            list.forEach((i)=>{
                if(save){
                    if(i===hefen)
                    flag=true
                }else{
                    if(i===hefen)
                    flag2=false
                }
            })
            if(flag||(!save&&flag2)){
                return item
            }
        })
        // console.log(this.all)
        return this.all
    },

    // 按位置选码 只需要满足四 三 二 定即可 
    // 定位置 上面已经定义
    //头尾有数  已经满足3种玩法
    headandtail(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                // console.log(ge,shi,bai,qian)
                
                let flag=false
                let flag2=true
                list.forEach((i)=>{
                    if(save){
                        if(ge===i||qian===i)
                        flag=true
                    }else{
                        if(ge===i||qian===i)
                        flag2=false
                    }
                })
                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },
    //中肚有数  已经满足3种玩法
    middleposition(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1)) 
                let flag=false
                let flag2=true
                list.forEach((i)=>{
                    if(save){
                        if(shi===i||bai===i)
                        flag=true
                    }else{
                        if(shi===i||bai===i)
                        flag2=false
                    }
                })
                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },
    // 有数    已经满足3种玩法
    existnumber(type,list,save,placelist){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                let flag=false
                let flag2=true
                if(type===4){
                    list.forEach((i)=>{
                        if(save){
                            if(item.includes(i))
                            flag=true
                        }else{
                            if(item.includes(i))
                            flag2=false
                        }
                    })
                }
                if(type===3){
                    placelist.forEach((n)=>{
                        if(n==='ABC'){
                            list.forEach((i)=>{
                                if(save){
                                    if(qian===i||bai===i||shi===i)
                                    flag=true
                                }else{
                                    if(qian===i||bai===i||shi===i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='ABD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(qian===i||bai===i||ge===i)
                                    flag=true
                                }else{
                                    if(qian===i||bai===i||ge===i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='ACD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(qian===i||shi===i||ge===i)
                                    flag=true
                                }else{
                                    if(qian===i||shi===i||ge===i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='BCD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(bai===i||shi===i||ge===i)
                                    flag=true
                                }else{
                                    if(bai===i||shi===i||ge===i)
                                    flag2=false
                                }
                            })
                        }
                    })
                }
                if(type===2){
                    placelist.forEach((n)=>{
                        if(n==='AB'){
                            list.forEach((i)=>{
                                if(save){
                                    if(qian==i||bai==i)
                                    flag=true
                                }else{
                                    if(qian==i||bai==i)
                                    flag=true
                                }
                            })
                        }
                        if(n==='AC'){
                            list.forEach((i)=>{
                                if(save){
                                    if(qian==i||shi==i)
                                    flag=true
                                }else{
                                    if(qian==i||shi==i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='AD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(qian==i||ge==i)
                                    flag=true
                                }else{
                                    if(qian==i||ge==i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='BC'){
                            list.forEach((i)=>{
                                if(save){
                                    if(bai==i||shi==i)
                                    flag=true
                                }else{
                                    if(bai==i||shi==i)
                                    flag=true
                                }
                            })
                        }
                        if(n==='BD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(bai==i||ge==i)
                                    flag=true
                                }else{
                                    if(bai==i||ge==i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='CD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(shi==i||ge==i)
                                    flag=true
                                }else{
                                    if(shi==i||ge==i)
                                    flag2=false
                                }
                            })
                        }
                    })
                }
                if(flag||(!save&&flag2)){
                    return item
                }
        })
        // console.log(this.all)
        return this.all
    },
    // 凹凸升降 只需满足 四、三定即可 已经满足2种玩法
    bumplift(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
            let temp
            let flag=false
            let flag2=true
            if(item.includes('X')){
                temp=item.replace('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定
                let ge=temp[3]
                let shi=temp[2]
                let bai=temp[1]
                let qian=temp[0]
                
                list.forEach((n)=>{
                if(n==='ao'){
                    if(save){
                        if(qian>bai&&qian>shi&&ge>bai&&ge>shi)
                        flag=true
                    }else{
                        if(qian>bai&&qian>shi&&ge>bai&&ge>shi)
                        flag2=false
                    }
                }   
                if(n==='tu'){
                    if(save){
                        if(qian<bai&&qian<shi&&ge<bai&&ge<shi)
                        flag=true
                    }else{
                        if(qian<bai&&qian<shi&&ge<bai&&ge<shi)
                        flag2=false
                    }
                }
                if(n==='up'){
                    if(save){
                        if(qian<bai&&bai<shi&&shi<ge)
                        flag=true
                    }else{
                        if(qian<bai&&bai<shi&&shi<ge)
                        flag2=false
                    }
                }
                if(n==='down'){
                    if(save){
                        if(qian>bai&&bai>shi&&shi>ge)
                        flag=true
                    }else{
                        if(qian>bai&&bai>shi&&shi>ge)
                        flag2=false
                    }
                }
                if(n==='level'){
                    if(save){
                        if(qian===bai&&bai===shi&&shi===ge)
                        flag=true
                    }else{
                        if(qian===bai&&bai===shi&&shi===ge)
                        flag2=false
                    }
                }
                })
            }
            if(temp.length===3){//三定
                let ge=temp[2]
                let shi=temp[1]
                let bai=temp[0]
                
                
                list.forEach((n)=>{
                if(n==='ao'){
                    if(save){
                        if(bai>shi&&ge>shi)
                        flag=true
                    }else{
                        if(bai>shi&&ge>shi)
                        flag2=false
                    }
                }   
                if(n==='tu'){
                    if(save){
                        if(bai<shi&&ge<shi)
                        flag=true
                    }else{
                        if(bai<shi&&ge<shi)
                        flag2=false
                    }
                }
                if(n==='up'){
                    if(save){
                        if(bai<shi&&shi<ge)
                        flag=true
                    }else{
                        if(bai<shi&&shi<ge)
                        flag2=false
                    }
                }
                if(n==='down'){
                    if(save){
                        if(bai>shi&&shi>ge)
                        flag=true
                    }else{
                        if(bai>shi&&shi>ge)
                        flag2=false
                    }
                }
                if(n==='level'){
                    if(save){
                        if(bai===shi&&shi===ge)
                        flag=true
                    }else{
                        if(bai===shi&&shi===ge)
                        flag2=false
                    }
                }
                })
            }
          
            if(flag||!save&&flag2){
                return item

            }
            
                
            
        })
            
        // console.log(this.all)
        return this.all
    },

    // 合值筛选
    //两数合 只需满足四 三 二定即可 已经满足3种玩法
    doubleresultant(type,list,save,placelist){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let ge= item[3]==='X'?'X':parseInt(item[3])
                let shi=item[2]==='X'?'X':parseInt(item[2])
                let bai=item[1]==='X'?'X':parseInt(item[1])
                let qian=item[0]==='X'?'X':parseInt(item[0])
                let ab=(qian+bai)%10
                let ac=(qian+shi)%10
                let ad=(qian+ge)%10
                let bc=(bai+shi)%10
                let bd=(bai+ge)%10
                let cd=(shi+ge)%10
                let flag=false
                let flag2=true
                if(type===1){//定位置
                    placelist.forEach((n)=>{
                        if(n==='AB'){
                            list.forEach((i)=>{
                                if(save){
                                    if(ab===i)
                                    flag=true
                                }else{
                                    if(ab===i)
                                    flag2=false
                                }
                                
                            })
                        }
                        if(n==='AC'){
                            list.forEach((i)=>{
                                if(save){
                                    if(ac===i)
                                    flag=true
                                }else{
                                    if(ac===i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='AD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(ad===i)
                                    flag=true
                                }else{
                                    if(ad===i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='BC'){
                            list.forEach((i)=>{
                                if(save){
                                    if(bc===i)
                                    flag=true
                                }else{
                                    if(bc===i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='BD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(bd===i)
                                    flag=true
                                }else{
                                    if(bd===i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='CD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(cd===i)
                                    flag=true
                                }else{
                                    if(cd===i)
                                    flag2=false
                                }
                            })
                        }
                    })
                }
                if(type===0){//不定位置
                    
                    list.forEach((i)=>{
                        if(save){
                            if(ab===i||ac===i||ad===i||bc===i||bd===i||cd===i)
                            flag=true
                        }else{
                            if(ab===i||ac===i||ad===i||bc===i||bd===i||cd===i)
                            flag2=false
                        }
                    })
                }
                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },
    // 三数合 只需满足四 三 定即可 已经满足2种玩法
    threeresultant(type,list,save,placelist){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let ge= item[3]==='X'?'X':parseInt(item[3])
                let shi=item[2]==='X'?'X':parseInt(item[2])
                let bai=item[1]==='X'?'X':parseInt(item[1])
                let qian=item[0]==='X'?'X':parseInt(item[0])
                let abc=qian==='X'||bai==='X'||shi==='X'?11:(qian+bai+shi)%10
                let abd=qian==='X'||bai==='X'||ge==='X'?11:(qian+bai+ge)%10
                let acd=qian==='X'||shi==='X'||ge==='X'?11:(qian+shi+ge)%10
                let bcd=bai==='X'||shi==='X'||ge==='X'?11:(bai+shi+ge)%10
                
                let flag=false
                let flag2=true

                if(type===1){//定位置
                    placelist.forEach((n)=>{
                        if(n==='ABC'){
                            list.forEach((i)=>{
                                if(save){
                                    if(abc===i)
                                    flag=true
                                }else{
                                    if(abc===i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='ABD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(abd===i)
                                    flag=true
                                }else{
                                    if(abd===i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='ACD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(acd===i)
                                    flag=true
                                }else{
                                    if(acd===i)
                                    flag2=false
                                }
                            })
                        }
                        if(n==='BCD'){
                            list.forEach((i)=>{
                                if(save){
                                    if(bcd===i)
                                    flag=true
                                }else{
                                    if(bcd===i)
                                    flag2=false
                                }
                            })
                        }
                        
                    })
                }
                if(type===0){//不定位置
                    
                    list.forEach((i)=>{
                        if(save){
                            if(abc===i||abd===i||acd===i||bcd===i)
                            flag=true
                        }else{
                            if(abc===i||abd===i||acd===i||bcd===i)
                            flag2=false
                        }
                    })
                }
                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },
    // 合分上面已经定义

   
    // 单双（奇偶） 条件交集
    singledouble(type,list,save){
        this.recover()//复原大底
        if(type===1){  //按位快选 条件交集 只需满足四 三 二定即可 已经满足3种玩法
            // console.log('按位快选')
                list.forEach((n)=>{
                    if(n.name==='qian'){
                        this.all=this.all.filter((item)=>{
                        let qian=item[0]==='X'?'X':parseInt(item[0])
                        let flag=true
                        if(save){
                            if(this.dan(qian,n.code)){
                                return item
                            }
                            
                        }else{
                            if(this.dan(qian,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                      
                    }
                    if(n.name==='bai'){
                        this.all=this.all.filter((item)=>{
                        let bai=item[1]==='X'?'X':parseInt(item[1])
                        let flag=true
                        if(save){
                            if(this.dan(bai,n.code))
                            return item
                        }else{
                            if(this.dan(bai,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                        
                    }
                    if(n.name==='shi'){
                        this.all=this.all.filter((item)=>{
                        let shi=item[2]==='X'?'X':parseInt(item[2])
                        let flag=true
                        if(save){
                            if(this.dan(shi,n.code))
                            return item
                        }else{
                            if(this.dan(shi,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                       
                    }
                    if(n.name==='ge'){
                        this.all=this.all.filter((item)=>{
                        let ge=item[3]==='X'?'X':parseInt(item[3])
                        let flag=true
                        if(save){
                            if(this.dan(ge,n.code))
                            return item
                        }else{
                            if(this.dan(ge,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                        
                    }
                })
                
            }
        if(type===2){  //按位任选 条件并集 需满足四 三 二定  已经满足3种玩法
            this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定
                let ge= parseInt(item.substr(3,1))
                let shi=parseInt(item.substr(2,1))
                let bai=parseInt(item.substr(1,1))
                let qian=parseInt(item.substr(0,1))
                list.forEach((n)=>{//aaaa aaab aaba aabb abaa abab abba abbb  bbbb bbba bbab bbaa babb baba baab baaa a为单 b为双
                if(n==='aaaa'){
                    if(save){
                        if(this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,1))
                        flag=true
                    }else{
                        if(this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,1))
                        flag2=false
                    }
                    
                }
                if(n==='aaab'){
                    if(save){
                        if(this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,0))
                        flag=true
                    }else{
                        if(this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,0))
                        flag2=false
                    }
                }
                if(n==='aaba'){
                    if(save){
                        if(this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,1))
                        flag=true
                    }else{
                        if(this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,1))
                        flag2=false
                    }
                }
                if(n==='aabb'){
                    if(save){
                        if(this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,0))
                        flag=true
                    }else{
                        if(this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,0))
                        flag2=false
                    }
                }
                if(n==='abaa'){
                    if(save){
                        if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,1))
                        flag=true
                    }else{
                        if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,1))
                        flag2=false
                    }
                }
                if(n==='abab'){
                    if(save){
                        if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,0))
                        flag=true
                    }else{
                        if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,0))
                        flag2=false
                    }
                }
                if(n==='abba'){
                    if(save){
                        if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,1))
                        flag=true
                    }else{
                        if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,1))
                        flag2=false
                    }
                }
                if(n==='abbb'){
                    if(save){
                        if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,0))
                        flag=true
                    }else{
                        if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,0))
                        flag2=false
                    }
                }
                if(n==='bbbb'){
                    if(save){
                        if(this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,0))
                        flag=true
                    }else{
                        if(this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,0))
                        flag2=false
                    }
                }
                if(n==='bbba'){
                    if(save){
                        if(this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,1))
                        flag=true
                    }else{
                        if(this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,1))
                        flag2=false
                    }
                }
                if(n==='bbab'){
                    if(save){
                        if(this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,0))
                        flag=true
                    }else{
                        if(this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,0))
                        flag2=false
                    }
                }
                if(n==='bbaa'){
                    if(save){
                        if(this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,1))
                        flag=true
                    }else{
                        if(this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,1))
                        flag2=false
                    }
                }
                if(n==='babb'){
                    if(save){
                        if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,0))
                        flag=true
                    }else{
                        if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,0))
                        flag2=false
                    }
                }
                if(n==='baba'){
                    if(save){
                        if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,1))
                        flag=true
                    }else{
                        if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,1))
                        flag2=false
                    }
                }
                if(n==='baab'){
                    if(save){
                        if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,0))
                        flag=true
                    }else{
                        if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,0))
                        flag2=false
                    }
                }
                if(n==='baaa'){
                    if(save){
                        if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,1))
                        flag=true
                    }else{
                        if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,1))
                        flag2=false   
                    }
                }
               
                
            })
            
            }
            if(temp.length===3){//三定
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                list.forEach((n)=>{// a为单 b为双
                    if(n==='aaaX'){
                        if(save){
                            if(this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aaXa'){
                        if(save){
                            if(this.dan(qian,1)&&this.dan(bai,1)&&shi==='X'&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&this.dan(bai,1)&&shi==='X'&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXaa'){
                        if(save){
                            if(this.dan(qian,1)&&bai==='X'&&this.dan(shi,1)&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&bai==='X'&&this.dan(shi,1)&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xaaa'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='abbX'){
                        if(save){
                            if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXb'){
                        if(save){
                            if(this.dan(qian,1)&&this.dan(bai,0)&&shi==='X'&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&this.dan(bai,0)&&shi==='X'&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXbb'){
                        if(save){
                            if(this.dan(qian,1)&&bai==='X'&&this.dan(shi,0)&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&bai==='X'&&this.dan(shi,0)&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xabb'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='abaX'){
                        if(save){
                            if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&this.dan(bai,0)&&this.dan(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXa'){
                        if(save){
                            if(this.dan(qian,1)&&this.dan(bai,0)&&shi==='X'&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&this.dan(bai,0)&&shi==='X'&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXba'){
                        if(save){
                            if(this.dan(qian,1)&&bai==='X'&&this.dan(shi,0)&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&bai==='X'&&this.dan(shi,0)&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xaba'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,1)&&this.dan(shi,0)&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='bbbX'){
                        if(save){
                            if(this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bbXb'){
                        if(save){
                            if(this.dan(qian,0)&&this.dan(bai,0)&&shi==='X'&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&this.dan(bai,0)&&shi==='X'&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXbb'){
                        if(save){
                            if(this.dan(qian,0)&&bai==='X'&&this.dan(shi,0)&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&bai==='X'&&this.dan(shi,0)&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xbbb'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='baaX'){
                        if(save){
                            if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXa'){
                        if(save){
                            if(this.dan(qian,0)&&this.dan(bai,1)&&shi==='X'&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&this.dan(bai,1)&&shi==='X'&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXaa'){
                        if(save){
                            if(this.dan(qian,0)&&bai==='X'&&this.dan(shi,1)&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&bai==='X'&&this.dan(shi,1)&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xbaa'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='babX'){
                        if(save){
                            if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&this.dan(bai,1)&&this.dan(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXb'){
                        if(save){
                            if(this.dan(qian,0)&&this.dan(bai,1)&&shi==='X'&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&this.dan(bai,1)&&shi==='X'&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXab'){
                        if(save){
                            if(this.dan(qian,0)&&bai==='X'&&this.dan(shi,1)&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&bai==='X'&&this.dan(shi,1)&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xbab'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,0)&&this.dan(shi,1)&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                })
            }
            if(temp.length===2){//二定 
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                list.forEach((n)=>{// a为单 b为双
                    if(n==='aaXX'){
                        if(save){
                            if(this.dan(qian,1)&&this.dan(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&this.dan(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXXa'){
                        if(save){
                            if(this.dan(qian,1)&&bai==='X'&&shi==='X'&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&bai==='X'&&shi==='X'&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXaa'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dan(shi,1)&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dan(shi,1)&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaXa'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,1)&&shi==='X'&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,1)&&shi==='X'&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaaX'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,1)&&this.dan(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,1)&&this.dan(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXaX'){
                        if(save){
                            if(this.dan(qian,1)&&bai==='X'&&this.dan(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&bai==='X'&&this.dan(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXX'){
                        if(save){
                            if(this.dan(qian,1)&&this.dan(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&this.dan(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXXb'){
                        if(save){
                            if(this.dan(qian,1)&&bai==='X'&&shi==='X'&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&bai==='X'&&shi==='X'&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXab'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dan(shi,1)&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dan(shi,1)&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaXb'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,1)&&shi==='X'&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,1)&&shi==='X'&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XabX'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,1)&&this.dan(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,1)&&this.dan(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXbX'){
                        if(save){
                            if(this.dan(qian,1)&&bai==='X'&&this.dan(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,1)&&bai==='X'&&this.dan(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bbXX'){
                        if(save){
                            if(this.dan(qian,0)&&this.dan(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&this.dan(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXb'){
                        if(save){
                            if(this.dan(qian,0)&&bai==='X'&&shi==='X'&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&bai==='X'&&shi==='X'&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXbb'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dan(shi,0)&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dan(shi,0)&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXb'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,0)&&shi==='X'&&this.dan(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,0)&&shi==='X'&&this.dan(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbbX'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,0)&&this.dan(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,0)&&this.dan(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXbX'){
                        if(save){
                            if(this.dan(qian,0)&&bai==='X'&&this.dan(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&bai==='X'&&this.dan(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXX'){
                        if(save){
                            if(this.dan(qian,0)&&this.dan(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&this.dan(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXa'){
                        if(save){

                            if(this.dan(qian,0)&&bai==='X'&&shi==='X'&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&bai==='X'&&shi==='X'&&this.dan(ge,1))

                            flag2=false
                        }
                        
                    }
                    if(n==='XXba'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dan(shi,0)&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dan(shi,0)&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXa'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,0)&&shi==='X'&&this.dan(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,0)&&shi==='X'&&this.dan(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbaX'){
                        if(save){
                            if(qian==='X'&&this.dan(bai,0)&&this.dan(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dan(bai,0)&&this.dan(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXaX'){
                        if(save){
                            if(this.dan(qian,0)&&bai==='X'&&this.dan(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dan(qian,0)&&bai==='X'&&this.dan(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                })

            }
            
            if(flag||(!save&&flag2))
            return item

            })
        }
        if(type===3){  //出现比例 条件并集 需要满足 6种情况 已经满足6种玩法
            this.all=this.all.filter((item)=>{
                let flag=false
                let flag2=true
                let temp
                if(item.includes('X')){
                    temp=item.replaceAll('X','')
                    
                }else{
                    temp=item
                }
                if(temp.length===4){//四定 四现
                    let dan=0
                    let shuang=0
                    for(let i=0;i<temp.length;i++){
                        if(this.dan(parseInt(temp[i]),1)){
                            dan++
                        }
                        else{
                            shuang++
                        }
                        
                    }
                    list.forEach((n)=>{
                        if(n==='4:0'){//单：双 a:b
                            if(save){
                                if(dan===4&&shuang===0)
                                flag=true
                            }else{
                                if((dan===4&&shuang===0))
                                flag2=false
                            }
                        }
                        if(n==='0:4'){//单：双 a:b
                            if(save){
                                if(dan===0&&shuang===4)
                                flag=true
                            }else{
                                if((dan===0&&shuang===4))
                                flag2=false
                            }
                        }
                        if(n==='3:1'){//单：双 a:b
                            if(save){
                                if(dan===3&&shuang===1)
                                flag=true
                            }else{
                                if((dan===3&&shuang===1))
                                flag2=false
                            }
                        }
                        if(n==='1:3'){//单：双 a:b
                            if(save){
                                if(dan===1&&shuang===3)
                                flag=true
                            }else{
                                if((dan===1&&shuang===3))
                                flag2=false
                            }
                        }
                        if(n==='2:2'){//单：双 a:b
                            if(save){
                                if(dan===2&&shuang===2)
                                flag=true
                            }else{
                                if((dan===2&&shuang===2))
                                flag2=false
                            }
                        }
                      
                    
                    })
                    
                }
                if(temp.length===3){//三定 三现
                    let dan=0
                    let shuang=0
                    for(let i=0;i<temp.length;i++){
                        if(this.dan(parseInt(temp[i]),1)){
                            dan++
                        }
                        else{
                            shuang++
                        }
                        
                    }
                    list.forEach((n)=>{
                        if(n==='3:0'){//单：双 a:b
                            if(save){
                                if(dan===3&&shuang===0)
                                flag=true
                            }else{
                                if((dan===3&&shuang===0))
                                flag2=false
                            }
                        }
                        if(n==='0:3'){//单：双 a:b
                            if(save){
                                if(dan===0&&shuang===3)
                                flag=true
                            }else{
                                if((dan===0&&shuang===3))
                                flag2=false
                            }
                        }
                        if(n==='2:1'){//单：双 a:b
                            if(save){
                                if(dan===2&&shuang===1)
                                flag=true
                            }else{
                                if((dan===2&&shuang===1))
                                flag2=false
                            }
                        }
                        if(n==='1:2'){//单：双 a:b
                            if(save){
                                if(dan===1&&shuang===2)
                                flag=true
                            }else{
                                if((dan===1&&shuang===2))
                                flag2=false
                            }
                        }
                      
                    
                    })
                    
                }
                if(temp.length===2){//二定 二现
                    let dan=0
                    let shuang=0
                    for(let i=0;i<temp.length;i++){
                        if(this.dan(parseInt(temp[i]),1)){
                            dan++
                        }
                        else{
                            shuang++
                        }
                        
                    }
                    list.forEach((n)=>{
                        if(n==='2:0'){//单：双 a:b
                            if(save){
                                if(dan===2&&shuang===0)
                                flag=true
                            }else{
                                if((dan===2&&shuang===0))
                                flag2=false
                            }
                        }
                        if(n==='0:2'){//单：双 a:b
                            if(save){
                                if(dan===0&&shuang===2)
                                flag=true
                            }else{
                                if((dan===0&&shuang===2))
                                flag2=false
                            }
                        }
                        if(n==='1:1'){//单：双 a:b
                            if(save){
                                if(dan===1&&shuang===1)
                                flag=true
                            }else{
                                if((dan===1&&shuang===1))
                                flag2=false
                            }
                        }
                       
                      
                    
                    })
                }
                
                if(flag||(!save&&flag2))
                return item
            })
        }
        if(type===4){  //断组 需要满足6种情况 已经满足6种玩法 够不够选还需考虑
            this.all=this.all.filter((item)=>{
                let flag=true
                let temp
                if(item.includes('X')){
                    temp=item.replaceAll('X','')
                    
                }else{
                    temp=item
                }
                if(temp.length===4){//四定 四现
                    let ge= parseInt(temp.substr(3,1))
                    let shi=parseInt(temp.substr(2,1))
                    let bai=parseInt(temp.substr(1,1))
                    let qian=parseInt(temp.substr(0,1))
                    if(save){
                    if(
                    this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,1)||
                    this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,0)
                    )
                    return item
                    }else{
                        if(
                        this.dan(qian,1)&&this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,1)||
                        this.dan(qian,0)&&this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,0)
                        )
                        flag=false
                    }
                }
                if(temp.length===3){//三定 三现
                    let ge=parseInt(temp.substr(2,1))
                    let shi=parseInt(temp.substr(1,1))
                    let bai=parseInt(temp.substr(0,1))
                    if(save){
                    if(
                    this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,1)||
                    this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,0)
                    )
                    return item
                    }else{
                        if(
                        this.dan(bai,1)&&this.dan(shi,1)&&this.dan(ge,1)||
                        this.dan(bai,0)&&this.dan(shi,0)&&this.dan(ge,0)
                        )
                        flag=false
                    }
                }
                if(temp.length===2){//二定 二现
                    let ge=parseInt(temp.substr(1,1))
                    let shi=parseInt(temp.substr(0,1))
                    if(save){
                    if(
                    this.dan(shi,1)&&this.dan(ge,1)||
                    this.dan(shi,0)&&this.dan(ge,0)
                    )
                    return item
                    }else{
                        if(
                        this.dan(shi,1)&&this.dan(ge,1)||
                        this.dan(shi,0)&&this.dan(ge,0)
                        )
                        flag=false
                    }
                }
                
                
                if(flag&&!save)
                return item
            })
        }
        // console.log(this.all)
        return this.all
      

    },
    //大小  条件交集
    bigsmall(type,list,save){
        this.recover()//复原大底
        if(type===1){  //按位快选 条件交集 只需满足四 三 二定即可 已经满足3种玩法
            // console.log('按位快选')
                list.forEach((n)=>{
                    if(n.name==='qian'){
                        this.all=this.all.filter((item)=>{
                        let qian=item[0]==='X'?'X':parseInt(item[0])
                        let flag=true
                        if(save){
                            if(this.da(qian,n.code)){
                                return item
                            }
                            
                        }else{
                            if(this.da(qian,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                      
                    }
                    if(n.name==='bai'){
                        this.all=this.all.filter((item)=>{
                        let bai=item[1]==='X'?'X':parseInt(item[1])
                        let flag=true
                        if(save){
                            if(this.da(bai,n.code))
                            return item
                        }else{
                            if(this.da(bai,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                        
                    }
                    if(n.name==='shi'){
                        this.all=this.all.filter((item)=>{
                        let shi=item[2]==='X'?'X':parseInt(item[2])
                        let flag=true
                        if(save){
                            if(this.da(shi,n.code))
                            return item
                        }else{
                            if(this.da(shi,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                       
                    }
                    if(n.name==='ge'){
                        this.all=this.all.filter((item)=>{
                        let ge=item[3]==='X'?'X':parseInt(item[3])
                        let flag=true
                        if(save){
                            if(this.da(ge,n.code))
                            return item
                        }else{
                            if(this.da(ge,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                        
                    }
                })
                
            }
        if(type===2){  //按位任选 条件并集 需满足四 三 二定  已经满足3种玩法
            this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定
                let ge= parseInt(item.substr(3,1))
                let shi=parseInt(item.substr(2,1))
                let bai=parseInt(item.substr(1,1))
                let qian=parseInt(item.substr(0,1))
                list.forEach((n)=>{//aaaa aaab aaba aabb abaa abab abba abbb  bbbb bbba bbab bbaa babb baba baab baaa a为大 b为小
                if(n==='aaaa'){
                    if(save){
                        if(this.da(qian,1)&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,1))
                        flag=true
                    }else{
                        if(this.da(qian,1)&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,1))
                        flag2=false
                    }
                    
                }
                if(n==='aaab'){
                    if(save){
                        if(this.da(qian,1)&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,0))
                        flag=true
                    }else{
                        if(this.da(qian,1)&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,0))
                        flag2=false
                    }
                }
                if(n==='aaba'){
                    if(save){
                        if(this.da(qian,1)&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,1))
                        flag=true
                    }else{
                        if(this.da(qian,1)&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,1))
                        flag2=false
                    }
                }
                if(n==='aabb'){
                    if(save){
                        if(this.da(qian,1)&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,0))
                        flag=true
                    }else{
                        if(this.da(qian,1)&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,0))
                        flag2=false
                    }
                }
                if(n==='abaa'){
                    if(save){
                        if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,1))
                        flag=true
                    }else{
                        if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,1))
                        flag2=false
                    }
                }
                if(n==='abab'){
                    if(save){
                        if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,0))
                        flag=true
                    }else{
                        if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,0))
                        flag2=false
                    }
                }
                if(n==='abba'){
                    if(save){
                        if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,1))
                        flag=true
                    }else{
                        if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,1))
                        flag2=false
                    }
                }
                if(n==='abbb'){
                    if(save){
                        if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,0))
                        flag=true
                    }else{
                        if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,0))
                        flag2=false
                    }
                }
                if(n==='bbbb'){
                    if(save){
                        if(this.da(qian,0)&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,0))
                        flag=true
                    }else{
                        if(this.da(qian,0)&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,0))
                        flag2=false
                    }
                }
                if(n==='bbba'){
                    if(save){
                        if(this.da(qian,0)&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,1))
                        flag=true
                    }else{
                        if(this.da(qian,0)&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,1))
                        flag2=false
                    }
                }
                if(n==='bbab'){
                    if(save){
                        if(this.da(qian,0)&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,0))
                        flag=true
                    }else{
                        if(this.da(qian,0)&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,0))
                        flag2=false
                    }
                }
                if(n==='bbaa'){
                    if(save){
                        if(this.da(qian,0)&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,1))
                        flag=true
                    }else{
                        if(this.da(qian,0)&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,1))
                        flag2=false
                    }
                }
                if(n==='babb'){
                    if(save){
                        if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,0))
                        flag=true
                    }else{
                        if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,0))
                        flag2=false
                    }
                }
                if(n==='baba'){
                    if(save){
                        if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,1))
                        flag=true
                    }else{
                        if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,1))
                        flag2=false
                    }
                }
                if(n==='baab'){
                    if(save){
                        if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,0))
                        flag=true
                    }else{
                        if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,0))
                        flag2=false
                    }
                }
                if(n==='baaa'){
                    if(save){
                        if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,1))
                        flag=true
                    }else{
                        if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,1))
                        flag2=false   
                    }
                }
               
                
            })
            
            }
            if(temp.length===3){//三定
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                list.forEach((n)=>{// a为大 b为小
                    if(n==='aaaX'){
                        if(save){
                            if(this.da(qian,1)&&this.da(bai,1)&&this.da(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,1)&&this.da(bai,1)&&this.da(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aaXa'){
                        if(save){
                            if(this.da(qian,1)&&this.da(bai,1)&&shi==='X'&&this.da(ge,1))
                            flag=true
                        }else{
                            if(this.da(qian,1)&&this.da(bai,1)&&shi==='X'&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXaa'){
                        if(save){
                            if(this.da(qian,1)&&bai==='X'&&this.da(shi,1)&&this.da(ge,1))
                            flag=true
                        }else{
                            if(this.da(qian,1)&&bai==='X'&&this.da(shi,1)&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xaaa'){
                        if(save){
                            if(qian==='X'&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='abbX'){
                        if(save){
                            if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXb'){
                        if(save){
                            if(this.da(qian,1)&&this.da(bai,0)&&shi==='X'&&this.da(ge,0))
                            flag=true
                        }else{
                            if(this.da(qian,1)&&this.da(bai,0)&&shi==='X'&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXbb'){
                        if(save){
                            if(this.da(qian,1)&&bai==='X'&&this.da(shi,0)&&this.da(ge,0))
                            flag=true
                        }else{
                            if(this.da(qian,1)&&bai==='X'&&this.da(shi,0)&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xabb'){
                        if(save){
                            if(qian==='X'&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='abaX'){
                        if(save){
                            if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,1)&&this.da(bai,0)&&this.da(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXa'){
                        if(save){
                            if(this.da(qian,1)&&this.da(bai,0)&&shi==='X'&&this.da(ge,1))
                            flag=true
                        }else{
                            if(this.da(qian,1)&&this.da(bai,0)&&shi==='X'&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXba'){
                        if(save){
                            if(this.da(qian,1)&&bai==='X'&&this.da(shi,0)&&this.da(ge,1))
                            flag=true
                        }else{
                            if(this.da(qian,1)&&bai==='X'&&this.da(shi,0)&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xaba'){
                        if(save){
                            if(qian==='X'&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,1)&&this.da(shi,0)&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='bbbX'){
                        if(save){
                            if(this.da(qian,0)&&this.da(bai,0)&&this.da(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,0)&&this.da(bai,0)&&this.da(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bbXb'){
                        if(save){
                            if(this.da(qian,0)&&this.da(bai,0)&&shi==='X'&&this.da(ge,0))
                            flag=true
                        }else{
                            if(this.da(qian,0)&&this.da(bai,0)&&shi==='X'&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXbb'){
                        if(save){
                            if(this.da(qian,0)&&bai==='X'&&this.da(shi,0)&&this.da(ge,0))
                            flag=true
                        }else{
                            if(this.da(qian,0)&&bai==='X'&&this.da(shi,0)&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xbbb'){
                        if(save){
                            if(qian==='X'&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='baaX'){
                        if(save){
                            if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXa'){
                        if(save){
                            if(this.da(qian,0)&&this.da(bai,1)&&shi==='X'&&this.da(ge,1))
                            flag=true
                        }else{
                            if(this.da(qian,0)&&this.da(bai,1)&&shi==='X'&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXaa'){
                        if(save){
                            if(this.da(qian,0)&&bai==='X'&&this.da(shi,1)&&this.da(ge,1))
                            flag=true
                        }else{
                            if(this.da(qian,0)&&bai==='X'&&this.da(shi,1)&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xbaa'){
                        if(save){
                            if(qian==='X'&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='babX'){
                        if(save){
                            if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,0)&&this.da(bai,1)&&this.da(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXb'){
                        if(save){
                            if(this.da(qian,0)&&this.da(bai,1)&&shi==='X'&&this.da(ge,0))
                            flag=true
                        }else{
                            if(this.da(qian,0)&&this.da(bai,1)&&shi==='X'&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXab'){
                        if(save){
                            if(this.da(qian,0)&&bai==='X'&&this.da(shi,1)&&this.da(ge,0))
                            flag=true
                        }else{
                            if(this.da(qian,0)&&bai==='X'&&this.da(shi,1)&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xbab'){
                        if(save){
                            if(qian==='X'&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,0)&&this.da(shi,1)&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                })
            }
            if(temp.length===2){//二定 
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                list.forEach((n)=>{// a为大 b为小
                    if(n==='aaXX'){
                        if(save){
                            if(this.da(qian,1)&&this.da(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,1)&&this.da(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXXa'){
                        if(save){
                            if(this.da(qian,1)&&bai==='X'&&shi==='X'&&this.da(ge,1))
                            flag=true
                        }else{
                            if(this.da(qian,1)&&bai==='X'&&shi==='X'&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXaa'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.da(shi,1)&&this.da(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.da(shi,1)&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaXa'){
                        if(save){
                            if(qian==='X'&&this.da(bai,1)&&shi==='X'&&this.da(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,1)&&shi==='X'&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaaX'){
                        if(save){
                            if(qian==='X'&&this.da(bai,1)&&this.da(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,1)&&this.da(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXaX'){
                        if(save){
                            if(this.da(qian,1)&&bai==='X'&&this.da(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,1)&&bai==='X'&&this.da(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXX'){
                        if(save){
                            if(this.da(qian,1)&&this.da(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,1)&&this.da(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXXb'){
                        if(save){
                            if(this.da(qian,1)&&bai==='X'&&shi==='X'&&this.da(ge,0))
                            flag=true
                        }else{
                            if(this.da(qian,1)&&bai==='X'&&shi==='X'&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXab'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.da(shi,1)&&this.da(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.da(shi,1)&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaXb'){
                        if(save){
                            if(qian==='X'&&this.da(bai,1)&&shi==='X'&&this.da(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,1)&&shi==='X'&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XabX'){
                        if(save){
                            if(qian==='X'&&this.da(bai,1)&&this.da(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,1)&&this.da(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXbX'){
                        if(save){
                            if(this.da(qian,1)&&bai==='X'&&this.da(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,1)&&bai==='X'&&this.da(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bbXX'){
                        if(save){
                            if(this.da(qian,0)&&this.da(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,0)&&this.da(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXb'){
                        if(save){
                            if(this.da(qian,0)&&bai==='X'&&shi==='X'&&this.da(ge,0))
                            flag=true
                        }else{
                            if(this.da(qian,0)&&bai==='X'&&shi==='X'&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXbb'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.da(shi,0)&&this.da(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.da(shi,0)&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXb'){
                        if(save){
                            if(qian==='X'&&this.da(bai,0)&&shi==='X'&&this.da(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,0)&&shi==='X'&&this.da(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbbX'){
                        if(save){
                            if(qian==='X'&&this.da(bai,0)&&this.da(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,0)&&this.da(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXbX'){
                        if(save){
                            if(this.da(qian,0)&&bai==='X'&&this.da(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,0)&&bai==='X'&&this.da(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXX'){
                        if(save){
                            if(this.da(qian,0)&&this.da(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,0)&&this.da(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXa'){
                        if(save){
                            if(this.da(qian,0)&&bai==='X'&&shi==='X'&&this.da(ge,1))
                            flag=true
                        }else{
                            if(this.da(qian,0)&&bai==='X'&&shi==='X'&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXba'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.da(shi,0)&&this.da(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.da(shi,0)&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXa'){
                        if(save){
                            if(qian==='X'&&this.da(bai,0)&&shi==='X'&&this.da(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,0)&&shi==='X'&&this.da(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbaX'){
                        if(save){
                            if(qian==='X'&&this.da(bai,0)&&this.da(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.da(bai,0)&&this.da(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXaX'){
                        if(save){
                            if(this.da(qian,0)&&bai==='X'&&this.da(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.da(qian,0)&&bai==='X'&&this.da(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                })

            }
            
            if(flag||(!save&&flag2))
            return item

            })
        }
        if(type===3){  //出现比例 条件并集 需要满足 6种情况 已经满足6种玩法
            this.all=this.all.filter((item)=>{
                let flag=false
                let flag2=true
                let temp
                if(item.includes('X')){
                    temp=item.replaceAll('X','')
                    
                }else{
                    temp=item
                }
                if(temp.length===4){//四定 四现
                    let da=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.da(parseInt(temp[i]),1)){
                            da++
                        }
                        else{
                            xiao++
                        }
                        
                    }
                    // console.log(da,xiao,temp,item)
                    list.forEach((n)=>{
                        if(n==='4:0'){//单：双 a:b
                            if(save){
                                if(da===4&&xiao===0)
                                flag=true
                            }else{
                                if((da===4&&xiao===0))
                                flag2=false
                            }
                        }
                        if(n==='0:4'){//单：双 a:b
                            if(save){
                                if(da===0&&xiao===4)
                                flag=true
                            }else{
                                if((da===0&&xiao===4))
                                flag2=false
                            }
                        }
                        if(n==='3:1'){//单：双 a:b
                            if(save){
                                if(da===3&&xiao===1)
                                flag=true
                            }else{
                                if((da===3&&xiao===1))
                                flag2=false
                            }
                        }
                        if(n==='1:3'){//单：双 a:b
                            if(save){
                                if(da===1&&xiao===3)
                                flag=true
                            }else{
                                if((da===1&&xiao===3))
                                flag2=false
                            }
                        }
                        if(n==='2:2'){//单：双 a:b
                            if(save){
                                if(da===2&&xiao===2)
                                flag=true
                            }else{
                                if((da===2&&xiao===2))
                                flag2=false
                            }
                        }
                      
                    
                    })
                    
                }
                if(temp.length===3){//三定 三现
                    let da=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.da(parseInt(temp[i]),1)){
                            da++
                        }
                        else{
                            xiao++
                        }
                        
                    }
                    // console.log(da,xiao,temp,item)

                    list.forEach((n)=>{
                        if(n==='3:0'){//单：双 a:b
                            if(save){
                                if(da===3&&xiao===0)
                                flag=true
                            }else{
                                if((da===3&&xiao===0))
                                flag2=false
                            }
                        }
                        if(n==='0:3'){//单：双 a:b
                            if(save){
                                if(da===0&&xiao===3)
                                flag=true
                            }else{
                                if((da===0&&xiao===3))
                                flag2=false
                            }
                        }
                        if(n==='2:1'){//单：双 a:b
                            if(save){
                                if(da===2&&xiao===1)
                                flag=true
                            }else{
                                if((da===2&&xiao===1))
                                flag2=false
                            }
                        }
                        if(n==='1:2'){//单：双 a:b
                            if(save){
                                if(da===1&&xiao===2)
                                flag=true
                            }else{
                                if((da===1&&xiao===2))
                                flag2=false
                            }
                        }
                      
                    
                    })
                    
                }
                if(temp.length===2){//二定 二现
                    let da=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.da(parseInt(temp[i]),1)){
                            da++
                        }
                        else{
                            xiao++
                        }
                        
                    }
                    // console.log(da,xiao,temp,item)

                    list.forEach((n)=>{
                        if(n==='2:0'){//单：双 a:b
                            if(save){
                                if(da===2&&xiao===0)
                                flag=true
                            }else{
                                if((da===2&&xiao===0))
                                flag2=false
                            }
                        }
                        if(n==='0:2'){//单：双 a:b
                            if(save){
                                if(da===0&&xiao===2)
                                flag=true
                            }else{
                                if((da===0&&xiao===2))
                                flag2=false
                            }
                        }
                        if(n==='1:1'){//单：双 a:b
                            if(save){
                                if(da===1&&xiao===1)
                                flag=true
                            }else{
                                if((da===1&&xiao===1))
                                flag2=false
                            }
                        }
                       
                      
                    
                    })
                }
                
                if(flag||(!save&&flag2))
                return item
            })
        }
        if(type===4){  //断组 需要满足6种情况 已经满足6种玩法
            this.all=this.all.filter((item)=>{
                let flag=true
                let temp
                if(item.includes('X')){
                    temp=item.replaceAll('X','')
                    
                }else{
                    temp=item
                }
                if(temp.length===4){//四定 四现
                    let ge= parseInt(temp.substr(3,1))
                    let shi=parseInt(temp.substr(2,1))
                    let bai=parseInt(temp.substr(1,1))
                    let qian=parseInt(temp.substr(0,1))
                    if(save){
                    if(
                    this.da(qian,1)&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,1)||
                    this.da(qian,0)&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,0)
                    )
                    return item
                    }else{
                        if(
                        this.da(qian,1)&&this.da(bai,1)&&this.da(shi,1)&&this.da(ge,1)||
                        this.da(qian,0)&&this.da(bai,0)&&this.da(shi,0)&&this.da(ge,0)
                        )
                        flag=false
                    }
                }
                if(temp.length===3){//三定 三现
                    let ge=parseInt(temp.substr(2,1))
                    let shi=parseInt(temp.substr(1,1))
                    let bai=parseInt(temp.substr(0,1))
                    if(save){
                    if(
                    this.da(bai,1)&&this.da(shi,1)&&this.da(ge,1)||
                    this.da(bai,0)&&this.da(shi,0)&&this.da(ge,0)
                    )
                    return item
                    }else{
                        if(
                        this.da(bai,1)&&this.da(shi,1)&&this.da(ge,1)||
                        this.da(bai,0)&&this.da(shi,0)&&this.da(ge,0)
                        )
                        flag=false
                    }
                }
                if(temp.length===2){//二定 二现
                    let ge=parseInt(temp.substr(1,1))
                    let shi=parseInt(temp.substr(0,1))
                    if(save){
                    if(
                    this.da(shi,1)&&this.da(ge,1)||
                    this.da(shi,0)&&this.da(ge,0)
                    )
                    return item
                    }else{
                        if(
                        this.da(shi,1)&&this.da(ge,1)||
                        this.da(shi,0)&&this.da(ge,0)
                        )
                        flag=false
                    }
                }
                
                
                if(flag&&!save)
                return item
            })
        }
        // console.log(this.all)
        return this.all
    },
    //质合  条件交集
    qualitysum(type,list,save){
        this.recover()//复原大底
        if(type===1){  //按位快选 条件交集 只需满足四 三 二定即可 已经满足3种玩法
            // console.log('按位快选')
                list.forEach((n)=>{
                    if(n.name==='qian'){
                        this.all=this.all.filter((item)=>{
                        let qian=item[0]==='X'?'X':parseInt(item[0])
                        let flag=true
                        if(save){
                            if(this.zhi(qian,n.code)){
                                return item
                            }
                            
                        }else{
                            if(this.zhi(qian,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                      
                    }
                    if(n.name==='bai'){
                        this.all=this.all.filter((item)=>{
                        let bai=item[1]==='X'?'X':parseInt(item[1])
                        let flag=true
                        if(save){
                            if(this.zhi(bai,n.code))
                            return item
                        }else{
                            if(this.zhi(bai,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                        
                    }
                    if(n.name==='shi'){
                        this.all=this.all.filter((item)=>{
                        let shi=item[2]==='X'?'X':parseInt(item[2])
                        let flag=true
                        if(save){
                            if(this.zhi(shi,n.code))
                            return item
                        }else{
                            if(this.zhi(shi,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                       
                    }
                    if(n.name==='ge'){
                        this.all=this.all.filter((item)=>{
                        let ge=item[3]==='X'?'X':parseInt(item[3])
                        let flag=true
                        if(save){
                            if(this.zhi(ge,n.code))
                            return item
                        }else{
                            if(this.zhi(ge,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                        
                    }
                })
                
            }
        if(type===2){  //按位任选 条件并集 需满足四 三 二定  已经满足3种玩法
            this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定
                let ge= parseInt(item.substr(3,1))
                let shi=parseInt(item.substr(2,1))
                let bai=parseInt(item.substr(1,1))
                let qian=parseInt(item.substr(0,1))
                list.forEach((n)=>{//aaaa aaab aaba aabb abaa abab abba abbb  bbbb bbba bbab bbaa babb baba baab baaa a为大 b为小
                if(n==='aaaa'){
                    if(save){
                        if(this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,1))
                        flag=true
                    }else{
                        if(this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,1))
                        flag2=false
                    }
                    
                }
                if(n==='aaab'){
                    if(save){
                        if(this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,0))
                        flag=true
                    }else{
                        if(this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,0))
                        flag2=false
                    }
                }
                if(n==='aaba'){
                    if(save){
                        if(this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,1))
                        flag=true
                    }else{
                        if(this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,1))
                        flag2=false
                    }
                }
                if(n==='aabb'){
                    if(save){
                        if(this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,0))
                        flag=true
                    }else{
                        if(this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,0))
                        flag2=false
                    }
                }
                if(n==='abaa'){
                    if(save){
                        if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,1))
                        flag=true
                    }else{
                        if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,1))
                        flag2=false
                    }
                }
                if(n==='abab'){
                    if(save){
                        if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,0))
                        flag=true
                    }else{
                        if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,0))
                        flag2=false
                    }
                }
                if(n==='abba'){
                    if(save){
                        if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,1))
                        flag=true
                    }else{
                        if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,1))
                        flag2=false
                    }
                }
                if(n==='abbb'){
                    if(save){
                        if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,0))
                        flag=true
                    }else{
                        if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,0))
                        flag2=false
                    }
                }
                if(n==='bbbb'){
                    if(save){
                        if(this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,0))
                        flag=true
                    }else{
                        if(this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,0))
                        flag2=false
                    }
                }
                if(n==='bbba'){
                    if(save){
                        if(this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,1))
                        flag=true
                    }else{
                        if(this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,1))
                        flag2=false
                    }
                }
                if(n==='bbab'){
                    if(save){
                        if(this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,0))
                        flag=true
                    }else{
                        if(this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,0))
                        flag2=false
                    }
                }
                if(n==='bbaa'){
                    if(save){
                        if(this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,1))
                        flag=true
                    }else{
                        if(this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,1))
                        flag2=false
                    }
                }
                if(n==='babb'){
                    if(save){
                        if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,0))
                        flag=true
                    }else{
                        if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,0))
                        flag2=false
                    }
                }
                if(n==='baba'){
                    if(save){
                        if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,1))
                        flag=true
                    }else{
                        if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,1))
                        flag2=false
                    }
                }
                if(n==='baab'){
                    if(save){
                        if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,0))
                        flag=true
                    }else{
                        if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,0))
                        flag2=false
                    }
                }
                if(n==='baaa'){
                    if(save){
                        if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,1))
                        flag=true
                    }else{
                        if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,1))
                        flag2=false   
                    }
                }
               
                
            })
            
            }
            if(temp.length===3){//三定
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                list.forEach((n)=>{// a为大 b为小
                    if(n==='aaaX'){
                        if(save){
                            if(this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aaXa'){
                        if(save){
                            if(this.zhi(qian,1)&&this.zhi(bai,1)&&shi==='X'&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&this.zhi(bai,1)&&shi==='X'&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXaa'){
                        if(save){
                            if(this.zhi(qian,1)&&bai==='X'&&this.zhi(shi,1)&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&bai==='X'&&this.zhi(shi,1)&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xaaa'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='abbX'){
                        if(save){
                            if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXb'){
                        if(save){
                            if(this.zhi(qian,1)&&this.zhi(bai,0)&&shi==='X'&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&this.zhi(bai,0)&&shi==='X'&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXbb'){
                        if(save){
                            if(this.zhi(qian,1)&&bai==='X'&&this.zhi(shi,0)&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&bai==='X'&&this.zhi(shi,0)&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xabb'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='abaX'){
                        if(save){
                            if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&this.zhi(bai,0)&&this.zhi(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXa'){
                        if(save){
                            if(this.zhi(qian,1)&&this.zhi(bai,0)&&shi==='X'&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&this.zhi(bai,0)&&shi==='X'&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXba'){
                        if(save){
                            if(this.zhi(qian,1)&&bai==='X'&&this.zhi(shi,0)&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&bai==='X'&&this.zhi(shi,0)&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xaba'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,1)&&this.zhi(shi,0)&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='bbbX'){
                        if(save){
                            if(this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bbXb'){
                        if(save){
                            if(this.zhi(qian,0)&&this.zhi(bai,0)&&shi==='X'&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&this.zhi(bai,0)&&shi==='X'&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXbb'){
                        if(save){
                            if(this.zhi(qian,0)&&bai==='X'&&this.zhi(shi,0)&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&bai==='X'&&this.zhi(shi,0)&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xbbb'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='baaX'){
                        if(save){
                            if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXa'){
                        if(save){
                            if(this.zhi(qian,0)&&this.zhi(bai,1)&&shi==='X'&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&this.zhi(bai,1)&&shi==='X'&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXaa'){
                        if(save){
                            if(this.zhi(qian,0)&&bai==='X'&&this.zhi(shi,1)&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&bai==='X'&&this.zhi(shi,1)&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xbaa'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='babX'){
                        if(save){
                            if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&this.zhi(bai,1)&&this.zhi(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXb'){
                        if(save){
                            if(this.zhi(qian,0)&&this.zhi(bai,1)&&shi==='X'&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&this.zhi(bai,1)&&shi==='X'&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXab'){
                        if(save){
                            if(this.zhi(qian,0)&&bai==='X'&&this.zhi(shi,1)&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&bai==='X'&&this.zhi(shi,1)&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xbab'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,0)&&this.zhi(shi,1)&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                })
            }
            if(temp.length===2){//二定 
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                list.forEach((n)=>{// a为大 b为小
                    if(n==='aaXX'){
                        if(save){
                            if(this.zhi(qian,1)&&this.zhi(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&this.zhi(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXXa'){
                        if(save){
                            if(this.zhi(qian,1)&&bai==='X'&&shi==='X'&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&bai==='X'&&shi==='X'&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXaa'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.zhi(shi,1)&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.zhi(shi,1)&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaXa'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,1)&&shi==='X'&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,1)&&shi==='X'&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaaX'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,1)&&this.zhi(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,1)&&this.zhi(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXaX'){
                        if(save){
                            if(this.zhi(qian,1)&&bai==='X'&&this.zhi(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&bai==='X'&&this.zhi(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXX'){
                        if(save){
                            if(this.zhi(qian,1)&&this.zhi(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&this.zhi(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXXb'){
                        if(save){
                            if(this.zhi(qian,1)&&bai==='X'&&shi==='X'&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&bai==='X'&&shi==='X'&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXab'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.zhi(shi,1)&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.zhi(shi,1)&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaXb'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,1)&&shi==='X'&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,1)&&shi==='X'&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XabX'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,1)&&this.zhi(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,1)&&this.zhi(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXbX'){
                        if(save){
                            if(this.zhi(qian,1)&&bai==='X'&&this.zhi(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,1)&&bai==='X'&&this.zhi(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bbXX'){
                        if(save){
                            if(this.zhi(qian,0)&&this.zhi(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&this.zhi(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXb'){
                        if(save){
                            if(this.zhi(qian,0)&&bai==='X'&&shi==='X'&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&bai==='X'&&shi==='X'&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXbb'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.zhi(shi,0)&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.zhi(shi,0)&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXb'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,0)&&shi==='X'&&this.zhi(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,0)&&shi==='X'&&this.zhi(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbbX'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,0)&&this.zhi(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,0)&&this.zhi(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXbX'){
                        if(save){
                            if(this.zhi(qian,0)&&bai==='X'&&this.zhi(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&bai==='X'&&this.zhi(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXX'){
                        if(save){
                            if(this.zhi(qian,0)&&this.zhi(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&this.zhi(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXa'){
                        if(save){
                            if(this.zhi(qian,0)&&bai==='X'&&shi==='X'&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&bai==='X'&&shi==='X'&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXba'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.zhi(shi,0)&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.zhi(shi,0)&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXa'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,0)&&shi==='X'&&this.zhi(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,0)&&shi==='X'&&this.zhi(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbaX'){
                        if(save){
                            if(qian==='X'&&this.zhi(bai,0)&&this.zhi(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.zhi(bai,0)&&this.zhi(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXaX'){
                        if(save){
                            if(this.zhi(qian,0)&&bai==='X'&&this.zhi(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.zhi(qian,0)&&bai==='X'&&this.zhi(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                })

            }
            
            if(flag||(!save&&flag2))
            return item

            })
        }
        if(type===3){  //出现比例 条件并集 需要满足 6种情况 已经满足6种玩法
            this.all=this.all.filter((item)=>{
                let flag=false
                let flag2=true
                let temp
                if(item.includes('X')){
                    temp=item.replaceAll('X','')
                    
                }else{
                    temp=item
                }
                if(temp.length===4){//四定 四现
                    let zhi=0
                    let he=0
                    for(let i=0;i<temp.length;i++){
                        if(this.zhi(parseInt(temp[i]),1)){
                            zhi++
                        }
                        else{
                            he++
                        }
                        
                    }
                    // console.log(zhi,he,temp,item)
                    list.forEach((n)=>{
                        if(n==='4:0'){//单：双 a:b
                            if(save){
                                if(zhi===4&&he===0)
                                flag=true
                            }else{
                                if((zhi===4&&he===0))
                                flag2=false
                            }
                        }
                        if(n==='0:4'){//单：双 a:b
                            if(save){
                                if(zhi===0&&he===4)
                                flag=true
                            }else{
                                if((zhi===0&&he===4))
                                flag2=false
                            }
                        }
                        if(n==='3:1'){//单：双 a:b
                            if(save){
                                if(zhi===3&&he===1)
                                flag=true
                            }else{
                                if((zhi===3&&he===1))
                                flag2=false
                            }
                        }
                        if(n==='1:3'){//单：双 a:b
                            if(save){
                                if(zhi===1&&he===3)
                                flag=true
                            }else{
                                if((zhi===1&&he===3))
                                flag2=false
                            }
                        }
                        if(n==='2:2'){//单：双 a:b
                            if(save){
                                if(zhi===2&&he===2)
                                flag=true
                            }else{
                                if((zhi===2&&he===2))
                                flag2=false
                            }
                        }
                      
                    
                    })
                    
                }
                if(temp.length===3){//三定 三现
                    let zhi=0
                    let he=0
                    for(let i=0;i<temp.length;i++){
                        if(this.zhi(parseInt(temp[i]),1)){
                            zhi++
                        }
                        else{
                            he++
                        }
                        
                    }
                    // console.log(zhi,he,temp,item)

                    list.forEach((n)=>{
                        if(n==='3:0'){//单：双 a:b
                            if(save){
                                if(zhi===3&&he===0)
                                flag=true
                            }else{
                                if((zhi===3&&he===0))
                                flag2=false
                            }
                        }
                        if(n==='0:3'){//单：双 a:b
                            if(save){
                                if(zhi===0&&he===3)
                                flag=true
                            }else{
                                if((zhi===0&&he===3))
                                flag2=false
                            }
                        }
                        if(n==='2:1'){//单：双 a:b
                            if(save){
                                if(zhi===2&&he===1)
                                flag=true
                            }else{
                                if((zhi===2&&he===1))
                                flag2=false
                            }
                        }
                        if(n==='1:2'){//单：双 a:b
                            if(save){
                                if(zhi===1&&he===2)
                                flag=true
                            }else{
                                if((zhi===1&&he===2))
                                flag2=false
                            }
                        }
                      
                    
                    })
                    
                }
                if(temp.length===2){//二定 二现
                    let zhi=0
                    let he=0
                    for(let i=0;i<temp.length;i++){
                        if(this.zhi(parseInt(temp[i]),1)){
                            zhi++
                        }
                        else{
                            he++
                        }
                        
                    }
                    // console.log(zhi,he,temp,item)

                    list.forEach((n)=>{
                        if(n==='2:0'){//单：双 a:b
                            if(save){
                                if(zhi===2&&he===0)
                                flag=true
                            }else{
                                if((zhi===2&&he===0))
                                flag2=false
                            }
                        }
                        if(n==='0:2'){//单：双 a:b
                            if(save){
                                if(zhi===0&&he===2)
                                flag=true
                            }else{
                                if((zhi===0&&he===2))
                                flag2=false
                            }
                        }
                        if(n==='1:1'){//单：双 a:b
                            if(save){
                                if(zhi===1&&he===1)
                                flag=true
                            }else{
                                if((zhi===1&&he===1))
                                flag2=false
                            }
                        }
                       
                      
                    
                    })
                }
                
                if(flag||(!save&&flag2))
                return item
            })
        }
        if(type===4){  //断组 需要满足6种情况 已经满足6种玩法
            this.all=this.all.filter((item)=>{
                let flag=true
                let temp
                if(item.includes('X')){
                    temp=item.replaceAll('X','')
                    
                }else{
                    temp=item
                }
                if(temp.length===4){//四定 四现
                    let ge= parseInt(temp.substr(3,1))
                    let shi=parseInt(temp.substr(2,1))
                    let bai=parseInt(temp.substr(1,1))
                    let qian=parseInt(temp.substr(0,1))
                    if(save){
                    if(
                    this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,1)||
                    this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,0)
                    )
                    return item
                    }else{
                        if(
                        this.zhi(qian,1)&&this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,1)||
                        this.zhi(qian,0)&&this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,0)
                        )
                        flag=false
                    }
                }
                if(temp.length===3){//三定 三现
                    let ge=parseInt(temp.substr(2,1))
                    let shi=parseInt(temp.substr(1,1))
                    let bai=parseInt(temp.substr(0,1))
                    if(save){
                    if(
                    this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,1)||
                    this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,0)
                    )
                    return item
                    }else{
                        if(
                        this.zhi(bai,1)&&this.zhi(shi,1)&&this.zhi(ge,1)||
                        this.zhi(bai,0)&&this.zhi(shi,0)&&this.zhi(ge,0)
                        )
                        flag=false
                    }
                }
                if(temp.length===2){//二定 二现
                    let ge=parseInt(temp.substr(1,1))
                    let shi=parseInt(temp.substr(0,1))
                    if(save){
                    if(
                    this.zhi(shi,1)&&this.zhi(ge,1)||
                    this.zhi(shi,0)&&this.zhi(ge,0)
                    )
                    return item
                    }else{
                        if(
                        this.zhi(shi,1)&&this.zhi(ge,1)||
                        this.zhi(shi,0)&&this.zhi(ge,0)
                        )
                        flag=false
                    }
                }
                
                
                if(flag&&!save)
                return item
            })
        }
        // console.log(this.all)
        return this.all
    },
    //大中小  条件交集
    bigmediumsmall(type,list,save){
        this.recover()//复原大底
        if(type===1){  //按位快选 条件交集 只需满足四 三 二定即可 已经满足3种玩法
            // console.log('按位快选')
                list.forEach((n)=>{
                    if(n.name==='qian'){
                        this.all=this.all.filter((item)=>{
                        let qian=item[0]==='X'?'X':parseInt(item[0])
                        let flag=true
                        if(save){
                            if(this.dazhongxiao(qian,n.code)){
                                return item
                            }
                            
                        }else{
                            if(this.dazhongxiao(qian,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                      
                    }
                    if(n.name==='bai'){
                        this.all=this.all.filter((item)=>{
                        let bai=item[1]==='X'?'X':parseInt(item[1])
                        let flag=true
                        if(save){
                            if(this.dazhongxiao(bai,n.code))
                            return item
                        }else{
                            if(this.dazhongxiao(bai,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                        
                    }
                    if(n.name==='shi'){
                        this.all=this.all.filter((item)=>{
                        let shi=item[2]==='X'?'X':parseInt(item[2])
                        let flag=true
                        if(save){
                            if(this.dazhongxiao(shi,n.code))
                            return item
                        }else{
                            if(this.dazhongxiao(shi,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                       
                    }
                    if(n.name==='ge'){
                        this.all=this.all.filter((item)=>{
                        let ge=item[3]==='X'?'X':parseInt(item[3])
                        let flag=true
                        if(save){
                            if(this.dazhongxiao(ge,n.code))
                            return item
                        }else{
                            if(this.dazhongxiao(ge,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                        
                    }
                })
                
            }
        if(type===2){  //按位任选 条件并集 需满足四 三 二定  已经满足3种玩法
            this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定
                let ge= parseInt(item.substr(3,1))
                let shi=parseInt(item.substr(2,1))
                let bai=parseInt(item.substr(1,1))
                let qian=parseInt(item.substr(0,1))
                list.forEach((n)=>{//aaaa aaab aaac ...一种27*3=81种 a b c 分别代表大 中 小（主要围绕千位为大2 中1 小0 ）
                    //千位为大（2）
                    if(n==='aaaa'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='aaab'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aaac'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='aaba'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='aabb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aabc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='aaca'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='aacb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aacc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='abaa'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='abab'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='abac'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='abba'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='abbb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='abbc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='abca'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='abcb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='abcc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='acaa'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='acab'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='acac'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='acba'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='acbb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='acbc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='acca'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='accb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='accc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    
                //    千位为中
                    if(n==='baaa'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='baab'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='baac'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='baba'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='babb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='babc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='baca'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bacb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bacc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bbaa'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bbab'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bbac'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bbba'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bbbb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bbbc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bbca'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bbcb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bbcc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bcaa'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bcab'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bcac'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bcba'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bcbb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bcbc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bcca'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bccb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bccc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                //    千位为小
                    if(n==='caaa'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='caab'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='caac'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='caba'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cabb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cabc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='caca'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cacb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cacc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cbaa'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cbab'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cbac'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cbba'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cbbb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cbbc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cbca'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cbcb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cbcc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='ccaa'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='ccab'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='ccac'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='ccba'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='ccbb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='ccbc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='ccca'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cccb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cccc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    
                })
            
            }
            if(temp.length===3){//三定
                
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                list.forEach((n)=>{//108种 a b c 分别代表大 中 小（主要围绕千位为大2 中1 小0 ）

                    //X在千位
                    if(n==='Xaaa'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xaab'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xaac'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xaba'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xabb'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xabc'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xaca'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xacb'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xacc'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xbaa'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xbab'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xbac'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xbba'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xbbb'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xbbc'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xbca'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xbcb'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xbcc'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xcaa'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xcab'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xcac'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xcba'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xcbb'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xcbc'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xcca'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xccb'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xccc'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }

                    //X在百位
                    if(n==='aXaa'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXab'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aXac'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='aXba'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='aXbb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aXbc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='aXca'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='aXcb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aXcc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bXaa'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXab'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bXac'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bXba'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bXbb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bXbc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bXca'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bXcb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bXcc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cXaa'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='cXab'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cXac'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cXba'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cXbb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cXbc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cXca'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cXcb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cXcc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    //X在十位
                    if(n==='aaXa'){ //
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='aaXb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aaXc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='abXa'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='abXb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='abXc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='acXa'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='acXb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='acXc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='baXa'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='baXb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='baXc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bbXa'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bbXb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bbXc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bcXa'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bcXb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bcXc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='caXa'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='caXb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='caXc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cbXa'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cbXb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cbXc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='ccXa'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='ccXb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='ccXc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                    }

                    //X在个位
                    if(n==='aaaX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aabX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='aacX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='abaX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='abbX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='abcX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='acaX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='acbX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='accX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='baaX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='babX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bacX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bbaX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bbbX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bbcX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bcaX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bcbX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bccX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='caaX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='cabX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='cacX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='cbaX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='cbbX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='cbcX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='ccaX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='ccbX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='cccX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }

                   
                    
                })
            
            }
            if(temp.length===2){//二定  
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                list.forEach((n)=>{// a为大 b为中 c位小 54种
                    //XX在千百位
                    if(n==='XXaa'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXab'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXac'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,2)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXba'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXbb'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXbc'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,1)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXca'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXcb'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXcc'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.dazhongxiao(shi,0)&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                        
                    }
                    //XX在千十位
                    if(n==='XaXa'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaXb'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaXc'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXa'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXb'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXc'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XcXa'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XcXb'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XcXc'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                        
                    }
                    //XX在千个位
                    if(n==='XaaX'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XabX'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XacX'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,2)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XbaX'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XbbX'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XbcX'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,1)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XcaX'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XcbX'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XccX'){
                        if(save){
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.dazhongxiao(bai,0)&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    //XX在百十位
                    if(n==='aXXa'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXXb'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXXc'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXa'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXb'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXc'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='cXXa'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='cXXb'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='cXXc'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&shi==='X'&&this.dazhongxiao(ge,0))
                            flag2=false
                        }
                        
                    }
                    //XX在百个位
                    if(n==='aXaX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXbX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXcX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&bai==='X'&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXaX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXbX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXcX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&bai==='X'&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='cXaX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='cXbX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='cXcX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&bai==='X'&&this.dazhongxiao(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    //XX在十个位
                    if(n==='aaXX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,2)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='acXX'){
                        if(save){
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,2)&&this.dazhongxiao(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,2)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bbXX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bcXX'){
                        if(save){
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,1)&&this.dazhongxiao(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='caXX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,2)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='cbXX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='ccXX'){
                        if(save){
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.dazhongxiao(qian,0)&&this.dazhongxiao(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    
                   
                })

            }
            
            if(flag||(!save&&flag2))
            return item

            })
        }
        if(type===3){  //出现比例 条件并集 需要满足 6种情况 已经满足6种玩法
            this.all=this.all.filter((item)=>{
                let flag=false
                let flag2=true
                let temp
                if(item.includes('X')){
                    temp=item.replaceAll('X','')
                    
                }else{
                    temp=item
                }
                if(temp.length===4){//四定 四现
                    let da=0
                    let zhong=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.dazhongxiao(parseInt(temp[i]),2)){
                            da++
                        }
                        if(this.dazhongxiao(parseInt(temp[i]),1)){
                            zhong++
                        }
                        if(this.dazhongxiao(parseInt(temp[i]),0)){
                            xiao++
                        }
                        
                        
                    }
                    // console.log(da,zhong,xiao,temp,item)
                    list.forEach((n)=>{ //15
                        if(n==='0:0:4'){
                            if(save){
                                if(da===0&&zhong===0&&xiao===4)
                                flag=true
                            }else{
                                if(da===0&&zhong===0&&xiao===4)
                                flag2=false
                            }
                        }
                        if(n==='0:1:3'){
                            if(save){
                                if(da===0&&zhong===1&&xiao===3)
                                flag=true
                            }else{
                                if(da===0&&zhong===1&&xiao===3)
                                flag2=false
                            }
                        }
                        if(n==='0:2:2'){
                            if(save){
                                if(da===0&&zhong===2&&xiao===2)
                                flag=true
                            }else{
                                if(da===0&&zhong===2&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='0:3:1'){
                            if(save){
                                if(da===0&&zhong===3&&xiao===1)
                                flag=true
                            }else{
                                if(da===0&&zhong===3&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='0:4:0'){
                            if(save){
                                if(da===0&&zhong===4&&xiao===0)
                                flag=true
                            }else{
                                if(da===0&&zhong===4&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='1:0:3'){
                            if(save){
                                if(da===1&&zhong===0&&xiao===3)
                                flag=true
                            }else{
                                if(da===1&&zhong===0&&xiao===3)
                                flag2=false
                            }
                        }
                        if(n==='1:1:2'){
                            if(save){
                                if(da===1&&zhong===1&&xiao===2)
                                flag=true
                            }else{
                                if(da===1&&zhong===1&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='1:2:1'){
                            if(save){
                                if(da===1&&zhong===2&&xiao===1)
                                flag=true
                            }else{
                                if(da===1&&zhong===2&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='1:3:0'){
                            if(save){
                                if(da===1&&zhong===3&&xiao===0)
                                flag=true
                            }else{
                                if(da===1&&zhong===3&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='2:0:2'){
                            if(save){
                                if(da===2&&zhong===0&&xiao===2)
                                flag=true
                            }else{
                                if(da===2&&zhong===0&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='2:1:1'){
                            if(save){
                                if(da===2&&zhong===1&&xiao===1)
                                flag=true
                            }else{
                                if(da===2&&zhong===1&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='2:2:0'){
                            if(save){
                                if(da===2&&zhong===2&&xiao===0)
                                flag=true
                            }else{
                                if(da===2&&zhong===2&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='3:0:1'){
                            if(save){
                                if(da===3&&zhong===0&&xiao===1)
                                flag=true
                            }else{
                                if(da===3&&zhong===0&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='3:1:0'){
                            if(save){
                                if(da===3&&zhong===1&&xiao===0)
                                flag=true
                            }else{
                                if(da===3&&zhong===1&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='4:0:0'){
                            if(save){
                                if(da===4&&zhong===0&&xiao===0)
                                flag=true
                            }else{
                                if(da===4&&zhong===0&&xiao===0)
                                flag2=false
                            }
                        }
                        
                    })
                    
                }
                if(temp.length===3){//三定 三现
                    let da=0
                    let zhong=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.dazhongxiao(parseInt(temp[i]),2)){
                            da++
                        }
                        if(this.dazhongxiao(parseInt(temp[i]),1)){
                            zhong++
                        }
                        if(this.dazhongxiao(parseInt(temp[i]),0)){
                            xiao++
                        }
                        
                        
                    }
                    // console.log(da,zhong,xiao,temp,item)
                    list.forEach((n)=>{ //10种
                        if(n==='0:0:3'){
                            if(save){
                                if(da===0&&zhong===0&&xiao===3)
                                flag=true
                            }else{
                                if(da===0&&zhong===0&&xiao===3)
                                flag2=false
                            }
                        }
                        if(n==='0:1:2'){
                            if(save){
                                if(da===0&&zhong===1&&xiao===2)
                                flag=true
                            }else{
                                if(da===0&&zhong===1&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='0:2:1'){
                            if(save){
                                if(da===0&&zhong===2&&xiao===1)
                                flag=true
                            }else{
                                if(da===0&&zhong===2&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='0:3:0'){
                            if(save){
                                if(da===0&&zhong===3&&xiao===0)
                                flag=true
                            }else{
                                if(da===0&&zhong===3&&xiao===0)
                                flag2=false
                            }
                        }
                        
                        if(n==='1:0:2'){
                            if(save){
                                if(da===1&&zhong===0&&xiao===2)
                                flag=true
                            }else{
                                if(da===1&&zhong===0&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='1:1:1'){
                            if(save){
                                if(da===1&&zhong===1&&xiao===1)
                                flag=true
                            }else{
                                if(da===1&&zhong===1&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='1:2:0'){
                            if(save){
                                if(da===1&&zhong===2&&xiao===0)
                                flag=true
                            }else{
                                if(da===1&&zhong===2&&xiao===0)
                                flag2=false
                            }
                        }
                        
                        if(n==='2:0:1'){
                            if(save){
                                if(da===2&&zhong===0&&xiao===1)
                                flag=true
                            }else{
                                if(da===2&&zhong===0&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='2:1:0'){
                            if(save){
                                if(da===2&&zhong===1&&xiao===0)
                                flag=true
                            }else{
                                if(da===2&&zhong===1&&xiao===0)
                                flag2=false
                            }
                        }
                        
                        if(n==='3:0:0'){
                            if(save){
                                if(da===3&&zhong===0&&xiao===0)
                                flag=true
                            }else{
                                if(da===3&&zhong===0&&xiao===0)
                                flag2=false
                            }
                        }
                       
                        
                    })
                    
                }
                if(temp.length===2){//二定 二现
                    let da=0
                    let zhong=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.dazhongxiao(parseInt(temp[i]),2)){
                            da++
                        }
                        if(this.dazhongxiao(parseInt(temp[i]),1)){
                            zhong++
                        }
                        if(this.dazhongxiao(parseInt(temp[i]),0)){
                            xiao++
                        }
                        
                        
                    }
                    // console.log(da,zhong,xiao,temp,item)
                    list.forEach((n)=>{ //6种
                        if(n==='0:0:2'){
                            if(save){
                                if(da===0&&zhong===0&&xiao===2)
                                flag=true
                            }else{
                                if(da===0&&zhong===0&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='0:1:1'){
                            if(save){
                                if(da===0&&zhong===1&&xiao===1)
                                flag=true
                            }else{
                                if(da===0&&zhong===1&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='0:2:0'){
                            if(save){
                                if(da===0&&zhong===2&&xiao===0)
                                flag=true
                            }else{
                                if(da===0&&zhong===2&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='1:0:1'){
                            if(save){
                                if(da===1&&zhong===0&&xiao===1)
                                flag=true
                            }else{
                                if(da===1&&zhong===0&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='1:1:0'){
                            if(save){
                                if(da===1&&zhong===1&&xiao===0)
                                flag=true
                            }else{
                                if(da===1&&zhong===1&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='2:0:0'){
                            if(save){
                                if(da===2&&zhong===0&&xiao===0)
                                flag=true
                            }else{
                                if(da===2&&zhong===0&&xiao===0)
                                flag2=false
                            }
                        }
                        
                       
                        
                    })
                    
                }
                
                if(flag||(!save&&flag2))
                return item
            })
        }
        if(type===4){  //断组 需要满足4种情况 已经满足4种玩法
            this.all=this.all.filter((item)=>{
                let flag=true
                let temp
                if(item.includes('X')){
                    temp=item.replaceAll('X','')
                    
                }else{
                    temp=item
                }
                if(temp.length===4){//四定 四现
                    let da=0
                    let zhong=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.dazhongxiao(parseInt(temp[i]),2)){
                            da++
                        }
                        if(this.dazhongxiao(parseInt(temp[i]),1)){
                            zhong++
                        }
                        if(this.dazhongxiao(parseInt(temp[i]),0)){
                            xiao++
                        }
                        
                        
                    }
                    // console.log(da,zhong,xiao,temp,item)
                    if(save){
                        if(!(da>0&&zhong>0&&xiao>0))
                        return item
                    }else{
                        if(!(da>0&&zhong>0&&xiao>0))
                        flag=false
                    }
                }
                if(temp.length===3){//三定 三现
                    let da=0
                    let zhong=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.dazhongxiao(parseInt(temp[i]),2)){
                            da++
                        }
                        if(this.dazhongxiao(parseInt(temp[i]),1)){
                            zhong++
                        }
                        if(this.dazhongxiao(parseInt(temp[i]),0)){
                            xiao++
                        }
                        
                        
                    }
                    // console.log(da,zhong,xiao,temp,item)
                    if(save){
                        if(!(da>0&&zhong>0&&xiao>0))
                        return item
                    }else{
                        if(!(da>0&&zhong>0&&xiao>0))
                        flag=false
                    }
                }
                
                
                
                if(flag&&!save)
                return item
            })
        }
        // console.log(this.all)
        return this.all
    },
    // 012路  条件交集
    road012(type,list,save){
        this.recover()//复原大底
        if(type===1){  //按位快选 条件交集 只需满足四 三 二定即可 已经满足3种玩法
            // console.log('按位快选')
                list.forEach((n)=>{
                    if(n.name==='qian'){
                        this.all=this.all.filter((item)=>{
                        let qian=item[0]==='X'?'X':parseInt(item[0])
                        let flag=true
                        if(save){
                            if(this.lus(qian,n.code)){
                                return item
                            }
                            
                        }else{
                            if(this.lus(qian,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                      
                    }
                    if(n.name==='bai'){
                        this.all=this.all.filter((item)=>{
                        let bai=item[1]==='X'?'X':parseInt(item[1])
                        let flag=true
                        if(save){
                            if(this.lus(bai,n.code))
                            return item
                        }else{
                            if(this.lus(bai,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                        
                    }
                    if(n.name==='shi'){
                        this.all=this.all.filter((item)=>{
                        let shi=item[2]==='X'?'X':parseInt(item[2])
                        let flag=true
                        if(save){
                            if(this.lus(shi,n.code))
                            return item
                        }else{
                            if(this.lus(shi,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                       
                    }
                    if(n.name==='ge'){
                        this.all=this.all.filter((item)=>{
                        let ge=item[3]==='X'?'X':parseInt(item[3])
                        let flag=true
                        if(save){
                            if(this.lus(ge,n.code))
                            return item
                        }else{
                            if(this.lus(ge,n.code)&&n.code!=='')
                            flag=false
                        }
                        if(!save&&flag)
                            return item
                        })
                        
                    }
                })
                
            }
        if(type===2){  //按位任选 条件并集 需满足四 三 二定  已经满足3种玩法
            this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定
                let ge= parseInt(item.substr(3,1))
                let shi=parseInt(item.substr(2,1))
                let bai=parseInt(item.substr(1,1))
                let qian=parseInt(item.substr(0,1))
                list.forEach((n)=>{//aaaa aaab aaac ...一种27*3=81种 a b c 分别代表大 中 小（主要围绕千位为大2 中1 小0 ）
                    //千位为大（2）
                    if(n==='aaaa'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='aaab'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aaac'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='aaba'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='aabb'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aabc'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='aaca'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='aacb'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aacc'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='abaa'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='abab'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='abac'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='abba'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='abbb'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='abbc'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='abca'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='abcb'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='abcc'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='acaa'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='acab'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='acac'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='acba'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='acbb'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='acbc'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='acca'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='accb'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='accc'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    
                //    千位为中
                    if(n==='baaa'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='baab'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='baac'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='baba'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='babb'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='babc'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='baca'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bacb'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bacc'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bbaa'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bbab'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bbac'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bbba'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bbbb'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bbbc'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bbca'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bbcb'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bbcc'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bcaa'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bcab'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bcac'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bcba'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bcbb'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bcbc'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bcca'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bccb'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bccc'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                //    千位为小
                    if(n==='caaa'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='caab'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='caac'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='caba'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cabb'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cabc'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='caca'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cacb'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cacc'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cbaa'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cbab'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cbac'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cbba'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cbbb'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cbbc'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cbca'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cbcb'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cbcc'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='ccaa'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='ccab'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='ccac'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='ccba'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='ccbb'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='ccbc'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='ccca'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cccb'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cccc'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    
                })
            
            }
            if(temp.length===3){//三定
                
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                list.forEach((n)=>{//108种 a b c 分别代表大 中 小（主要围绕千位为大2 中1 小0 ）

                    //X在千位
                    if(n==='Xaaa'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='Xaab'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xaac'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xaba'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xabb'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xabc'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xaca'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xacb'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xacc'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xbaa'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xbab'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xbac'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xbba'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xbbb'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xbbc'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xbca'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xbcb'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xbcc'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xcaa'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xcab'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xcac'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xcba'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xcbb'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xcbc'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='Xcca'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='Xccb'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='Xccc'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }

                    //X在百位
                    if(n==='aXaa'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXab'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aXac'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='aXba'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='aXbb'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aXbc'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='aXca'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='aXcb'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aXcc'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bXaa'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXab'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bXac'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bXba'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bXbb'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bXbc'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bXca'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bXcb'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bXcc'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cXaa'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='cXab'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cXac'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cXba'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cXbb'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cXbc'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cXca'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cXcb'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cXcc'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    //X在十位
                    if(n==='aaXa'){ //
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='aaXb'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='aaXc'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='abXa'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='abXb'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='abXc'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='acXa'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='acXb'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='acXc'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='baXa'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='baXb'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='baXc'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bbXa'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bbXb'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bbXc'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='bcXa'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='bcXb'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='bcXc'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='caXa'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='caXb'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='caXc'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='cbXa'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='cbXb'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='cbXc'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                    }
                    if(n==='ccXa'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                    }
                    if(n==='ccXb'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                    }
                    if(n==='ccXc'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                    }

                    //X在个位
                    if(n==='aaaX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aabX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='aacX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='abaX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='abbX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='abcX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='acaX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='acbX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='accX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='baaX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='babX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bacX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bbaX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bbbX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bbcX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bcaX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bcbX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='bccX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='caaX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='cabX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='cacX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='cbaX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='cbbX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='cbcX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='ccaX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='ccbX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                    }
                    if(n==='cccX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                    }

                   
                    
                })
            
            }
            if(temp.length===2){//二定  
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                list.forEach((n)=>{// a为大 b为中 c位小 54种
                    //XX在千百位
                    if(n==='XXaa'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXab'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXac'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.lus(shi,2)&&this.lus(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXba'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXbb'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXbc'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.lus(shi,1)&&this.lus(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXca'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXcb'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XXcc'){
                        if(save){
                            if(qian==='X'&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&bai==='X'&&this.lus(shi,0)&&this.lus(ge,0))
                            flag2=false
                        }
                        
                    }
                    //XX在千十位
                    if(n==='XaXa'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaXb'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XaXc'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXa'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXb'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XbXc'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='XcXa'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='XcXb'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='XcXc'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                        
                    }
                    //XX在千个位
                    if(n==='XaaX'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XabX'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XacX'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,2)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XbaX'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XbbX'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XbcX'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,1)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XcaX'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XcbX'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='XccX'){
                        if(save){
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(qian==='X'&&this.lus(bai,0)&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    //XX在百十位
                    if(n==='aXXa'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXXb'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='aXXc'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXa'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXb'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='bXXc'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                        
                    }
                    if(n==='cXXa'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&shi==='X'&&this.lus(ge,2))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&shi==='X'&&this.lus(ge,2))
                            flag2=false
                        }
                        
                    }
                    if(n==='cXXb'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&shi==='X'&&this.lus(ge,1))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&shi==='X'&&this.lus(ge,1))
                            flag2=false
                        }
                        
                    }
                    if(n==='cXXc'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&shi==='X'&&this.lus(ge,0))
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&shi==='X'&&this.lus(ge,0))
                            flag2=false
                        }
                        
                    }
                    //XX在百个位
                    if(n==='aXaX'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXbX'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='aXcX'){
                        if(save){
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&bai==='X'&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXaX'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXbX'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bXcX'){
                        if(save){
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&bai==='X'&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='cXaX'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,2)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,2)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='cXbX'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,1)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,1)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='cXcX'){
                        if(save){
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,0)&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&bai==='X'&&this.lus(shi,0)&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    //XX在十个位
                    if(n==='aaXX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,2)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,2)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='abXX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='acXX'){
                        if(save){
                            if(this.lus(qian,2)&&this.lus(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,2)&&this.lus(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='baXX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,2)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,2)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bbXX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='bcXX'){
                        if(save){
                            if(this.lus(qian,1)&&this.lus(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,1)&&this.lus(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='caXX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,2)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,2)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='cbXX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,1)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,1)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    if(n==='ccXX'){
                        if(save){
                            if(this.lus(qian,0)&&this.lus(bai,0)&&shi==='X'&&ge==='X')
                            flag=true
                        }else{
                            if(this.lus(qian,0)&&this.lus(bai,0)&&shi==='X'&&ge==='X')
                            flag2=false
                        }
                        
                    }
                    
                   
                })

            }
            
            if(flag||(!save&&flag2))
            return item

            })
        }
        if(type===3){  //出现比例 条件并集 需要满足 6种情况 已经满足6种玩法
            this.all=this.all.filter((item)=>{
                let flag=false
                let flag2=true
                let temp
                if(item.includes('X')){
                    temp=item.replaceAll('X','')
                    
                }else{
                    temp=item
                }
                if(temp.length===4){//四定 四现
                    let da=0
                    let zhong=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.lus(parseInt(temp[i]),2)){
                            da++
                        }
                        if(this.lus(parseInt(temp[i]),1)){
                            zhong++
                        }
                        if(this.lus(parseInt(temp[i]),0)){
                            xiao++
                        }
                        
                        
                    }
                    // console.log(da,zhong,xiao,temp,item)
                    list.forEach((n)=>{ //15
                        if(n==='0:0:4'){
                            if(save){
                                if(da===0&&zhong===0&&xiao===4)
                                flag=true
                            }else{
                                if(da===0&&zhong===0&&xiao===4)
                                flag2=false
                            }
                        }
                        if(n==='0:1:3'){
                            if(save){
                                if(da===0&&zhong===1&&xiao===3)
                                flag=true
                            }else{
                                if(da===0&&zhong===1&&xiao===3)
                                flag2=false
                            }
                        }
                        if(n==='0:2:2'){
                            if(save){
                                if(da===0&&zhong===2&&xiao===2)
                                flag=true
                            }else{
                                if(da===0&&zhong===2&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='0:3:1'){
                            if(save){
                                if(da===0&&zhong===3&&xiao===1)
                                flag=true
                            }else{
                                if(da===0&&zhong===3&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='0:4:0'){
                            if(save){
                                if(da===0&&zhong===4&&xiao===0)
                                flag=true
                            }else{
                                if(da===0&&zhong===4&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='1:0:3'){
                            if(save){
                                if(da===1&&zhong===0&&xiao===3)
                                flag=true
                            }else{
                                if(da===1&&zhong===0&&xiao===3)
                                flag2=false
                            }
                        }
                        if(n==='1:1:2'){
                            if(save){
                                if(da===1&&zhong===1&&xiao===2)
                                flag=true
                            }else{
                                if(da===1&&zhong===1&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='1:2:1'){
                            if(save){
                                if(da===1&&zhong===2&&xiao===1)
                                flag=true
                            }else{
                                if(da===1&&zhong===2&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='1:3:0'){
                            if(save){
                                if(da===1&&zhong===3&&xiao===0)
                                flag=true
                            }else{
                                if(da===1&&zhong===3&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='2:0:2'){
                            if(save){
                                if(da===2&&zhong===0&&xiao===2)
                                flag=true
                            }else{
                                if(da===2&&zhong===0&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='2:1:1'){
                            if(save){
                                if(da===2&&zhong===1&&xiao===1)
                                flag=true
                            }else{
                                if(da===2&&zhong===1&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='2:2:0'){
                            if(save){
                                if(da===2&&zhong===2&&xiao===0)
                                flag=true
                            }else{
                                if(da===2&&zhong===2&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='3:0:1'){
                            if(save){
                                if(da===3&&zhong===0&&xiao===1)
                                flag=true
                            }else{
                                if(da===3&&zhong===0&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='3:1:0'){
                            if(save){
                                if(da===3&&zhong===1&&xiao===0)
                                flag=true
                            }else{
                                if(da===3&&zhong===1&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='4:0:0'){
                            if(save){
                                if(da===4&&zhong===0&&xiao===0)
                                flag=true
                            }else{
                                if(da===4&&zhong===0&&xiao===0)
                                flag2=false
                            }
                        }
                        
                    })
                    
                }
                if(temp.length===3){//三定 三现
                    let da=0
                    let zhong=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.lus(parseInt(temp[i]),2)){
                            da++
                        }
                        if(this.lus(parseInt(temp[i]),1)){
                            zhong++
                        }
                        if(this.lus(parseInt(temp[i]),0)){
                            xiao++
                        }
                        
                        
                    }
                    // console.log(da,zhong,xiao,temp,item)
                    list.forEach((n)=>{ //10种
                        if(n==='0:0:3'){
                            if(save){
                                if(da===0&&zhong===0&&xiao===3)
                                flag=true
                            }else{
                                if(da===0&&zhong===0&&xiao===3)
                                flag2=false
                            }
                        }
                        if(n==='0:1:2'){
                            if(save){
                                if(da===0&&zhong===1&&xiao===2)
                                flag=true
                            }else{
                                if(da===0&&zhong===1&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='0:2:1'){
                            if(save){
                                if(da===0&&zhong===2&&xiao===1)
                                flag=true
                            }else{
                                if(da===0&&zhong===2&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='0:3:0'){
                            if(save){
                                if(da===0&&zhong===3&&xiao===0)
                                flag=true
                            }else{
                                if(da===0&&zhong===3&&xiao===0)
                                flag2=false
                            }
                        }
                        
                        if(n==='1:0:2'){
                            if(save){
                                if(da===1&&zhong===0&&xiao===2)
                                flag=true
                            }else{
                                if(da===1&&zhong===0&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='1:1:1'){
                            if(save){
                                if(da===1&&zhong===1&&xiao===1)
                                flag=true
                            }else{
                                if(da===1&&zhong===1&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='1:2:0'){
                            if(save){
                                if(da===1&&zhong===2&&xiao===0)
                                flag=true
                            }else{
                                if(da===1&&zhong===2&&xiao===0)
                                flag2=false
                            }
                        }
                        
                        if(n==='2:0:1'){
                            if(save){
                                if(da===2&&zhong===0&&xiao===1)
                                flag=true
                            }else{
                                if(da===2&&zhong===0&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='2:1:0'){
                            if(save){
                                if(da===2&&zhong===1&&xiao===0)
                                flag=true
                            }else{
                                if(da===2&&zhong===1&&xiao===0)
                                flag2=false
                            }
                        }
                        
                        if(n==='3:0:0'){
                            if(save){
                                if(da===3&&zhong===0&&xiao===0)
                                flag=true
                            }else{
                                if(da===3&&zhong===0&&xiao===0)
                                flag2=false
                            }
                        }
                       
                        
                    })
                    
                }
                if(temp.length===2){//二定 二现
                    let da=0
                    let zhong=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.lus(parseInt(temp[i]),2)){
                            da++
                        }
                        if(this.lus(parseInt(temp[i]),1)){
                            zhong++
                        }
                        if(this.lus(parseInt(temp[i]),0)){
                            xiao++
                        }
                        
                        
                    }
                    // console.log(da,zhong,xiao,temp,item)
                    list.forEach((n)=>{ //6种
                        if(n==='0:0:2'){
                            if(save){
                                if(da===0&&zhong===0&&xiao===2)
                                flag=true
                            }else{
                                if(da===0&&zhong===0&&xiao===2)
                                flag2=false
                            }
                        }
                        if(n==='0:1:1'){
                            if(save){
                                if(da===0&&zhong===1&&xiao===1)
                                flag=true
                            }else{
                                if(da===0&&zhong===1&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='0:2:0'){
                            if(save){
                                if(da===0&&zhong===2&&xiao===0)
                                flag=true
                            }else{
                                if(da===0&&zhong===2&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='1:0:1'){
                            if(save){
                                if(da===1&&zhong===0&&xiao===1)
                                flag=true
                            }else{
                                if(da===1&&zhong===0&&xiao===1)
                                flag2=false
                            }
                        }
                        if(n==='1:1:0'){
                            if(save){
                                if(da===1&&zhong===1&&xiao===0)
                                flag=true
                            }else{
                                if(da===1&&zhong===1&&xiao===0)
                                flag2=false
                            }
                        }
                        if(n==='2:0:0'){
                            if(save){
                                if(da===2&&zhong===0&&xiao===0)
                                flag=true
                            }else{
                                if(da===2&&zhong===0&&xiao===0)
                                flag2=false
                            }
                        }
                        
                       
                        
                    })
                    
                }
                
                if(flag||(!save&&flag2))
                return item
            })
        }
        if(type===4){  //断组 需要满足4种情况 已经满足4种玩法
            this.all=this.all.filter((item)=>{
                let flag=true
                let temp
                if(item.includes('X')){
                    temp=item.replaceAll('X','')
                    
                }else{
                    temp=item
                }
                if(temp.length===4){//四定 四现
                    let da=0
                    let zhong=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.lus(parseInt(temp[i]),2)){
                            da++
                        }
                        if(this.lus(parseInt(temp[i]),1)){
                            zhong++
                        }
                        if(this.lus(parseInt(temp[i]),0)){
                            xiao++
                        }
                        
                        
                    }
                    // console.log(da,zhong,xiao,temp,item)
                    if(save){
                        if(!(da>0&&zhong>0&&xiao>0))
                        return item
                    }else{
                        if(!(da>0&&zhong>0&&xiao>0))
                        flag=false
                    }
                }
                if(temp.length===3){//三定 三现
                    let da=0
                    let zhong=0
                    let xiao=0
                    for(let i=0;i<temp.length;i++){
                        if(this.lus(parseInt(temp[i]),2)){
                            da++
                        }
                        if(this.lus(parseInt(temp[i]),1)){
                            zhong++
                        }
                        if(this.lus(parseInt(temp[i]),0)){
                            xiao++
                        }
                        
                        
                    }
                    // console.log(da,zhong,xiao,temp,item)
                    if(save){
                        if(!(da>0&&zhong>0&&xiao>0))
                        return item
                    }else{
                        if(!(da>0&&zhong>0&&xiao>0))
                        flag=false
                    }
                }
                
                
                
                if(flag&&!save)
                return item
            })
        }
        // console.log(this.all)
        return this.all
    },
    

    // 对数 已经符合6种玩法
    logarithm(list,save){
        this.recover()
        this.all=this.all.filter((item)=>{
                let ge=item%10
                let shi=Math.trunc(item/10%10)
                let bai=Math.trunc(item/100%10)
                let qian=Math.trunc(item/1000)
                let flag=false
                let flag2=true
            list.forEach((n)=>{
                if(n==='1'){//至少包含一组对数 0对5 1对6 2对7 3对8 4对9
                    if(save){
                        if(
                        item.includes('0')&&item.includes('5')||
                        item.includes('1')&&item.includes('6')||
                        item.includes('2')&&item.includes('7')||
                        item.includes('3')&&item.includes('8')||
                        item.includes('4')&&item.includes('9')
                        )
                        flag=true
                    }else{
                        if(
                        item.includes('0')&&item.includes('5')||
                        item.includes('1')&&item.includes('6')||
                        item.includes('2')&&item.includes('7')||
                        item.includes('3')&&item.includes('8')||
                        item.includes('4')&&item.includes('9')
                        )
                        flag2=false
                    }
                }
                if(n==='2'){//至少包含两组对数 0对5=>a 1对6=>b 2对7=>c 3对8=>d 4对9=>e 有十五种组合 aa bb cc dd ee ab ac ad ae bc bd be cd ce de 特别：0055也是两组所以还需下面三十种情况
                    if(save){
                        if(
                        qian===0&&bai===0&&shi===5&&ge===5||qian===0&&bai===5&&shi===0&&ge===5||qian===0&&bai===5&&shi===5&&ge===0||qian===5&&bai===0&&shi===0&&ge===5||qian===5&&bai===0&&shi===5&&ge===0||qian===5&&bai===5&&shi===0&&ge===0||
                        qian===1&&bai===1&&shi===6&&ge===6||qian===1&&bai===6&&shi===1&&ge===6||qian===1&&bai===6&&shi===6&&ge===1||qian===6&&bai===1&&shi===1&&ge===6||qian===6&&bai===1&&shi===6&&ge===1||qian===6&&bai===6&&shi===1&&ge===1||
                        qian===2&&bai===2&&shi===7&&ge===7||qian===2&&bai===7&&shi===2&&ge===7||qian===2&&bai===7&&shi===7&&ge===2||qian===7&&bai===2&&shi===2&&ge===7||qian===7&&bai===2&&shi===7&&ge===2||qian===7&&bai===7&&shi===2&&ge===2||
                        qian===3&&bai===3&&shi===8&&ge===8||qian===3&&bai===8&&shi===3&&ge===8||qian===3&&bai===8&&shi===8&&ge===3||qian===8&&bai===3&&shi===3&&ge===8||qian===8&&bai===3&&shi===8&&ge===3||qian===8&&bai===8&&shi===3&&ge===3||
                        qian===4&&bai===4&&shi===9&&ge===9||qian===4&&bai===9&&shi===4&&ge===9||qian===4&&bai===9&&shi===9&&ge===4||qian===9&&bai===4&&shi===4&&ge===9||qian===9&&bai===4&&shi===9&&ge===4||qian===9&&bai===9&&shi===4&&ge===4||
                        
                        item.includes('0')&&item.includes('5')&&item.includes('1')&&item.includes('6')||
                        item.includes('0')&&item.includes('5')&&item.includes('2')&&item.includes('7')||
                        item.includes('0')&&item.includes('5')&&item.includes('3')&&item.includes('8')||
                        item.includes('0')&&item.includes('5')&&item.includes('4')&&item.includes('9')||
                        item.includes('1')&&item.includes('6')&&item.includes('2')&&item.includes('7')||
                        item.includes('1')&&item.includes('6')&&item.includes('3')&&item.includes('8')||
                        item.includes('1')&&item.includes('6')&&item.includes('4')&&item.includes('9')||
                        item.includes('2')&&item.includes('7')&&item.includes('3')&&item.includes('8')||
                        item.includes('2')&&item.includes('7')&&item.includes('4')&&item.includes('9')||
                        item.includes('3')&&item.includes('8')&&item.includes('4')&&item.includes('9')
                      
                        )
                        flag=true
                    }else{
                        if(
                        qian===0&&bai===0&&shi===5&&ge===5||qian===0&&bai===5&&shi===0&&ge===5||qian===0&&bai===5&&shi===5&&ge===0||qian===5&&bai===0&&shi===0&&ge===5||qian===5&&bai===0&&shi===5&&ge===0||qian===5&&bai===5&&shi===0&&ge===0||
                        qian===1&&bai===1&&shi===6&&ge===6||qian===1&&bai===6&&shi===1&&ge===6||qian===1&&bai===6&&shi===6&&ge===1||qian===6&&bai===1&&shi===1&&ge===6||qian===6&&bai===1&&shi===6&&ge===1||qian===6&&bai===6&&shi===1&&ge===1||
                        qian===2&&bai===2&&shi===7&&ge===7||qian===2&&bai===7&&shi===2&&ge===7||qian===2&&bai===7&&shi===7&&ge===2||qian===7&&bai===2&&shi===2&&ge===7||qian===7&&bai===2&&shi===7&&ge===2||qian===7&&bai===7&&shi===2&&ge===2||
                        qian===3&&bai===3&&shi===8&&ge===8||qian===3&&bai===8&&shi===3&&ge===8||qian===3&&bai===8&&shi===8&&ge===3||qian===8&&bai===3&&shi===3&&ge===8||qian===8&&bai===3&&shi===8&&ge===3||qian===8&&bai===8&&shi===3&&ge===3||
                        qian===4&&bai===4&&shi===9&&ge===9||qian===4&&bai===9&&shi===4&&ge===9||qian===4&&bai===9&&shi===9&&ge===4||qian===9&&bai===4&&shi===4&&ge===9||qian===9&&bai===4&&shi===9&&ge===4||qian===9&&bai===9&&shi===4&&ge===4||
                        
                        item.includes('0')&&item.includes('5')&&item.includes('1')&&item.includes('6')||
                        item.includes('0')&&item.includes('5')&&item.includes('2')&&item.includes('7')||
                        item.includes('0')&&item.includes('5')&&item.includes('3')&&item.includes('8')||
                        item.includes('0')&&item.includes('5')&&item.includes('4')&&item.includes('9')||
                        item.includes('1')&&item.includes('6')&&item.includes('2')&&item.includes('7')||
                        item.includes('1')&&item.includes('6')&&item.includes('3')&&item.includes('8')||
                        item.includes('1')&&item.includes('6')&&item.includes('4')&&item.includes('9')||
                        item.includes('2')&&item.includes('7')&&item.includes('3')&&item.includes('8')||
                        item.includes('2')&&item.includes('7')&&item.includes('4')&&item.includes('9')||
                        item.includes('3')&&item.includes('8')&&item.includes('4')&&item.includes('9')
                      
                        )
                        flag2=false
                    }

                }
            })
            if(flag||(!save&&flag2))
            return item
        })
        // console.log(this.all)
        return this.all
    },


    // 不定位置选号
    // 配号码 已经符合6种玩法 需要根据 四 三 二 来决定有多少个数组 四对应四个数组以此类推
    withnumber(list1,list2,list3,list4){

        let list=[]
        // console.log(list1,list2,list3,list4)
        if(list1.length!==0)
        list.push(list1)
        if(list2.length!==0)
        list.push(list2)
        if(list3.length!==0)
        list.push(list3)
        if(list4.length!==0)
        list.push(list4)
        // console.log(list)
        this.recover() //还原大底
        this.all=this.all.filter((item)=>{
            let sum
            let temp
            let integer=item //全是数字组成，去掉X
            
            if(item.includes('X')){
                integer=item.replaceAll('X','')
                
            }else{
                integer=item
            }
            if(integer.length===4){ //四定、现
                if(list.length===4){ //四组都有数据
                    for(let i=0;i<list[0].length;i++){
                        sum=0
                        temp=integer
                        if(temp.indexOf(list[0][i])!==-1){
                            let temp1=temp.replace(list[0][i],'')
                            sum++
                            for(let i=0;i<list[1].length;i++){
                                sum=1
                                temp=temp1
                                if(temp.indexOf(list[1][i])!==-1){
                                    let temp2=temp.replace(list[1][i],'')
                                    sum++
                                    for(let i=0;i<list[2].length;i++){
                                        sum=2
                                        temp=temp2
                                        if(temp.indexOf(list[2][i])!==-1){
                                            let temp3=temp.replace(list[2][i],'')
                                            sum++
                                            for(let i=0;i<list[3].length;i++){
                                                sum=3
                                                temp=temp3
                                                if(temp.indexOf(list[3][i])!==-1){
                                                    temp=temp.replace(list[3][i],'')
                                                    sum++
                                                }
                                                if(sum===4){
                                                    return item
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } 
                }
                if(list.length===3){ //有三组数据
                    for(let i=0;i<list[0].length;i++){
                        sum=1
                        temp=integer
                        if(temp.indexOf(list[0][i])!==-1){
                            let temp1=temp.replace(list[0][i],'')
                            sum++
                            for(let i=0;i<list[1].length;i++){
                                sum=2
                                temp=temp1
                                if(temp.indexOf(list[1][i])!==-1){
                                    let temp2=temp.replace(list[1][i],'')
                                    sum++
                                    for(let i=0;i<list[2].length;i++){
                                        sum=3
                                        temp=temp2
                                        if(temp.indexOf(list[2][i])!==-1){
                                            sum++
                                            
                                        }
                                        if(sum===4){
                                            return item
                                        }
                                    }
                                }
                            }
                        }
                    } 
                }
                if(list.length===2){ //有两组数据
                    for(let i=0;i<list[0].length;i++){
                        sum=2
                        temp=integer
                        if(temp.indexOf(list[0][i])!==-1){
                            let temp1=temp.replace(list[0][i],'')
                            sum++
                            for(let i=0;i<list[1].length;i++){
                                sum=3
                                temp=temp1
                                if(temp.indexOf(list[1][i])!==-1){
                                    sum++
                                
                                }
                                if(sum===4){
                                    return item
                                }
                            }
                        }
                    } 
                }
                if(list.length===1){ //有一组数据
                    for(let i=0;i<list[0].length;i++){
                        sum=3
                        temp=integer
                        if(temp.indexOf(list[0][i])!==-1){
                            sum++
                            
                        }
                        if(sum===4){
                            return item
                        }
                    } 
                }
            }
            if(integer.length===3){ //三定、现
                if(list.length===3){ //有三组数据
                    for(let i=0;i<list[0].length;i++){
                        sum=1
                        temp=integer
                        if(temp.indexOf(list[0][i])!==-1){
                            let temp1=temp.replace(list[0][i],'')
                            sum++
                            for(let i=0;i<list[1].length;i++){
                                sum=2
                                temp=temp1
                                if(temp.indexOf(list[1][i])!==-1){
                                    let temp2=temp.replace(list[1][i],'')
                                    sum++
                                    for(let i=0;i<list[2].length;i++){
                                        sum=3
                                        temp=temp2
                                        if(temp.indexOf(list[2][i])!==-1){
                                            sum++
                                            
                                        }
                                        if(sum===4){
                                            return item
                                        }
                                    }
                                }
                            }
                        }
                    } 
                }
                if(list.length===2){ //有两组数据
                    for(let i=0;i<list[0].length;i++){
                        sum=2
                        temp=integer
                        if(temp.indexOf(list[0][i])!==-1){
                            let temp1=temp.replace(list[0][i],'')
                            sum++
                            for(let i=0;i<list[1].length;i++){
                                sum=3
                                temp=temp1
                                if(temp.indexOf(list[1][i])!==-1){
                                    sum++
                                
                                }
                                if(sum===4){
                                    return item
                                }
                            }
                        }
                    } 
                }
                if(list.length===1){ //有一组数据
                    for(let i=0;i<list[0].length;i++){
                        sum=3
                        temp=integer
                        if(temp.indexOf(list[0][i])!==-1){
                            sum++
                            
                        }
                        if(sum===4){
                            return item
                        }
                    } 
                }
            }
            if(integer.length===2){ //二定、现
                if(list.length===2){ //有两组数据
                    for(let i=0;i<list[0].length;i++){
                        sum=2
                        temp=integer
                        if(temp.indexOf(list[0][i])!==-1){
                            let temp1=temp.replace(list[0][i],'')
                            sum++
                            for(let i=0;i<list[1].length;i++){
                                sum=3
                                temp=temp1
                                if(temp.indexOf(list[1][i])!==-1){
                                    sum++
                                
                                }
                                if(sum===4){
                                    return item
                                }
                            }
                        }
                    } 
                }
                if(list.length===1){ //有一组数据
                    for(let i=0;i<list[0].length;i++){
                        sum=3
                        temp=integer
                        if(temp.indexOf(list[0][i])!==-1){
                            sum++
                            
                        }
                        if(sum===4){
                            return item
                        }
                    } 
                }
            }
         
            
        })
        // console.log(this.all)
        return this.all
    },
    
    //排除码上面已经定义：
    // 必出码  已经满足6种玩法
    appearnumber(list){
        this.recover() //还原大底
        this.all=this.all.filter((item)=>{
            let flag=false
            list.forEach((i)=>{
                if(item.includes(i))
                flag=true
            })
            if(flag)
            return item
        })
        // console.log(this.all)
        return this.all
    },
    //重数  已经满足6种玩法
    multiplenumbers(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))
                // console.log(ge,shi,bai,qian)
                let flag=false
                let flag2=true
                list.forEach((i)=>{
                    if(i==='1'){//双重ab ac ad bc bd cd
                        if(save){
                            if((qian===bai&&qian!=='X')||(qian===shi&&qian!=='X')||(qian===ge&&qian!=='X')||(bai===shi&&bai!=='X')||(bai===ge&&bai!=='X')||(shi===ge&&shi!=='X'))
                            flag=true
                        }else{
                            if((qian===bai&&qian!=='X')||(qian===shi&&qian!=='X')||(qian===ge&&qian!=='X')||(bai===shi&&bai!=='X')||(bai===ge&&bai!=='X')||(shi===ge&&shi!=='X'))
                            flag2=false
                        }
                    }
                    if(i==='2'){//双双重 ab&&cd ac&&bd ad&&bc
                        if(save){
                            if(qian===bai&&shi===ge||qian===shi&&bai===ge||qian===ge&&bai===shi)
                            flag=true
                        }else{
                            if(qian===bai&&shi===ge||qian===shi&&bai===ge||qian===ge&&bai===shi)
                            flag2=false
                        }
                    }
                    if(i==='3'){//三重 abc abd acd bcd
                        if(save){
                            if(qian===bai&&bai===shi||qian===shi&&shi===ge||qian===bai&&bai===ge||bai===shi&&shi===ge)
                            flag=true
                        }else{
                            if(qian===bai&&bai===shi||qian===shi&&shi===ge||qian===bai&&bai===ge||bai===shi&&shi===ge)
                            flag2=false
                        }
                    }
                    if(i==='4'){//四重abcd
                        if(save){
                            if(qian===bai&&bai===shi&&shi===ge)
                            flag=true
                        }else{
                            if(qian===bai&&bai===shi&&shi===ge)
                            flag2=false
                        }
                    }
                })
                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },
    //连号兄弟号 上面已经定义
    // 入数个数 条件并集 已经满足6种玩法
    incomingnumber(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
            let temp
            let flag=false
            let flag2=true
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            // console.log(temp,item)
            if(temp.length===4){//四定 四现
                let qian=temp[0]
                let bai=temp[1]
                let shi=temp[2]
                let ge=temp[3]
                list.forEach((n)=>{
                    if(n==='1'){//所有号码相同 a===b===c===d
                        if(save){
                            if(qian===bai&&bai===shi&&shi===ge){
                            flag=true
                            }
                        }else{
                            if(qian===bai&&bai===shi&&shi===ge){
                            flag2=false
                        }
                        }
                        
                    }
                    if(n==='2'){//只含2个不同的号码
                                // a===b c===d b!==c
                                // a===c b===d c!==b
                                // a===d b===c d!==b
                                // a===b b===c c!==d
                                // a===b b===d d!==c
                                // a===c c===d d!==b
                                // b===c c===d d!==a
                                
                        if(save){
                            if(
                            qian===bai&&shi===ge&&bai!==shi||
                            qian===shi&&bai===ge&&shi!==ge||
                            qian===ge&&bai===shi&&ge!==bai||
                            qian===bai&&bai===shi&&shi!==ge||
                            qian===bai&&bai===ge&&ge!==shi||
                            qian===shi&&shi===ge&&ge!==bai||
                            bai===shi&&shi===ge&&ge!==qian
                            )
                            flag=true
                        }else{
                            if(
                            qian===bai&&shi===ge&&bai!==shi||
                            qian===shi&&bai===ge&&shi!==ge||
                            qian===ge&&bai===shi&&ge!==bai||
                            qian===bai&&bai===shi&&shi!==ge||
                            qian===bai&&bai===ge&&ge!==shi||
                            qian===shi&&shi===ge&&ge!==bai||
                            bai===shi&&shi===ge&&ge!==qian
                            )
                            flag2=false
                        }

                    }
                    if(n==='3'){//只含3个不同的号码
                        // a===b a!==c a!==d c!==d
                        // a===c a!==b a!==d b!==d
                        // a===d a!==b a!==c b!==c
                        // b===c b!==a b!==d a!==d
                        // b===d b!==c b!==a c!==a
                        // c===d c!==a c!==b a!==b
                        if(save){
                            if(
                            qian===bai&&qian!==shi&&qian!==ge&&shi!==ge||
                            qian===shi&&qian!==bai&&qian!==ge&&bai!==ge||
                            qian===ge&&qian!==bai&&qian!==shi&&bai!==shi||
                            bai===shi&&bai!==qian&&bai!==ge&&qian!==ge||
                            bai===ge&&bai!==qian&&bai!==shi&&qian!==shi||
                            shi===ge&&shi!==bai&&shi!==qian&&bai!==qian
                            )
                            flag=true
                        }else{
                            if(
                            qian===bai&&qian!==shi&&qian!==ge&&shi!==ge||
                            qian===shi&&qian!==bai&&qian!==ge&&bai!==ge||
                            qian===ge&&qian!==bai&&qian!==shi&&bai!==shi||
                            bai===shi&&bai!==qian&&bai!==ge&&qian!==ge||
                            bai===ge&&bai!==qian&&bai!==shi&&qian!==shi||
                            shi===ge&&shi!==bai&&shi!==qian&&bai!==qian
                            )
                            flag2=false
                        }
                    }
                    if(n==='4'){//所有号码均不相同
                        // a!==b a!==c a!==d b!==c b!==d c!==d
                        if(save){
                            if(
                            qian!==bai&&qian!==shi&&qian!==ge&&bai!==shi&&bai!==ge&&shi!==ge
                            )
                            flag=true
                        }else{
                            if(
                            qian!==bai&&qian!==shi&&qian!==ge&&bai!==shi&&bai!==ge&&shi!==ge
                            )
                            flag2=false
                        }
                    }
                })
                
            }
            if(temp.length===3){//三定 三现
                let bai=temp[0]
                let shi=temp[1]
                let ge=temp[2]
                list.forEach((n)=>{
                    if(n==='1'){//所有号码相同 a===b===c
                        if(save){
                            if(bai===shi&&shi===ge){
                            flag=true
                            }
                        }else{
                            if(bai===shi&&shi===ge){
                            flag2=false
                        }
                        }
                        
                    }
                    if(n==='2'){//只含2个不同的号码
                                //a=b&& b!=c
                                //a=c&& c!=b
                                //b=c&& c!=a
                                
                        if(save){
                            if(
                            bai===shi&&shi!==ge||
                            bai===ge&&ge!==shi||
                            shi===ge&&ge!==bai
                            )
                            flag=true
                        }else{
                            if(
                            bai===shi&&shi!==ge||
                            bai===ge&&ge!==shi||
                            shi===ge&&ge!==bai
                            )
                            flag2=false
                        }

                    }
                    if(n==='3'){//只含3个不同的号码
                       //a!=b&&a!=c&&b!=c
                        if(save){
                            if(
                            bai!==shi&&bai!==ge&&shi!==ge
                            )
                            flag=true
                        }else{
                            if(
                                bai!==shi&&bai!==ge&&shi!==ge
                            )
                            flag2=false
                        }
                    }
                    
                })
                
            }
            if(temp.length===2){//二定 二现
                let shi=temp[0]
                let ge=temp[1]
                list.forEach((n)=>{
                    if(n==='1'){//所有号码相同 a===b
                        if(save){
                            if(shi===ge){
                            flag=true
                            }
                        }else{
                            if(shi===ge){
                            flag2=false
                        }
                        }
                        
                    }
                    if(n==='2'){//只含2个不同的号码
                                //a!==b
                                
                        if(save){
                            if(
                            shi!==ge
                            )
                            flag=true
                        }else{
                            if(
                            shi!=ge
                            )
                            flag2=false
                        }

                    }
                    
                    
                })
            }
                

                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },

    
    // 极值 条件并集    已经满足6种玩法
    extremum(type,list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
            
            let flag=false
            let flag2=true
            
           if(item.length===4){//四定 四现 三定 二定
                if(type===1){//最大值
                let ge= item.substr(3,1)==='X'?-1:parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?-1:parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?-1:parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?-1:parseInt(item.substr(0,1))
                let max=Math.max(qian,bai,shi,ge)
                    list.forEach((n)=>{
                        if(save){
                        if(max<=n&&(qian===n||bai===n||shi===n||ge===n))
                        flag=true
                        }else{
                        if(max<=n&&(qian===n||bai===n||shi===n||ge===n))
                        flag2=false
                        }
                    })
                }
                if(type===0){//最小值
                let ge= item.substr(3,1)==='X'?11:parseInt(item.substr(3,1))
                let shi=item.substr(2,1)==='X'?11:parseInt(item.substr(2,1))
                let bai=item.substr(1,1)==='X'?11:parseInt(item.substr(1,1))
                let qian=item.substr(0,1)==='X'?11:parseInt(item.substr(0,1))
                    let min=Math.min(qian,bai,shi,ge)
                    list.forEach((n)=>{
                        if(save){
                            if(min>=n&&(qian===n||bai===n||shi===n||ge===n))
                            flag=true
                        }else{
                            if(min>=n&&(qian===n||bai===n||shi===n||ge===n))
                            flag2=false
                        }
                    })
                }
           }
           if(item.length===3){//三现
                if(type===1){//最大值
                let ge= item.substr(2,1)==='X'?-1:parseInt(item.substr(2,1))
                let shi=item.substr(1,1)==='X'?-1:parseInt(item.substr(1,1))
                let bai=item.substr(0,1)==='X'?-1:parseInt(item.substr(0,1))
                let max=Math.max(bai,shi,ge)
                    list.forEach((n)=>{
                    if(save){
                        if(max<=n&&(bai===n||shi===n||ge===n))
                        flag=true
                    }else{
                        if(max<=n&&(bai===n||shi===n||ge===n))
                        flag2=false
                    }
                    })
                }
                if(type===0){//最小值
                let ge= item.substr(2,1)==='X'?11:parseInt(item.substr(2,1))
                let shi=item.substr(1,1)==='X'?11:parseInt(item.substr(1,1))
                let bai=item.substr(0,1)==='X'?11:parseInt(item.substr(0,1))
                    let min=Math.min(bai,shi,ge)
                    list.forEach((n)=>{
                        if(save){
                            if(min>=n&&(bai===n||shi===n||ge===n))
                            flag=true
                        }else{
                            if(min>=n&&(bai===n||shi===n||ge===n))
                            flag2=false
                        }
                    })
                }
           }
           if(item.length===2){//二现
                if(type===1){//最大值
                let ge= item.substr(1,1)==='X'?-1:parseInt(item.substr(1,1))
                let shi=item.substr(0,1)==='X'?-1:parseInt(item.substr(0,1))
                let max=Math.max(shi,ge)
                    list.forEach((n)=>{
                    if(save){
                        if(max<=n&&(shi===n||ge===n))
                        flag=true
                    }else{
                        if(max<=n&&(shi===n||ge===n))
                        flag2=false
                    }
                    })
                }
                if(type===0){//最小值
                let ge= item.substr(1,1)==='X'?11:parseInt(item.substr(1,1))
                let shi=item.substr(0,1)==='X'?11:parseInt(item.substr(0,1))
                    let min=Math.min(shi,ge)
                    list.forEach((n)=>{
                        if(save){
                            if(min>=n&&(shi===n||ge===n))
                            flag=true
                        }else{
                            if(min>=n&&(shi===n||ge===n))
                            flag2=false
                        }
                    })
                }
           }

            
           
            if(flag||(!save&&flag2))
            return item

        })
        // console.log(this.all)
        return this.all
    },
    // 跨度 条件并集  已经满足6种玩法
    spacing(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定 四现
                let ge= parseInt(item.substr(3,1))
                let shi=parseInt(item.substr(2,1))
                let bai=parseInt(item.substr(1,1))
                let qian=parseInt(item.substr(0,1))
                let max=Math.max(qian,bai,shi,ge)
                let min=Math.min(qian,bai,shi,ge)
                let kua=max-min
                list.forEach((n)=>{
                    if(save){
                            if(kua===n){
                                flag=true
                            }
                    }else{
                            if(kua===n){
                                flag2=false
                            }
                    }
                })
            
            }
            if(temp.length===3){//三定 三现
                let ge= temp.substr(2,1)
                let shi=temp.substr(1,1)
                let bai=temp.substr(0,1)
                let max=Math.max(bai,shi,ge)
                let min=Math.min(bai,shi,ge)
                let kua=max-min
                list.forEach((n)=>{
                    if(save){
                            if(kua===n){
                                flag=true
                            }
                    }else{
                            if(kua===n){
                                flag2=false
                            }
                    }
                })

            }
            if(temp.length===2){//二定 二现
                let ge= temp.substr(1,1)
                let shi=temp.substr(0,1)
                let max=Math.max(shi,ge)
                let min=Math.min(shi,ge)
                let kua=max-min
                list.forEach((n)=>{
                    if(save){
                            if(kua===n){
                                flag=true
                            }
                    }else{
                            if(kua===n){
                                flag2=false
                            }
                    }
                })
            }
            
            
            if(flag||(!save&&flag2))
            return item
        })
        // console.log(this.all)
        return this.all
    },

    //加减乘除 
    //和值 条件并集 已经满足6种玩法
   
    sumvalue(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
            
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            
            if(temp.length===4){//四定 四现
                let ge=parseInt(temp[3])
                let shi=parseInt(temp[2])
                let bai=parseInt(temp[1])
                let qian=parseInt(temp[0])
                
                let sum=qian+bai+shi+ge
                
                list.forEach((n)=>{
                if(save){
                        if(sum===n){
                            flag=true
                        }
                }else{
                        if(sum===n){
                            flag2=false
                        }
                }
                })
            
            }
            if(temp.length===3){//三定三现
                let ge=parseInt(temp[2])
                let shi=parseInt(temp[1])
                let bai=parseInt(temp[0])
                
                let sum=bai+shi+ge
                list.forEach((n)=>{
                if(save){
                        if(sum===n){
                            flag=true
                        }
                }else{
                        if(sum===n){
                            flag2=false
                        }
                }
                })

            }
            if(temp.length===2){//二定 二现
                let ge=parseInt(temp[1])
                let shi=parseInt(temp[0])
                
                let sum=shi+ge
                list.forEach((n)=>{
                if(save){
                        if(sum===n){
                            flag=true
                        }
                }else{
                        if(sum===n){
                            flag2=false
                        }
                }
                })
            }
            
            if(flag||(!save&&flag2))
            return item
        })
        // console.log(this.all)
        return this.all
    },
    //合值（合分） 上面已经定义
    //合值+跨度 条件并集  已经满足6种玩法
    sumvaluepluspan(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定 四现
                let ge= parseInt(item.substr(3,1))
                let shi=parseInt(item.substr(2,1))
                let bai=parseInt(item.substr(1,1))
                let qian=parseInt(item.substr(0,1))
                let hefen=(qian+bai+shi+ge)%10
                let max=Math.max(qian,bai,shi,ge)
                let min=Math.min(qian,bai,shi,ge)
                let kua=max-min
                let hefenkuadu=hefen+kua
                list.forEach((n)=>{
                    if(save){
                            if(hefenkuadu===n){
                                flag=true
                            }
                    }else{
                            if(hefenkuadu===n){
                                flag2=false
                            }
                    }
                })
            
            }
            if(temp.length===3){//三定 三现
                let ge= parseInt(temp.substr(2,1))
                let shi=parseInt(temp.substr(1,1))
                let bai=parseInt(temp.substr(0,1))
                let hefen=(bai+shi+ge)%10
                let max=Math.max(bai,shi,ge)
                let min=Math.min(bai,shi,ge)
                let kua=max-min
                let hefenkuadu=hefen+kua
                list.forEach((n)=>{
                    if(save){
                            if(hefenkuadu===n){
                                flag=true
                            }
                    }else{
                            if(hefenkuadu===n){
                                flag2=false
                            }
                    }
                })

            }
            if(temp.length===2){//二定 二现
                let ge=parseInt(temp.substr(1,1))
                let shi=parseInt(temp.substr(0,1))
                let hefen=(shi+ge)%10
                let max=Math.max(shi,ge)
                let min=Math.min(shi,ge)
                let kua=max-min
                let hefenkuadu=hefen+kua
                list.forEach((n)=>{
                    if(save){
                            if(hefenkuadu===n){
                                flag=true
                            }
                    }else{
                            if(hefenkuadu===n){
                                flag2=false
                            }
                    }
                })
            }
            
            
            if(flag||(!save&&flag2))
            return item
               
        })
        // console.log(this.all)
        return this.all
    },
    //减式 上面已经定义
    //合值-跨度 条件并集 已经满足6种玩法
    sumvalueminuspan(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
            let flag=false
            let flag2=true
            let temp
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定 四现
                let ge= parseInt(item.substr(3,1))
                let shi=parseInt(item.substr(2,1))
                let bai=parseInt(item.substr(1,1))
                let qian=parseInt(item.substr(0,1))
                let hefen=(qian+bai+shi+ge)%10
                let max=Math.max(qian,bai,shi,ge)
                let min=Math.min(qian,bai,shi,ge)
                let kua=max-min
                let hefenkuadu=hefen-kua
                list.forEach((n)=>{
                    if(save){
                            if(hefenkuadu===n){
                                flag=true
                            }
                    }else{
                            if(hefenkuadu===n){
                                flag2=false
                            }
                    }
                })
            
            }
            if(temp.length===3){//三定 三现
                let ge= parseInt(temp.substr(2,1))
                let shi=parseInt(temp.substr(1,1))
                let bai=parseInt(temp.substr(0,1))
                let hefen=(bai+shi+ge)%10
                let max=Math.max(bai,shi,ge)
                let min=Math.min(bai,shi,ge)
                let kua=max-min
                let hefenkuadu=hefen-kua
                list.forEach((n)=>{
                    if(save){
                            if(hefenkuadu===n){
                                flag=true
                            }
                    }else{
                            if(hefenkuadu===n){
                                flag2=false
                            }
                    }
                })

            }
            if(temp.length===2){//二定 二现
                let ge=parseInt(temp.substr(1,1))
                let shi=parseInt(temp.substr(0,1))
                let hefen=(shi+ge)%10
                let max=Math.max(shi,ge)
                let min=Math.min(shi,ge)
                let kua=max-min
                let hefenkuadu=hefen-kua
                list.forEach((n)=>{
                    if(save){
                            if(hefenkuadu===n){
                                flag=true
                            }
                    }else{
                            if(hefenkuadu===n){
                                flag2=false
                            }
                    }
                })
            }
            
            
            if(flag||(!save&&flag2))
            return item
               
        })
        // console.log(this.all)
        return this.all
    },
    //两数乘积 条件并集 需要满足 四定 三定 二定 二现 已经满足4种玩法，需要注意的是二现需要一个空的位置数组
    doublemultiply(list,save,placelist){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let ge= item[3]==='X'?'X':parseInt(item[3])
                let shi=item[2]==='X'?'X':parseInt(item[2])
                let bai=item[1]==='X'?'X':parseInt(item[1])
                let qian=item[0]==='X'?'X':parseInt(item[0])
                let ab=(qian*bai)%10
                let ac=(qian*shi)%10
                let ad=(qian*ge)%10
                let bc=(bai*shi)%10
                let bd=(bai*ge)%10
                let cd=(shi*ge)%10
                // console.log(ab,ac,ad,bc,bd,cd,item)
                let flag=false
                let flag2=true
                if(placelist.length!==0){//四定 三定 二定 
                    placelist.forEach((n)=>{
                    if(n==='ab'){
                        list.forEach((i)=>{
                            if(save){
                                if(ab===i){
                                flag=true
                                }
                            }else{
                                if(ab===i){
                                flag2=false
                            }
                            }
                        })
                    }
                    if(n==='ac'){
                        list.forEach((i)=>{
                            if(save){
                                if(ac===i){
                                flag=true
                                }
                            }else{
                                if(ac===i){
                                flag2=false
                            }
                            }
                        })

                    }
                    if(n==='ad'){
                        list.forEach((i)=>{
                            if(save){
                                if(ad===i){
                                flag=true
                                }
                            }else{
                                if(ad===i){
                                flag2=false
                            }
                            }
                        })

                    }
                    if(n==='bc'){
                        list.forEach((i)=>{
                            if(save){
                                if(bc===i){
                                flag=true
                                }
                            }else{
                                if(bc===i){
                                flag2=false
                            }
                            }
                        })

                    }
                    if(n==='bd'){
                        list.forEach((i)=>{
                            if(save){
                                if(bd===i){
                                flag=true
                                }
                            }else{
                                if(bd===i){
                                flag2=false
                            }
                            }
                        })

                    }
                    if(n==='cd'){
                        list.forEach((i)=>{
                            if(save){
                                if(cd===i){
                                flag=true
                                }
                            }else{
                                if(cd===i){
                                flag2=false
                            }
                            }
                        })

                    }
                })
                
                }else{//二现
                    ge=parseInt(item[1])
                    shi=parseInt(item[0])
                    ab=(ge*shi)%10
                    list.forEach((i)=>{
                            if(save){
                                if(ab===i){
                                flag=true
                                }
                            }else{
                                if(ab===i){
                                flag2=false
                            }
                            }
                        })
                }
                
                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },
    // 三数乘积 条件并集 需要满足 四定 三定 三现 已经满足3种玩法，需要注意的是三现需要一个空的位置数组
    threemultiply(list,save,placelist){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let ge= item[3]==='X'?'X':parseInt(item[3])
                let shi=item[2]==='X'?'X':parseInt(item[2])
                let bai=item[1]==='X'?'X':parseInt(item[1])
                let qian=item[0]==='X'?'X':parseInt(item[0])
                let abc=qian==='X'||bai==='X'||shi==='X'?11:(qian*bai*shi)%10
                let abd=qian==='X'||bai==='X'||ge==='X'?11:(qian*bai*ge)%10
                let acd=qian==='X'||shi==='X'||ge==='X'?11:(qian*shi*ge)%10
                let bcd=bai==='X'||shi==='X'||ge==='X'?11:(bai*shi*ge)%10
                let flag=false
                let flag2=true
                if(placelist.length!==0){ //四定 三定
                    placelist.forEach((n)=>{
                    if(n==='abc'){
                        list.forEach((i)=>{
                            if(save){
                                if(abc===i){
                                flag=true
                                }
                            }else{
                                if(abc===i){
                                flag2=false
                            }
                            }
                        })
                    }
                    if(n==='abd'){
                        list.forEach((i)=>{
                            if(save){
                                if(abd===i){
                                flag=true
                                }
                            }else{
                                if(abd===i){
                                flag2=false
                            }
                            }
                        })

                    }
                    if(n==='acd'){
                        list.forEach((i)=>{
                            if(save){
                                if(acd===i){
                                flag=true
                                }
                            }else{
                                if(acd===i){
                                flag2=false
                            }
                            }
                        })

                    }
                    if(n==='bcd'){
                        list.forEach((i)=>{
                            if(save){
                                if(bcd===i){
                                flag=true
                                }
                            }else{
                                if(bcd===i){
                                flag2=false
                            }
                            }
                        })

                    }
                    
                })
                
                }else{ //三现
                    ge=parseInt(item[2])
                    shi=parseInt(item[1])
                    bai=parseInt(item[0])
                    abc=(bai*shi*ge)%10
                    list.forEach((i)=>{
                            if(save){
                                if(abc===i){
                                flag=true
                                }
                            }else{
                                if(abc===i){
                                flag2=false
                            }
                            }
                        })
                }
                
                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },
    
    // 四数乘积 条件并集 需要满足 四定 四现 已经满足2种玩法
    fourmultiply(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let ge=item%10
                let shi=Math.trunc(item/10%10)
                let bai=Math.trunc(item/100%10)
                let qian=Math.trunc(item/1000)
                let flag=false
                let flag2=true
                let hefen=(qian*bai*shi*ge)%10
                list.forEach((i)=>{
                    if(save){
                        if(hefen===i){
                        flag=true
                        }
                    }else{
                        if(hefen===i){
                        flag2=false
                        }
                    }
                })
                if(flag||(!save&&flag2))
                return item
            })
            // console.log(this.all)
            return this.all
    },
    // 位积 条件并集 需要满足 四定 三定 二定 已经满足3种玩法
    potentialproduct(type,list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let ge= item[3]==='X'?0:parseInt(item[3])
                let shi=item[2]==='X'?0:parseInt(item[2])
                let bai=item[1]==='X'?0:parseInt(item[1])
                let qian=item[0]==='X'?0:parseInt(item[0])
                let flag=false
                let flag2=true
                let weijihezhi=qian+bai*2+shi*3+ge*4
                let weijihefen=(qian+bai*2+shi*3+ge*4)%10
                if(type===1){//和值
                    list.forEach((i)=>{
                        if(save){
                            if(weijihezhi===i){
                            flag=true
                            }
                        }else{
                            if(weijihezhi===i){
                            flag2=false
                            }
                        }
                    })
                }
                if(type===0){//合值
                    list.forEach((i)=>{
                        if(save){
                            if(weijihefen===i){
                            flag=true
                            }
                        }else{
                            if(weijihefen===i){
                            flag2=false
                            }
                        }
                    })
                }
                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },
    // 反位积 条件并集  需要满足 四定 三定 二定 已经满足3种玩法
    inversepotentialproduct(type,list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
                let ge= item[3]==='X'?0:parseInt(item[3])
                let shi=item[2]==='X'?0:parseInt(item[2])
                let bai=item[1]==='X'?0:parseInt(item[1])
                let qian=item[0]==='X'?0:parseInt(item[0])
                let flag=false
                let flag2=true
                let fanweijihezhi=qian*4+bai*3+shi*2+ge
                let fanweijihefen=(qian*4+bai*3+shi*2+ge)%10
                if(type===1){//合值
                    list.forEach((i)=>{
                        if(save){
                            if(fanweijihezhi===i){
                            flag=true
                            }
                        }else{
                            if(fanweijihezhi===i){
                            flag2=false
                            }
                        }
                    })
                }
                if(type===0){//合值
                    list.forEach((i)=>{
                        if(save){
                            if(fanweijihefen===i){
                            flag=true
                            }
                        }else{
                            if(fanweijihefen===i){
                            flag2=false
                            }
                        }
                    })
                }
                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },
    // 余数和 已经满足6种玩法
    remaindersum(list,save){
        this.recover()//复原大底
        this.all=this.all.filter((item)=>{
            let temp
            let flag=false
            let flag2=true
            if(item.includes('X')){
                temp=item.replaceAll('X','')
                
            }else{
                temp=item
            }
            if(temp.length===4){//四定 四现
                let ge= parseInt(item.substr(3,1))
                let shi=parseInt(item.substr(2,1))
                let bai=parseInt(item.substr(1,1))
                let qian=parseInt(item.substr(0,1))
                let yushu=(qian%3)+(bai%3)+(shi%3)+(ge%3)
                list.forEach((i)=>{
                    if(save){
                        if(yushu===i){
                        flag=true
                        }
                    }else{
                        if(yushu===i){
                        flag2=false
                        }
                    }
                })
                
            
            }
            if(temp.length===3){//三定 三现
                let ge= parseInt(temp.substr(2,1))
                let shi=parseInt(temp.substr(1,1))
                let bai=parseInt(temp.substr(0,1))
                let yushu=(bai%3)+(shi%3)+(ge%3)
                list.forEach((i)=>{
                    if(save){
                        if(yushu===i){
                        flag=true
                        }
                    }else{
                        if(yushu===i){
                        flag2=false
                        }
                    }
                })
                

            }
            if(temp.length===2){//二定 二现
                let ge=parseInt(temp.substr(1,1))
                let shi=parseInt(temp.substr(0,1))
                let yushu=(shi%3)+(ge%3)
                list.forEach((i)=>{
                    if(save){
                        if(yushu===i){
                        flag=true
                        }
                    }else{
                        if(yushu===i){
                        flag2=false
                        }
                    }
                })
            }
                
                
                
                if(flag||(!save&&flag2))
                return item
        })
        // console.log(this.all)
        return this.all
    },

    //调用方法之前复原大底（局部使用的结果）
    recover(){
        switch (this.type) {
                case 1:
                
                    this.all=this.$store.state.prize.fourfixed;
                
                break;
                case 2:
                
                    this.all=this.$store.state.prize.fourshow;
                
                break;
                case 3:
                
                    this.all=this.$store.state.prize.threefixed;
               
                break;
                case 4:
                
                    this.all=this.$store.state.prize.threeshow;
                
                break;
                case 5:
                
                    this.all=this.$store.state.prize.twofixed;
                
                break;
                case 6:
                
                    this.all=this.$store.state.prize.twoshow;
                
                break;
        
         }
        //  console.log(this.all)
         
    },
    //更新最后结果的组数为原始大底（全局的结果）并还原选择的条件
    clear(){
        this.selectShow2=false;

        switch (this.type) {
                case 1:
                
                    this.lastall=this.$store.state.prize.fourfixed
                    this.selectList=JSON.parse(JSON.stringify(this.$store.state.prize.initcondFourDec))
                    

                
                break;
                case 2:
                
                    this.lastall=this.$store.state.prize.fourshow;
                    this.selectList=JSON.parse(JSON.stringify(this.$store.state.prize.initcondFourShow))

                
                break;
                case 3:
                
                    this.lastall=this.$store.state.prize.threefixed
                    this.selectList=JSON.parse(JSON.stringify(this.$store.state.prize.initcondThreeDec))


               
                break;
                case 4:
                
                    this.lastall=this.$store.state.prize.threeshow
                    this.selectList=JSON.parse(JSON.stringify(this.$store.state.prize.initcondThreeShow))


                
                break;
                case 5:
                
                    this.lastall=this.$store.state.prize.twofixed
                    this.selectList=JSON.parse(JSON.stringify(this.$store.state.prize.initcondTwoDec))


                
                break;
                case 6:
                
                    this.lastall=this.$store.state.prize.twoshow
                    this.selectList=JSON.parse(JSON.stringify(this.$store.state.prize.initcondTwoShow))

                break;
        
         }
        
        //  console.log(this.lastall)
        //  console.log(this.selectList)

    },
    //只是还原全局的大底
    clearlastall(flag){
        this.selectShow2=flag;
        switch (this.type) {
                case 1:
                
                    this.lastall=this.$store.state.prize.fourfixed
                    

                
                break;
                case 2:
                
                    this.lastall=this.$store.state.prize.fourshow;

                
                break;
                case 3:
                
                    this.lastall=this.$store.state.prize.threefixed
               
                break;
                case 4:
                
                    this.lastall=this.$store.state.prize.threeshow
                  
                
                break;
                case 5:
                
                    this.lastall=this.$store.state.prize.twofixed
                 
                break;
                case 6:
                
                    this.lastall=this.$store.state.prize.twoshow
                   
                break;
        
         }
        
        //  console.log(this.lastall)
        //  console.log(this.selectList)

    },

    //获取七星彩和排列五
     getlotteryhistoryseven(){
        let tempdata={lotteryKind: '7',
                    pageSize: '100',
                    sortBy:"lottery_data desc"}
            this.$axios({
              method: "post",
              url: this.$API['API_LOTTERY_LISTS_SPACE'],
              data:tempdata,
            //   headers: { access_token: this.access_token }
            }).then((response)=>{
                let array=response.data.data
                this.newestseven=array[0].periodText.substr(0,array[0].periodText.length-1)
                array.forEach((item)=>{
                    this.lotteryhistoryseven4.push(''+item.num1+item.num2+item.num3+item.num4)
                    this.lotteryhistoryseven3.push(''+'X'+item.num2+item.num3+item.num4)
                    this.lotteryhistoryseven3.push(''+item.num1+'X'+item.num3+item.num4)
                    this.lotteryhistoryseven3.push(''+item.num1+item.num2+'X'+item.num4)
                    this.lotteryhistoryseven3.push(''+item.num1+item.num2+item.num3+'X')
                    this.lotteryhistoryseven2.push(''+item.num1+item.num2+'X'+'X')
                    this.lotteryhistoryseven2.push(''+item.num1+'X'+item.num3+'X')
                    this.lotteryhistoryseven2.push(''+item.num1+'X'+'X'+item.num4)
                    this.lotteryhistoryseven2.push(''+'X'+item.num2+item.num3+'X')
                    this.lotteryhistoryseven2.push(''+'X'+item.num2+'X'+item.num4)
                    this.lotteryhistoryseven2.push(''+'X'+'X'+item.num3+item.num4)
                })
                // console.log(this.lotteryhistoryseven4,this.lotteryhistoryseven3,this.lotteryhistoryseven2)

            })
        },
         getlotteryhistoryfive(){
            let tempdata={lotteryKind: '5',
                    pageSize: '100',
                    sortBy:"lottery_data desc"}
             //获取排列五往期历史
         this.$axios({
              method: "post",
              url: this.$API['API_LOTTERY_LISTS_SPACE'],
              data:tempdata,
            //   headers: { access_token: this.access_token }
            }).then((response)=>{
                
                let array2=response.data.data
                this.newestfive=array2[0].periodText.substr(0,array2[0].periodText.length-1)
               
                array2.forEach((item)=>{
                    this.lotteryhistoryfive4.push(''+item.num1+item.num2+item.num3+item.num4)
                    this.lotteryhistoryfive3.push(''+'X'+item.num2+item.num3+item.num4)
                    this.lotteryhistoryfive3.push(''+item.num1+'X'+item.num3+item.num4)
                    this.lotteryhistoryfive3.push(''+item.num1+item.num2+'X'+item.num4)
                    this.lotteryhistoryfive3.push(''+item.num1+item.num2+item.num3+'X')
                    this.lotteryhistoryfive2.push(''+item.num1+item.num2+'X'+'X')
                    this.lotteryhistoryfive2.push(''+item.num1+'X'+item.num3+'X')
                    this.lotteryhistoryfive2.push(''+item.num1+'X'+'X'+item.num4)
                    this.lotteryhistoryfive2.push(''+'X'+item.num2+item.num3+'X')
                    this.lotteryhistoryfive2.push(''+'X'+item.num2+'X'+item.num4)
                    this.lotteryhistoryfive2.push(''+'X'+'X'+item.num3+item.num4)
                })
                // console.log(this.lotteryhistoryfive4,this.lotteryhistoryfive3,this.lotteryhistoryfive2)
                

            })
        },

    


   


    },
    computed:{
        
        selected(){
            let num=0
            for(let i=1;i<this.selectList.length;i++){
                let size=this.selectList[i].btn.filter((item)=>(item.isTrue===1))
                num+=size.length
            }
            return num
        },
        waitingselected(){ //条件的状态有0 1 2  0代表未选 1 表示已选 2代表已选待选
            let num=0
            for(let i=1;i<this.selectList.length;i++){
                let size=this.selectList[i].btn.filter((item)=>(item.isTrue!==0))
                num+=size.length
            }
            return num
        },
       
        


    },
    watch:{
    lastall(newVal){
        switch (this.type) {
                case 1:
                
                    this.$store.commit('prize/alertFourfixed',newVal);
                    // this.lastall=this.$store.state.prize.Cfourfixed


                break;
                case 2:
                
                    this.$store.commit('prize/alertFourshow',newVal);
                    // this.lastall=this.$store.state.prize.Cfourshow

               
                break;
                case 3:
               
                    this.$store.commit('prize/alertThreefixed',newVal);
                    // this.lastall=this.$store.state.prize.Cthreefixed

                
                break;
                case 4:
                
                    this.$store.commit('prize/alertThreeshow',newVal);
                    // this.lastall=this.$store.state.prize.Cthreeshow


                break;
                case 5:
               
                    this.$store.commit('prize/alertTwofixed',newVal);
                    // this.lastall=this.$store.state.prize.Ctwofixed


                break;
                case 6:
                
                    this.$store.commit('prize/alertTwoshow',newVal);
                    // this.lastall=this.$store.state.prize.Ctwoshow
                
                break;
        
        }
    },
    
    selectList:{
        deep:true,
        handler(newVal){
            switch (this.type) {
                case 1:
                
                    this.$store.commit('prize/alertcondFourfixed',newVal);

                break;
                case 2:
                
                    this.$store.commit('prize/alertcondFourshow',newVal);
                   


                break;
                case 3:
               
                    this.$store.commit('prize/alertcondThreefixed',newVal);
                   

                
                break;
                case 4:
                
                    this.$store.commit('prize/alertcondThreeshow',newVal);
                   


                break;
                case 5:
               
                    this.$store.commit('prize/alertcondTwofixed',newVal);
                   


                break;
                case 6:
                
                    this.$store.commit('prize/alertcondTwoshow',newVal);
                    
                
                break;
        
        }
        }
    }

    },
    mounted(){
        // this.$once(window.location.reload())
        //判断网页是什么打开
        // let isWeiXin = isWeixin()
        // if(isWeiXin){
        // this.showHeader = false
        // }
        // if(this.showHeader==false){
        // document.title = ''
        // }
        this.title= this.$route.query.title
        // console.log(this.$route)
        this.type=this.$store.state.prize.payType
        // console.log(this.type)

        switch(this.type){
            case 1:
                this.lastall=this.$store.state.prize.Cfourfixed
                this.menuList=this.$store.state.prize.menuFourDec
                this.selectList=this.$store.state.prize.condFourDec
                this.maxall='10000组'
                this.maxMsg='四定大底'
                break;
            case 2:
                this.lastall=this.$store.state.prize.Cfourshow
                this.menuList=this.$store.state.prize.menuFourShow
                this.selectList=this.$store.state.prize.condFourShow
                this.maxall='715组'
                this.maxMsg='四现大底'
                break;
            case 3:
                this.lastall=this.$store.state.prize.Cthreefixed
                this.menuList=this.$store.state.prize.menuThreeDec
                this.selectList=this.$store.state.prize.condThreeDec
                this.maxall='4000组'
                this.maxMsg='三定大底'
                break;
            case 4:
                this.lastall=this.$store.state.prize.Cthreeshow
                this.menuList=this.$store.state.prize.menuThreeShow
                this.selectList=this.$store.state.prize.condThreeShow
                this.maxall='220组'
                this.maxMsg='三现大底'
                break;
            case 5:
                this.lastall=this.$store.state.prize.Ctwofixed
                this.menuList=this.$store.state.prize.menuTwoDec
                this.selectList=this.$store.state.prize.condTwoDec
                this.maxall='600组'
                this.maxMsg='二定大底'
                break;
            case 6:
                this.lastall=this.$store.state.prize.Ctwoshow
                this.menuList=this.$store.state.prize.menuTwoShow
                this.selectList=this.$store.state.prize.condTwoShow
                this.maxall='55组'
                this.maxMsg='二现大底'
                break;
           
        }
        //测试用
        // console.log(this.lastall)
        // console.log(this.selectList)

        
        this.getlotteryhistoryseven()
        this.getlotteryhistoryfive()

        

    
            
        
      

        
        
        
           
        
        
        
    },
    created(){
        // window.localStorage.reload()
    }

}
</script>

<style lang="less" scoped>
// 左侧菜单样式
  .van-sidebar {
    width: 2.8rem;
    position: fixed;
    top: 1.2rem;
    left: 0;
    bottom: 1.8rem;
    overflow-y: scroll;
    
  }
// 右侧内容样式
  .right_box {
    position: fixed;
    top: 45px;
    left: 2.7rem;
    bottom: 1.8rem;
    right: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    font-size: 15px;

    div {
      h4 {
        margin: 10px;
        padding: 0px;
        color: red;
      }

      .van-grid {
        margin-bottom: 8px;
        margin-top: 3px;

        .van-grid-item {
          .van-grid-item__content--center {
            padding: 3px 10px;

            .infoTitleBox {
              width: 100%;

              h4 {
                margin: 0px;
                padding: 0px;
                color: black;
              }
            }

            .infoMesBox {
              width: 100%;

              p {
                margin: 5px 0px;
                font-size: 0.3rem;
                opacity: 0.5;
              }
            }

            .infoBtnBox {
              width: 100%;
              height: 40%;

              .van-button {
                float: right;
                width:20%;
                margin: 5px;
              }
            }
          }
        }

      }
    }
  }

//下端的展示区
.bottom_box{
    position: fixed;
    bottom: 0;
    height: 1.8rem;
    width: 10.6rem;
    div{
        display:inline-block;
    }
    .bottom_one{
       
            width: 1.8rem;
            height: 1.8rem;
            background-color: gray;
            div{
                position:fixed;
                bottom:0.3rem;
                left:0.1rem;
                width: 2rem;
                height: 2rem;
                border-radius:10px;
                text-align:center;
                font-size:18px;
                color:white;
                background-color:rgb(255, 89, 0);
            }
            
    }
    .bottom_two{
            width:56%;
            height:1.8rem;
            background-color:gray;
            div{
                padding-left: 1.2rem;
                position:fixed;
                bottom:0rem;
                text-align:center;
                font-size:0.35rem;
                color:white;

                
                
            }

    }
    .bottom_three{
            width:22%;
            height:1.8rem;
            background-color:rgb(234, 22, 22);

            div{
                padding-left: 0.3rem;
                position:fixed;
                bottom:0rem;
                color:white;
                text-align:center;
                font-size:0.35rem;

            }
    }
}


.pophead{
    height:1.2rem;
    font-size:0.6rem;
    color:white;
    background-color:red;
    // 并集，交集按钮的切换
    .unselect{
        margin-top: 8px;
        width:1.5rem;
        height:0.8rem;
        font-size:0.33rem;
        border:1px solid white;
        color:white;
        background-color:red;
    }
    .selectsave{
        margin-top: 8px;
        border:1px solid white;
        width:1.5rem;
        height:0.8rem;
        font-size:0.33rem;
        color:red;
        background-color:white;
    }
    .selectout{
        margin-top: 8px;
        border:1px solid white;
        width:1.5rem;
        height:0.8rem;
        font-size:0.33rem;
        color:red;
        background-color:white;
    }
    .van-icon{
        font-size:0.4rem;
        left:4rem;
    }

}
.popbody{
    
    background-color:white;
    ol{
        // text-align:center;
        // margin: 0rem 1.5rem;
        margin: 0.5rem 1.5rem;
        padding: 0rem 0.3rem;

    }
    hr{
        margin:15px 15px;
        opacity:0.2;
    }
    h5{
        width:60%;
        margin:10px 15px;
        margin-right:0.5rem;
        font-size:18px;
    }
    p{
        margin-left: 0.4rem;
        font-size: 0.33rem;
       
    }
    .radioboxclass{
        position: relative;
        bottom: 1rem;
        display: inline-block;
        
        margin-right: 1rem;
        font-size: 0.5rem;
    }
    .checkboxclass{
        margin-left: 0.8rem;
        margin-bottom: 0.3rem;
        width: 3rem;
        height: 0.8rem;
    }
    .checkboxclass2{
        margin-left: 0.5rem;
        margin-bottom: 0.3rem;
        width: 2rem;
        height: 0.8rem;
    }
    .van-checkbox-group{
        margin-left:0.8rem;
        .van-checkbox{
            font-size:0.5rem;
            
        }
    }
    .van-radio-group{
        .van-radio{
            margin-right: 1.0rem;
            font-size: 0.5rem;
        }
    }

    // 数字的选择
    .unchecked{
    border-radius: 50%;
    border:1px solid gray;
    width:36px;
    height:36px;
    display:inline-block;
    margin: 0.26rem 0.1rem;
    font-size: 28px;
    text-align:center;
    color:black;
    background-color:white;
    }
    .checkedsave{
    border-radius: 50%;
    border:1px solid gray;
    width:36px;
    height:36px;
    display:inline-block;
    margin: 0.26rem 0.1rem;
    font-size: 28px;
    text-align:center;
    color:white;
    background-color:green;
    }
    .checkedout{
    border-radius: 50%;
    border:1px solid gray;
    width:36px;
    height:36px;
    display:inline-block;
    margin: 0.26rem 0.1rem;
    font-size: 28px;
    text-align:center;
    color:white;
    background-color:red;
    }
    // 保留，排除按钮的切换
    .unselect{
        width:1.5rem;
        height:0.8rem;
        font-size:0.33rem;
        border:1px solid gray;
        background-color:white;
    }
    .selectsave{
        // border:none;
        border:1px solid gray;
        width:1.5rem;
        height:0.8rem;
        font-size:0.33rem;
        color:white;
        background-color:green;
    }
    .selectout{
        // border:none;
        border:1px solid gray;
        width:1.5rem;
        height:0.8rem;
        font-size:0.33rem;
        color:white;
        background-color:red;
    }
    //按钮根据已选 未选 保留 排除进行切换 开奖历史
    .unselect2{
        width:2rem;
        height:0.8rem;
        font-size:0.33rem;
        margin-left: 0.3rem;
        border:1px solid gray;
        background-color:white;
    }
    .selectsave2{
        // border:none;
        width:2rem;
        height:0.8rem;
        font-size:0.33rem;
        margin-left: 0.3rem;
        border:1px solid gray;
        color:white;
        background-color:green;
    }
    .selectout2{
        // border:none;
        width:2rem;
        height:0.8rem;
        font-size:0.33rem;
        margin-left: 0.3rem;
        border:1px solid gray;
        color:white;
        background-color:red;
    }
    .selectsave3{
        // border:none;
        width:2.5rem;
        height:0.8rem;
        font-size:0.33rem;
        margin-left: 0.3rem;
        border:1px solid gray;
        color:green;
    }
    .selectout3{
        // border:none;
        width:2.5rem;
        height:0.8rem;
        font-size:0.33rem;
        margin-left: 0.3rem;
        border:1px solid gray;
        color:red;
    }

    .unselectletter{
        padding:0rem;
        width:0.8rem;
        height:0.8rem;
        font-size:0.33rem;
        text-align: center;
        margin-left: 0.17rem;
        border:1px solid gray;
        background-color:white;
    }
    .selectsaveletter{
        padding:0rem;
        width:0.8rem;
        height:0.8rem;
        font-size:0.33rem;
        text-align: center;
        margin-left: 0.17rem;
        border:1px solid gray;
        color:white;
        background-color:green;
    }
    .selectoutletter{
        padding:0rem;
        width:0.8rem;
        height:0.8rem;
        font-size:0.33rem;
        text-align: center;
        margin-left: 0.17rem;
        border:1px solid gray;
        color:white;
        background-color:red;
    }
    
    
    

}
.popbutton{
    display:fixed;
    text-align:center;
    .van-button{
        border-radius: 10px;
        width: 4.0rem;
        height: 1rem;
        margin: 0.25rem;

    }
    
}


// 已选条件弹窗
.pop2{
    
    position: fixed;
    z-index: 0;
    .van-popup{
        position:fixed;
        bottom:1.8rem;
    }
    .popbody{
            //勾选与不勾选
        .choose{
            color: red;
            display:inline-block;
        }
        .unchoose{
            color: gray;
            display:inline-block;
        }
        .items{
            div{
                display:inline-block;
                height:100%;
                width:60%;
            }
            button{
                display:inline-block;
                border-radius:20px;
                height:100%;
                width:20%;
                position:relative;
                bottom:0.6rem;
                right:15px;
                font-size:18px;

            }
            .van-icon{
                display:inline-block;
                height:100%;
                width:20%;
                font-size:30px;
                text-align:center;
                bottom:0.5rem;
            }
            
        }
    }
    
   
    
}
</style>